import {
  Panel,
  connect,
  React,
  PanelBody,
  PanelHeader,
  reduxForm,
  Link,
  ToastNotification,
  BackButtonPos,
  // getDataNoTimeOut,
  getDataParams,
} from "../../../../components/helpers/library";
import FilterLaporanAnalisisHargaBarang from "./FilterAnalisisHargaBarang.jsx";

import { getDataJenis, getJenisGroup } from "../../../../actions";

class FormLaporanAnalisisHargaBarang extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      export: false,
      LaporanDataBarang: {
        databarang : [],
        count : 0
      },
      tgl_awal: new Date(),
      tgl_akhir: new Date(),
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(getDataJenis());
    this.props.dispatch(getJenisGroup());
  }
  componentWillUnmount() {}
  handleSubmit(data) {    
    this.setState({
      isLoading: true,
    });
    let data_post = {
      kode_dept: data.kode_dept,
      sort_by: data.type_laporan,
    };
    // console.log(data_post, "data_post")
    // return false

    // getDataNoTimeOut("barang/laporan-analisis-harga-barang", data_post)
    getDataParams(`barang/laporan-analisis-harga-barang`, data_post)
      .then((res) => {
        console.log(res, "DATA_RESSS")
        // return false
        if (res.data.length === 0) {
          ToastNotification("info", "Data Yang Anda Cari Tidak Ada");
          this.setState({
            isLoading: false,
            export: false,
          });
        } else {
          ToastNotification("success", "Laporan Analisis Harga Barang");
          this.setState({
            LaporanDataBarang: res.data,
            isLoading: false,
            export: true,
          });
        }
      })
      .catch((error) => this.errorHandling(error));
  }

  errorHandling(err) {
    console.log(err);
    ToastNotification("info", "Terjadi Kesalahan Saat Request Data");
    this.setState({
      isLoading: false,
      export: false,
    });
  }
  setStartDate(date) {
    this.setState({
      tgl_awal: new Date(date),
    });
  }
  render() {
    return (
      <div>
        <BackButtonPos link={"/SubMenuLaporan"}>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="#">Laporan</Link>
            </li>
            <li className="breadcrumb-item active">
              Laporan Analisis Harga Barang
            </li>
          </ol>
          <h1 className="page-header">Laporan Analisis Harga Barang </h1>
        </BackButtonPos>
        <Panel>
          <PanelHeader>Laporan Analisis Harga Barang</PanelHeader>
          <br />
          <PanelBody>
            <FilterLaporanAnalisisHargaBarang
              export={this.state.export}
              LaporanDataBarang={this.state.LaporanDataBarang}
              isLoading={this.state.isLoading}
              onSubmit={(data) => this.handleSubmit(data)}
            />
          </PanelBody>
          <br />
          {/* End Tambah Laporan Barang Sumary  */}
        </Panel>
      </div>
    );
  }
}
FormLaporanAnalisisHargaBarang = reduxForm({
  form: "FormLaporanAnalisisHargaBarang",
  enableReinitialize: true,
})(FormLaporanAnalisisHargaBarang);
export default connect()(FormLaporanAnalisisHargaBarang);
