import { GET_DATA_MEMBER, SHOW_MODAL_EDIT_MEMBER, SHOW_MODAL_TUKAR_POIN } from "../actions/member_action";


const initialState = {
    getDataMember: [],
    showEditMember: false,
    ShowModalTukarPoin:false
}

const datamember = (state = initialState, actions) => {
    switch (actions.type) {
        case GET_DATA_MEMBER:
            return {
                ...state,
                getDataMember: actions.payload.data,
            };
        case SHOW_MODAL_TUKAR_POIN:
            return {
                ...state,
                ShowModalTukarPoin: actions.payload.data,
            };
        case SHOW_MODAL_EDIT_MEMBER:
            return {
                ...state,
                showEditMember: actions.payload.data,
            };
        default:
            return state;
        }
}
export default datamember;