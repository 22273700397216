import React, { Component } from "react";
import { connect } from "react-redux";
import { change, Field, reduxForm } from "redux-form";
import Loading from "react-fullscreen-loading";
import { hideModal } from "../../../../actions/datamaster_action";
import { ReanderField, renderTextArea } from "../../../../components/helpers/field";
import { resizeFile } from "../../../../components/helpers/function";
import ValidasiBannerKatalog from "../../BannerKatalog/validate-form/ValidasiBannerKatalog";
// import ValidasiBannerKatalog from "pages/KatalogApp/BannerKatalog/validate-form/ValidasiBannerKatalog";

const maptostate = (state) => {
  if (state.stateInfoKatalog.ShowModal !== null) {
    return {
      gambar_path: state.stateInfoKatalog.ShowModal.gambar_path,
      isEdit: true,
      isLoading: state.utility.isLoading,
      initialValues: {
        kode_info: state.stateInfoKatalog.ShowModal.kode_info,
        deskripsi: state.stateInfoKatalog.ShowModal.deskripsi,
        gambar_path: state.stateInfoKatalog.ShowModal.gambar_path,
        gambar_name: state.stateInfoKatalog.ShowModal.gambar_name,
        title: state.stateInfoKatalog.ShowModal.title,
        sub_title: state.stateInfoKatalog.ShowModal.sub_title,
        info: state.stateInfoKatalog.ShowModal.info,
        sub_info: state.stateInfoKatalog.ShowModal.sub_info,
        url_pendukung: state.stateInfoKatalog.ShowModal.url_pendukung,
        nama_tombol: state.stateInfoKatalog.ShowModal.nama_tombol,
      },
    };
  } else {
    return {
      isLoading: state.utility.isLoading,
    };
  }
};
class FormInfoKatalog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aktif: false,
      listgroup: [],
      parameter_bandrol: "2301",
    };
  }

  setFocus() {
    setTimeout(() => {
      document.getElementById("kode_kategori").focus();
    }, 100);
  }

  getValue = async (event) => {
    const file = event.target.files[0];
    document.getElementById("gambar_path").value = file.name;
    const base64 = await resizeFile(file);
    document.getElementById("preview").src = base64;
    this.props.dispatch(change("FormInfoKatalog", "gambar_path", base64));
    console.log(base64);
  };

  render() {
    return (
      <form onSubmit={this.props.handleSubmit} autoComplete="off">
        <Field
          tabIndex="1"
          id="title"
          name="title"
          component={ReanderField}
          nouperCase
          type="text"
          label="Title Banner"
          placeholder="Masukan Title Banner"
        />
        <Field
          tabIndex="2"
          id="sub_title"
          name="sub_title"
          component={ReanderField}
          nouperCase
          type="text"
          label="Subtitle Banner"
          placeholder="Masukan Subtitle Banner"
        />
        <Field
          tabIndex="3"
          id="info"
          name="info"
          component={ReanderField}
          nouperCase
          type="text"
          label="Info Banner"
          placeholder="Masukan Info Banner"
        />
        <Field
          tabIndex="4"
          id="sub_info"
          name="sub_info"
          component={ReanderField}
          nouperCase
          type="text"
          label="Sub Info Banner"
          placeholder="Masukan Sub Info Banner"
        />
        <Field
          tabIndex="5"
          id="deskripsi"
          name="deskripsi"
          component={renderTextArea}
          nouperCase
          type="text"
          label="Deskripsi Banner"
          placeholder="Masukan Deskripsi Banner"
        />
        <Field
          tabIndex="6"
          id="url_pendukung"
          name="url_pendukung"
          component={ReanderField}
          nouperCase
          type="text"
          label="URL Pendukung Tombol"
          placeholder="Masukan Url Pendukung Banner"
        />
        <Field
          tabIndex="7"
          id="nama_tombol"
          name="nama_tombol"
          component={ReanderField}
          nouperCase
          type="text"
          label="Nama Untuk Tombol App"
          placeholder="Masukan Nama Untuk Tombol App"
        />
        <div className="form-group">
          <input
            type="file"
            id="gambar"
            name="gambar"
            onChange={(e) => this.getValue(e)}
          />
        </div>

        <Field
          tabIndex="3"
          id="gambar_path"
          name="gambar_path"
          component={ReanderField}
          type="text"
          label="Gambar Info"
          readOnly={true}
          placeholder="Masukan Gambar Info"
        />
        <div className="d-none">
          <Field
            id="gambar_name"
            name="gambar_name"
            component={ReanderField}
            type="text"
            label="Gambar Info"
            readOnly={true}
            placeholder="Masukan Gambar Info"
          />
        </div>
        <div className="col-lg-12">
          <img
            alt="previewIcon"
            id="preview"
            className="img-thumbnail"
            src={this.props.gambar_path}
          />
        </div>
        <div className="row">
          <div className="col-12 text-right">
            <button
              className="btn btn-white"
              onClick={() => this.props.dispatch(hideModal())}
              type="button"
              disabled={this.props.isLoading}
            >
              Batal
            </button>
            &nbsp;
            <button
              tabIndex="4"
              className={this.state.aktif ? "btn btn-primary" : "btn btn-white"}
              disabled={this.props.submitting}
              type="submit"
            >
              {this.props.isLoading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                  Menyimpan
                </>
              ) : (
                "Simpan Data"
              )}
            </button>
          </div>
        </div>
        {this.props.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </form>
    );
  }
}

FormInfoKatalog = reduxForm({
  form: "FormInfoKatalog",
  enableReinitialize: true,
  validate: ValidasiBannerKatalog,
})(FormInfoKatalog);
export default connect(maptostate, null)(FormInfoKatalog);
