import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import EmptyTable from "./emptyTable";

let width = window.innerWidth;

const TabelPagenation = ({
  data, // Data yang akan di tampikan ke table
  page, // jumlah page
  sizePerPage, // jumlah halaman per page
  onTableChange, // function untuk perpindahan
  totalSize, // jumlah data
  columns, // menampilkan column
  keyField, // identitas
  selectRow, // select row
  expandRow, // exxpend row
  rowStyle,
  enableSearch,
}) => {
  const [value, setvalue] = useState("");
  const [dataLocal, setData] = useState(data || []);
  const [pageLocal, setPageLocal] = useState(0);
  const [sizePerPageLocal, setsizePerPageLocal] = useState(0);
  const [totalSizeLocal, settotalSizeLocal] = useState(totalSize);

  useEffect(() => {
    setData(data);
    setPageLocal(page);
    setsizePerPageLocal(sizePerPage);
    settotalSizeLocal(totalSize);
  }, [data, page, sizePerPage, totalSize]);

  // console.log(totalSize);

  const handleSearchChange = (e) => {
    setvalue(e.target.value);

    const regex = new RegExp(`.*${e.target.value}.*`, "gi"); // Tambahkan .* di sekitar e.target.value
    const result = data.filter((item) => {
      return columns.some((column) => regex.test(item[column.dataField]));
    });

    if (e.target.value === "") {
      setData(data);
      settotalSizeLocal(totalSize);
    } else {
      setData(result);
      settotalSizeLocal(result.length);
    }
  };

  const handleTableChangeLocal = (type, { page, sizePerPage }) => {
    onTableChange(type, { page, sizePerPage });

    setPageLocal(page);
    setsizePerPageLocal(sizePerPage);
    settotalSizeLocal(data.length);
  };

  return (
    <ToolkitProvider
      keyField={keyField}
      data={dataLocal}
      columns={columns}
      // search
    >
      {(props) => (
        <div className="row">
          {enableSearch && (
            <>
              <div className="col-3 mt-2 mb-4">
                <input
                  placeholder="Search"
                  type="text"
                  value={value}
                  onChange={handleSearchChange}
                  className="form-control"
                />
              </div>
              <div className="col-6"></div>
            </>
          )}
          <div className="col-12">
            <BootstrapTable
              remote
              wrapperClasses={width > 500 ? "" : "table-responsive"}
              selectRow={selectRow}
              keyField="_id"
              {...props.baseProps}
              pagination={paginationFactory({
                page: pageLocal,
                sizePerPage: sizePerPageLocal,
                totalSize: totalSizeLocal,
              })}
              onTableChange={handleTableChangeLocal}
              noDataIndication={<EmptyTable text={"Tidak Ada Data"} />}
              expandRow={expandRow}
              rowStyle={rowStyle}
            />
          </div>
        </div>
      )}
    </ToolkitProvider>
  );
};

export default TabelPagenation;
