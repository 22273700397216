import {
  Tabel,
  React,
  useSelector,
  selectorMaster,
  useDispatch,
  lang,
  getItem,
  formatGram,
} from "../../../../../components/helpers/library";
import { cetakLaoranPdf } from "../Redux";
// import { columnsDetail } from "./columns";
//   import { columnsDetail, columnsRekap, columnsPerSales } from "./columns";
//   import ExportPenjualanSalesExelRekapPerSupplier from "../Report/exel/EportPenjualanSalesPerSupplier";

const TabelLaporanKelompok = () => {
  const dispatch = useDispatch();
  const datatmp = useSelector(selectorMaster.datatmp || []);
  const columnsNamaBarang = [
    {
      dataField: "nama_barang",
      text: lang("Nama Barang"),
      footer: "",
      formatter: (cell) => {
        return <div> {cell || "-"} </div>;
      },
    },
    {
      dataField: "berat",
      text: lang("Berat"),
      headerClasses: "text-right",
      formatter: (cell) => {
        return <div className="text-right"> {formatGram(cell, 3)} </div>;
      },
      footer: (columnData) => {
        return (
          <div className="text-right">
            {" "}
            {formatGram(
              columnData.reduce((acc, item) => acc + item, 0),
              3
            )}{" "}
          </div>
        );
      },
    },
    {
      dataField: "jumlah",
      text: lang("Jumlah"),
      headerClasses: "text-right",
      formatter: (cell) => {
        return <div className="text-right"> {cell} </div>;
      },
      footer: (columnData) => {
        return (
          <div className="text-right">
            {" "}
            {columnData.reduce((acc, item) => acc + item, 0)}{" "}
          </div>
        );
      },
    },
  ];
  const columnsNamaBarangSize = [
    {
      dataField: "nama_barang",
      text: lang("Nama Barang"),
      footer: "",
      formatter: (cell) => {
        return <div> {cell || "-"} </div>;
      },
    },
    {
      dataField: "size",
      text: lang("Size"),
      headerClasses: "text-right",
      footer: "",
      formatter: (cell) => {
        return <div> {cell || "-"} </div>;
      },
    },
    {
      dataField: "berat",
      text: lang("Berat"),
      headerClasses: "text-right",
      formatter: (cell) => {
        return <div className="text-right"> {formatGram(cell, 3)} </div>;
      },
      footer: (columnData) => {
        return (
          <div className="text-right">
            {" "}
            {formatGram(
              columnData.reduce((acc, item) => acc + item, 0),
              3
            )}{" "}
          </div>
        );
      },
    },
    {
      dataField: "jumlah",
      text: lang("Jumlah"),
      headerClasses: "text-right",
      formatter: (cell) => {
        return <div className="text-right"> {cell} </div>;
      },
      footer: (columnData) => {
        return (
          <div className="text-right">
            {" "}
            {columnData.reduce((acc, item) => acc + item, 0)}{" "}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Tabel
        keyField={
          datatmp.type === "NAMA BARANG" ? "NAMA BARANG DAN SIZE" : "_id"
        }
        data={getItem("laporanPenjualanSales")}
        columns={
          datatmp?.type === "NAMA BARANG"
            ? columnsNamaBarang
            : columnsNamaBarangSize
        }
        empty={datatmp?.tbl_barngs}
        textEmpty="Data Laporan Transaksi Penjualan Sales Kosong"
      />
      {datatmp?.databarang !== undefined && (
        <div className="row">
          <div className="col-6">
            <button
              type="button"
              onClick={() => dispatch(cetakLaoranPdf(datatmp.databarang))}
              className="btn btn-warning btn-block"
            >
              {" "}
              Export Pdf{" "}
            </button>
          </div>
          {console.log(datatmp.type, "TYPE_LAPORAN")}
          <div className="col-6">
            {/* {datatmp.type === "DETAIL" ? (
                <ExportPenjualanSalesExel data={datatmp?.databarang || []} />
              ) : supplierBarang ? (
                datatmp.type === "GROUP_PER_SUPPLIER" ? (
                  <ExportPenjualanSalesExelRekapPerSupplier
                    data={getItem("laporanPenjualanSalesExcelPerSupplier") || []}
                  />
                ) : (
                  <ExportPenjualanSalesExelRekap
                    data={datatmp?.databarang || []}
                  />
                )
              ) : (
                <ExportPenjualanSalesExelRekap data={datatmp?.databarang || []} />
              )} */}
          </div>
        </div>
      )}
    </>
  );
};

export default TabelLaporanKelompok;
