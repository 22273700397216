import {
  PanelContent,
  useDispatch,
} from "../../../../components/helpers/library";
import React from "react";
import FormLaporanPembelianPerfaktur from "./Form";
import { cariLaporan } from "./Redux";

const LaporanPembelianPerfaktur = () => {
  const dispatch = useDispatch();
  return (
    <PanelContent menu="Laporan Pembelian Per Faktur" submenu="Laporan">
      <FormLaporanPembelianPerfaktur
        onSubmit={(data) => dispatch(cariLaporan(data))}
      />
    </PanelContent>
  );
};

export default LaporanPembelianPerfaktur;
