import {
  GetDataGroup,
  getKelompokMasterHarga,
  getKondisiPembelian,
} from "../../../../../actions/datamaster_action";
import {
  Field,
  InputDate,
  React,
  useState,
  reduxForm,
  useDispatch,
  useEffect,
  change,
  Button,
  ReanderSelect,
  useSelector,
  selectorMaster,
} from "../../../../../components/helpers/library";

let FormLaporanPembelianPerfaktur = (props) => {
  const [tgl_awal, setTglAwal] = useState(new Date());
  const [tgl_akhir, setTglAkhir] = useState(new Date());

  const dispatch = useDispatch();
  const datagroup = useSelector(selectorMaster.datagroup);
  const dataKelompok = useSelector(selectorMaster.kelompokharga);
  const kondisiBeli = useSelector(selectorMaster.parameterKondisiBeli);
  const group = [
    {
      value: "ALL",
      name: "SEMUA",
    },
  ];
  const kelompok = [
    {
      value: "ALL",
      name: "SEMUA",
    },
  ];
  const kondisi = [
    {
      value: "ALL",
      name: "SEMUA",
    },
  ];
  useEffect(() => {
    dispatch(change("FormLaporanPembelianPerfaktur", "tgl_awal", new Date()));
    dispatch(change("FormLaporanPembelianPerfaktur", "tgl_akhir", new Date()));
    dispatch(change("FormLaporanPembelianPerfaktur", "kode_group", "ALL"));
    dispatch(change("FormLaporanPembelianPerfaktur", "kelompok_harga", "ALL"));
    dispatch(change("FormLaporanPembelianPerfaktur", "kondisi_beli", "ALL"));
    dispatch(GetDataGroup());
    dispatch(getKelompokMasterHarga());
    dispatch(getKondisiPembelian());
  }, [dispatch]);

  dataKelompok.map((list) => {
    return kelompok.push({
      value: list.kelompok_harga,
      name: list.kelompok_harga,
    });
  });
  datagroup.map((list) => {
    return group.push({
      value: list.kode_group,
      name: list.kode_group,
    });
  });
  kondisiBeli.map((list) => {
    return kondisi.push({
      value: list.kondisi_barang,
      name: list.kondisi_barang,
    });
  });
  return (
    <form onSubmit={props.handleSubmit} autoComplete="off">
      <div className="row">
        <div className="col-lg-3">
          <Field
            name="tgl_awal"
            component={InputDate}
            label="Tanggal Dari"
            type="text"
            selected={tgl_awal}
            onChange={(date) => setTglAwal(new Date(date))}
            placeholder="Masukkan Tanggal Dari"
          />
        </div>

        <div className="col-lg-3">
          <Field
            name="tgl_akhir"
            component={InputDate}
            type="text"
            selected={tgl_akhir}
            onChange={(date) => setTglAkhir(new Date(date))}
            label="Tanggal Akhir"
            placeholder="Masukkan Tanggal Akhir"
          />
        </div>
        <div className="col-lg-3">
          <Field
            name="kode_group"
            component={ReanderSelect}
            type="text"
            options={group}
            label="Kode Group"
            placeholder="Masukkan Kode Group"
          />
        </div>
        <div className="col-lg-3">
          <Field
            name="kelompok_harga"
            component={ReanderSelect}
            type="text"
            options={kelompok}
            label="Kelompok Harga"
            placeholder="Masukkan Kelompok Harga"
          />
        </div>
        <div className="col-lg-3">
          <Field
            name="kondisi_beli"
            component={ReanderSelect}
            type="text"
            options={kondisi}
            label="Kondisi Beli"
            placeholder="Masukkan Kondisi Beli"
          />
        </div>

        <div className="col-lg-3 mt-4">
          <Button
            title="Cari Data"
            color="primary"
            loading
            textLoading="Cari Data"
            block
          />
        </div>
      </div>
    </form>
  );
};

FormLaporanPembelianPerfaktur = reduxForm({
  form: "FormLaporanPembelianPerfaktur",
  enableReinitialize: true,
})(FormLaporanPembelianPerfaktur);
export default FormLaporanPembelianPerfaktur;
