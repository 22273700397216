import React from "react";
import { Progress } from "reactstrap";

const Progresbar = (props) => {
  return (
    <div className="text-center">
      <h2>
        Mohon Menunggu <br />{" "}
        {props.typeProsesSocket
          ? "Sedang Mengirim Data !!!"
          : "Sedang Mengambil Data !!!"}{" "}
      </h2>
      <br />
      {props.modalName === "JENIS_GEOUP" ? (
        <Progress
          animated
          value={props.progress * 100}
          max="100"
          barClassName="custome"
        >
          {props.progress}
        </Progress>
      ) : (
        <Progress
          animated
          value={(props.progress * 100) / props.total}
          max="100"
          barClassName="custome"
        >
          {props.progress} / {props.total}
        </Progress>
      )}
    </div>
  );
};

export default Progresbar;
