import {
  Field,
  InputDate,
  React,
  useSelector,
  useState,
  // reduxForm,
  // useDispatch,
  // useEffect,
  // change,
  selectorMaster,
  lang,
} from "./library";

let FilterLaporanTgl = ({
  handleSubmit,
  nameButton,
  disabledTglAkhir,
  cusTomeNameTglAwal,
  disabledButton,
}) => {
  const [tgl_awal, setTglAwal] = useState(new Date());
  const [tgl_akhir, setTglAkhir] = useState(new Date());
  const loading = useSelector(selectorMaster.loading);
  const tglLaporan = useSelector(selectorMaster.getAktifTgl);

  return (
    // <form onSubmit={handleSubmit} autoComplete="off">
    <div className="row">
      <div className="col-lg-3">
        <Field
          name="tgl_awal"
          component={InputDate}
          label={cusTomeNameTglAwal || `${lang("Tanggal Dari")}`}
          type="text"
          selected={tgl_awal}
          readOnly={tglLaporan}
          onChange={(date) => setTglAwal(new Date(date))}
          placeholder={`${lang("Masukkan")} ${lang("Tanggal Dari")}`}
        />
      </div>
      {disabledTglAkhir === true ? (
        ""
      ) : (
        <div className="col-lg-3">
          <Field
            name="tgl_akhir"
            component={InputDate}
            type="text"
            selected={tgl_akhir}
            readOnly={tglLaporan}
            onChange={(date) => setTglAkhir(new Date(date))}
            label={lang("Tanggal Akhir")}
            placeholder={`${lang("Masukkan")} ${lang("Tanggal Akhir")}`}
          />
        </div>
      )}
      {disabledButton === true ? (
        ""
      ) : (
        <div className="col-lg-3">
          <div className="text-right">
            <label>&nbsp;</label>
            <button
              type="submit"
              value="PDF"
              name="type_btn"
              className="btn btn-primary btn-block"
            >
              {loading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp;{" "}
                  {lang("Sedang Mencari")}
                </>
              ) : (
                lang(nameButton) || `${lang("Cari Laporan")}`
              )}
            </button>
          </div>
        </div>
      )}
    </div>
    // </form>
  );
};

// FilterLaporanTgl = reduxForm({
//   form: "FilterLaporanTgl",
//   enableReinitialize: true
// })(FilterLaporanTgl);
export default FilterLaporanTgl;
