import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FormModal } from "../../actions/databarang_action";
import { hideModal } from "../../actions/datamaster_action";
import { removeItem } from "./function";
import Draggable from "react-draggable";
const maptostate = (state) => {
  return {
    isOpen: state.datamaster.modalDialog,
    formModal: state.datamaster.FormModal
  };
};
class ModalOtorisasi extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  hideModal() {
    this.props.dispatch(hideModal());
    removeItem("total_rp_services");
  }
  render() {
    return (
      <Draggable>
        <Modal
          backdrop="static"
          keyboard={false}
          id={"otorisasi"}
          isOpen={this?.props?.formModal?.status || false}
          toggle={() =>
            this.props.dispatch(
              FormModal({
                form: this.props.formModal.form,
                status: false
              })
            )
          }
          size={this.props.size || "xl"}
        >
          <ModalHeader
            toggle={() =>
              this.props.dispatch(
                FormModal({
                  form: this.props.formModal.form,
                  status: false
                })
              )
            }
          >
            {this.props.title}
          </ModalHeader>
          <ModalBody>{this.props.content}</ModalBody>
        </Modal>
      </Draggable>
    );
  }
}

export default connect(maptostate, null)(ModalOtorisasi);
