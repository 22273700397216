import {GET_ALL_KIRIM_UANG_PUSAT} from "./kirim_uang_pusat_action";

const initialState = {
listKirimUangPusat: [],
}

const kirimUangPusat = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_KIRIM_UANG_PUSAT: {
            return {
                ...state,
                listKirimUangPusat: action.payload
            }
        }
        default:
            return state
    }
}

export default kirimUangPusat