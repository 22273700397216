import {
  getToday,
  isPos,
  setItem,
  io,
  Skeleton,
  Redirect,
  Swal,
  React,
  Suspense,
  lazy,
  // getUserdata,
  reduxForm,
  connect,
  PageSettings,
  LoadingContent,
  getItem,
  server,
  ModalLocal,
  Progresbar,
  removeItem,
  lang,
  setCookie,
} from "./components/helpers/library";
const Header = lazy(() => import("./components/header/header.jsx"));
const Content = lazy(() => import("./components/content/content.jsx"));
const Sidebar = lazy(() => import("./components/sidebar/sidebar.jsx"));
const FloatSubMenu = lazy(() =>
  import("./components/float-sub-menu/float-sub-menu.jsx")
);

class App extends React.Component {
  static contextType = PageSettings;
  constructor(props) {
    super(props);

    this.toggleSidebarMinify = (e) => {
      e.preventDefault();
      if (this.state.pageSidebarMinify) {
        this.setState((state) => ({
          pageFloatSubMenuActive: false,
        }));
      }
      this.setState((state) => ({
        pageSidebarMinify: !this.state.pageSidebarMinify,
      }));
    };
    this.toggleMobileSidebar = (e) => {
      this.setState((state) => ({
        pageSidebarToggled: !this.state.pageSidebarToggled,
      }));
    };
    this.handleSetPageSidebar = (value) => {
      this.setState((state) => ({
        pageSidebar: isPos() ? false : value,
      }));
    };

    this.handleSetPageSidebarWide = (value) => {
      this.setState((state) => ({
        pageSidebarWide: value,
      }));
    };

    this.toggleRightSidebar = (e) => {
      e.preventDefault();
      this.setState((state) => ({
        pageRightSidebarCollapsed: !this.state.pageRightSidebarCollapsed,
      }));
    };
    this.toggleMobileRightSidebar = (e) => {
      e.preventDefault();
      this.setState((state) => ({
        pageMobileRightSidebarToggled:
          !this.state.pageMobileRightSidebarToggled,
      }));
    };

    var floatSubMenuRemove;
    var floatSubMenuCalculate;
    var floatSubMenuRemoveTime = 250;
    this.handleFloatSubMenuOnMouseOver = (e) => {
      clearTimeout(floatSubMenuRemove);
      clearTimeout(floatSubMenuCalculate);
    };
    this.handleFloatSubMenuOnMouseOut = (e) => {
      floatSubMenuRemove = setTimeout(() => {
        this.setState((state) => ({
          pageFloatSubMenuActive: false,
        }));
      }, floatSubMenuRemoveTime);
    };
    this.handleSidebarOnMouseOver = (e, menu) => {
      if (this.state.pageSidebarMinify) {
        if (menu.children) {
          var left =
            document.getElementById("sidebar").offsetWidth +
            document.getElementById("sidebar").offsetLeft +
            "px";

          clearTimeout(floatSubMenuRemove);
          clearTimeout(floatSubMenuCalculate);

          this.setState((state) => ({
            pageFloatSubMenu: menu,
            pageFloatSubMenuActive: true,
            pageFloatSubMenuLeft: left,
          }));

          var offset = e.currentTarget.offsetParent.getBoundingClientRect();

          floatSubMenuCalculate = setTimeout(() => {
            var targetTop = offset.top;
            var windowHeight = window.innerHeight;
            var targetHeight = document.querySelector(
              ".float-sub-menu-container"
            ).offsetHeight;
            var top, bottom, arrowTop, arrowBottom, lineTop, lineBottom;

            if (windowHeight - targetTop > targetHeight) {
              top = offset.top + "px";
              bottom = "auto";
              arrowTop = "20px";
              arrowBottom = "auto";
              lineTop = "20px";
              lineBottom = "auto";
            } else {
              var aBottom = windowHeight - targetTop - 21;
              top = "auto";
              bottom = "0";
              arrowTop = "auto";
              arrowBottom = aBottom + "px";
              lineTop = "20px";
              lineBottom = aBottom + "px";
            }

            this.setState((state) => ({
              pageFloatSubMenuTop: top,
              pageFloatSubMenuBottom: bottom,
              pageFloatSubMenuLineTop: lineTop,
              pageFloatSubMenuLineBottom: lineBottom,
              pageFloatSubMenuArrowTop: arrowTop,
              pageFloatSubMenuArrowBottom: arrowBottom,
              pageFloatSubMenuOffset: offset,
            }));
          }, 0);
        } else {
          floatSubMenuRemove = setTimeout(() => {
            this.setState((state) => ({
              pageFloatSubMenu: "",
              pageFloatSubMenuActive: false,
            }));
          }, floatSubMenuRemoveTime);
        }
      }
    };
    this.handleSidebarOnMouseOut = (e) => {
      if (this.state.pageSidebarMinify) {
        floatSubMenuRemove = setTimeout(() => {
          this.setState((state) => ({
            pageFloatSubMenuActive: false,
          }));
        }, floatSubMenuRemoveTime);
      }
    };
    this.handleFloatSubMenuClick = () => {
      if (this.state.pageSidebarMinify) {
        const windowHeight = window.innerHeight;
        const targetHeight =
          document.getElementById("float-sub-menu").offsetHeight;
        const targetTop = this.state.pageFloatSubMenuOffset.top;
        const top =
          windowHeight - targetTop > targetHeight ? targetTop : "auto";
        const left =
          this.state.pageFloatSubMenuOffset.left +
          document.getElementById("sidebar").offsetWidth +
          "px";
        const bottom = windowHeight - targetTop > targetHeight ? "auto" : "0";
        const arrowTop =
          windowHeight - targetTop > targetHeight ? "20px" : "auto";
        const arrowBottom =
          windowHeight - targetTop > targetHeight
            ? "auto"
            : windowHeight - targetTop - 21 + "px";
        const lineTop =
          windowHeight - targetTop > targetHeight ? "20px" : "auto";
        const lineBottom =
          windowHeight - targetTop > targetHeight
            ? "auto"
            : windowHeight - targetTop - 21 + "px";

        this.setState((state) => ({
          pageFloatSubMenuTop: top,
          pageFloatSubMenuLeft: left,
          pageFloatSubMenuBottom: bottom,
          pageFloatSubMenuLineTop: lineTop,
          pageFloatSubMenuLineBottom: lineBottom,
          pageFloatSubMenuArrowTop: arrowTop,
          pageFloatSubMenuArrowBottom: arrowBottom,
        }));
      }
    };

    this.handleSetPageHeader = (value) => {
      this.setState((state) => ({
        pageHeader: value,
      }));
    };

    this.handleSetPageContentFullWidth = (value) => {
      this.setState((state) => ({
        pageContentFullWidth: value,
      }));
    };

    this.toggleMobileTopMenu = (e) => {
      e.preventDefault();
      this.setState((state) => ({
        pageMobileTopMenu: !this.state.pageMobileTopMenu,
      }));
    };

    this.handleSetBodyWhiteBg = (value) => {
      if (value === true) {
        document.body.classList.add("bg-white");
      } else {
        document.body.classList.remove("bg-white");
      }
    };

    this.state = {
      pageHeader: isPos() ? false : true,
      hasScroll: false,
      handleSetPageHeader: this.handleSetPageHeader,
      pageSidebar: isPos() ? false : true,
      pageSidebarWide: false,
      pageSidebarMinify: false,
      pageSidebarToggled: false,
      pageSidebarTransparent: false,
      pageContentFullWidth: false,
      handleSetPageSidebar: this.handleSetPageSidebar,
      handleSetPageSidebarWide: this.handleSetPageSidebarWide,
      handleSidebarOnMouseOut: this.handleSidebarOnMouseOut,
      handleSidebarOnMouseOver: this.handleSidebarOnMouseOver,
      toggleSidebarMinify: this.toggleSidebarMinify,
      toggleMobileSidebar: this.toggleMobileSidebar,
      handleSetPageContentFullWidth: this.handleSetPageContentFullWidth,

      pageFloatSubMenuActive: false,
      pageFloatSubMenu: "",
      pageFloatSubMenuTop: "auto",
      pageFloatSubMenuLeft: "auto",
      pageFloatSubMenuBottom: "auto",
      pageFloatSubMenuLineTop: "auto",
      pageFloatSubMenuLineBottom: "auto",
      pageFloatSubMenuArrowTop: "auto",
      pageFloatSubMenuArrowBottom: "auto",
      pageFloatSubMenuOffset: "",
      handleFloatSubMenuOnMouseOver: this.handleFloatSubMenuOnMouseOver,
      handleFloatSubMenuOnMouseOut: this.handleFloatSubMenuOnMouseOut,
      handleFloatSubMenuClick: this.handleFloatSubMenuClick,

      pageContent: true,
      pageContentClass: "",
      pageContentInverseMode: false,

      pageMobileTopMenu: false,
      toggleMobileTopMenu: this.toggleMobileTopMenu,

      pageMobileRightSidebarToggled: false,
      toggleRightSidebar: this.toggleRightSidebar,
      toggleMobileRightSidebar: this.toggleMobileRightSidebar,

      handleSetBodyWhiteBg: this.handleSetBodyWhiteBg,
    };
  }

  componentDidMount() {
    this.updateSettingSystem();
    setItem("url", server);
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("online", this.updateStatus);
    window.addEventListener("offline", this.updateStatus);
    localStorage.removeItem("D2A2E4A8D4DCA6");
    localStorage.removeItem("D2A2E4A8D4DCA7");
    removeItem("portal1");
  }
  updateSettingSystem() {
    console.log(server, "SERVE")
    this.socket = io.connect(server.replace("/api/v1/", ""), {

      extraHeaders: { "ngrok-skip-browser-warning": 1 },
    });
    this.socket.on("settings-changed", (data) => {
      if (data.length > 0) {
        Swal.fire({
          html: "Terjadi perubahan pada setting system, Apakah anda ingin refresh halaman ?? ",
          icon: "warning",
          position: "top-center",
          cancelButtonText: lang("Tidak"),
          showCancelButton: true,
          confirmButtonText: lang("Ya"),
          showConfirmButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            let modules = getItem("module");
            data.forEach((list) => {
              let index = modules.findIndex((row) => row.key === list.key);
              if (index >= 0) {
                modules[index] = list;
              } else {
                modules.push(list);
              }
            });

            // console.log(modules);

            setItem("module", modules);
            // MULTI_LANGUAGE_FEATURE
            setCookie(
              "language",
              modules?.find((list) => list?.key === "MULTI_LANGUAGE_FEATURE")
                ?.value || "id"
            );

            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        });
      }
    });
  }
  updateStatus(event) {
    if (navigator.onLine) {
      Swal.fire({
        position: "center",
        imageUrl: require("../src/assets/img/BackConnection.gif"),
        imageWidth: 250,
        imageHeight: 250,
        title:
          "Looks like you connection is Back, You Can Use Our Website Again",
        showConfirmButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    } else {
      Swal.fire({
        position: "center",
        imageUrl: require("../src/assets/img/LostConnection.gif"),
        title: "Looks like you lost your internet connection",
        imageWidth: 250,
        imageHeight: 250,
        showConfirmButton: false,
        allowOutsideClick: false,
      });
    }
  }
  componentWillUnmount() {
    // localStorage.clear();
    removeItem("portal1");
    this.updateSettingSystem();
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY > 0) {
      this.setState((state) => ({
        hasScroll: true,
      }));
    } else {
      this.setState((state) => ({
        hasScroll: false,
      }));
    }
  };

  render() {
    const isAuthenticated = getItem("islogin") || [];
    return (
      <Suspense fallback={<Skeleton width={"100%"} height={1000} />}>
        {window.location.pathname === "/terms-and-conditions" ? (
          <PageSettings.Provider value={this.state}>
            <Redirect to="/terms-and-conditions" />
            {this.state.pageContent && <Content />}
          </PageSettings.Provider>
        ) : window.location.pathname === "/privacy-policy" ? (
          <PageSettings.Provider value={this.state}>
            <Redirect to="/privacy-policy" />
            {this.state.pageContent && <Content />}
          </PageSettings.Provider>
        ) : isAuthenticated === getToday() ? (
          <PageSettings.Provider value={this.state}>
            <div
              className={
                "fade page-sidebar-fixed show page-container " +
                (this.state.pageHeader ? "page-header-fixed " : "") +
                (this.state.pageSidebar ? "" : "page-without-sidebar ") +
                (this.state.pageSidebarWide ? "page-with-wide-sidebar " : "") +
                (this.state.pageSidebarMinify ? "page-sidebar-minified " : "") +
                (this.state.pageSidebarToggled ? "page-sidebar-toggled " : "") +
                (this.state.pageRightSidebarCollapsed
                  ? "page-right-sidebar-collapsed "
                  : "") +
                (this.state.pageMobileRightSidebarToggled
                  ? "page-right-sidebar-toggled "
                  : "") +
                (this.state.hasScroll ? "has-scroll " : "")
              }
            >
              {this.state.pageHeader && <Header />}
              {this.state.pageSidebar && <Sidebar />}
              {this.state.pageContent && <Content />}
              <FloatSubMenu />
            </div>
            <ModalLocal
              size={"p"}
              isOpen={
                this.props.modalName === "JENIS_GEOUP"
                  ? true
                  : this.props.modalName === "MATI"
                  ? false
                  : this.props.socketData?.progress ===
                    this.props.socketData?.total
                  ? false
                  : true // isOpen kondisi untuk membuka modal true itu untuk menbuka modal false untuk menutup modal
              }
              disabledheader //Untuk menghide header
            >
              <Progresbar // module untuk menampilkan proges bar
                typeProsesSocket={this.props.typeProsesSocket}
                modalName={this.props.modalName}
                progress={this.props.socketData?.progress} // proses untuk menampilkan proges pengemabilan socketData dengan socket
                total={this.props.socketData?.total} // total jumlah data yang sedang di ambil
              />
            </ModalLocal>
          </PageSettings.Provider>
        ) : (
          <PageSettings.Provider value={this.state}>
            {localStorage.clear()}
            <Redirect to="/" />
            {this.state.pageContent && <Content />}
          </PageSettings.Provider>
        )}

        {this.props.loading && <LoadingContent />}
      </Suspense>
    );
  }
}

const maptostate = (state) => {
  // console.log(state.datamaster.getDataTmp);
  return {
    typeProsesSocket: state.datamaster.getDataTmp,
    loading: state.datamaster.isLoading,
    modalName: state.datamaster.getDataTmp?.modalName || false,
    socketData: state.datamaster.getDataTmp?.socketData || [],
    resultData: state.datamaster.getDataTmp?.resultData || [],
  };
};
App = reduxForm({
  form: "App",
  enableReinitialize: true,
})(App);
export default connect(maptostate)(App);
