import {setItem} from "../components/helpers/function";

export const SET_PORTAL = "SET_PORTAL";


export const setPortal = (portal) => {
    return async ( dispatch ,getState) => {
        // if(portal.contain)
        const portal1 = portal + "/api/v1/";
        // const portal2 = portal.slice(0, -4) + String(Number(portal.slice(-4)) + 1) + '/';
        // console.log(portal2)

        setItem("portal1", portal1);
        // setItem("portal2", portal2);
        dispatch({
            type : SET_PORTAL,
            payload : {
                portal1,
                // portal2
            }
        })
    }
}