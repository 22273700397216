const ValidasiMutasiUang = (value) => {
  const errors = {};
  if (!value.kategori) {
    errors.kategori = "Kategori Tidak Boleh Kosong";
  }
  if (!value.tujuan_mutasi_bank) {
    errors.tujuan_mutasi_bank = "Asal Tidak Boleh Kosong";
  }
  if (!value.asal_mutasi_bank) {
    errors.asal_mutasi_bank = "Asal Tidak Boleh Kosong";
  }

  if (!value.keterangan) {
    errors.keterangan = "Keterangan Tidak Boleh Kosong";
  }
  if (!value.jumlah_rp) {
    errors.jumlah_rp = "Jumlah Rp Tidak Boleh Kosong";
  }

  return errors;
};

export default ValidasiMutasiUang;
