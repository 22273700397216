import {
  setItem,
  getData,
  getDataNoTimeOut,
  ToastNotification,
  getDataParams,
  postDataNoTImeOut,
  getItem,
} from "../components/helpers/library";
import { isLoading, simpanDataTmp } from "./datamaster_action";
//Terima Barang
export const GET_NO_KIRIM = "GET_NO_KIRIM";
export const SHOW_MODAL_TERIMA_BARANG = "SHOW_MODAL_TERIMA_BARANG";
export const SET_CAMERA = "SET_CAMERA";
export const SET_BARANG_PINDAH = "SET_BARANG_PINDAH";
export const SET_BARANG_PINDAH_SATU_BAKI = "SET_BARANG_PINDAH_SATU_BAKI";
export const SET_HANCUR_BARANG = "SET_HANCUR_BARANG";
export const SHOW_MODAL_EDIT = "SHOW_MODAL_EDIT";
export const SHOW_MODAL_PESANAN = "SHOW_MODAL_PESANAN";
export const GET_TIMBANG_BARANG = "GET_TIMBANG_BARANG";
export const UPDATE_TIMBANG_BARANG = "UPDATE_TIMBANG_BARANG";
export const REPORT_AUTHORIZATION = "REPORT_AUTHORIZATION";
export const GET_BARANG_BARCODE = "GET_BARANG_BARCODE";

export const FORM_MODAL = "FORM_MODAL";
export const GET_NO_KIRIM_UNRECEIVE = "GET_NO_KIRIM_UNRECEIVE";
export const GET_DATA_BARANG_MANUAL = "GET_DATA_BARANG_MANUAL";

export const FormModal = (data) => {
  return (dispatch) => {
    dispatch({
      type: FORM_MODAL,
      payload: {
        data: data,
      },
    });
  };
};
export const ShowModalTerimaBarang = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_TERIMA_BARANG,
      payload: {
        data: data,
      },
    });
  };
};

export const setCamera = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_CAMERA,
      payload: {
        data: data,
      },
    });
  };
};
export const showEditBarang = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_EDIT,
      payload: {
        data: data,
      },
    });
  };
};
export const showModalPesanan = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_PESANAN,
      payload: {
        data: data,
      },
    });
  };
};
export const setBarangPindah = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_BARANG_PINDAH,
      payload: {
        data: data,
      },
    });
  };
};
export const setHancurBarang = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_HANCUR_BARANG,
      payload: {
        data: data,
      },
    });
  };
};
export const setBarangPindahSatuBaki = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_BARANG_PINDAH_SATU_BAKI,
      payload: {
        data: data,
      },
    });
  };
};

export const getDataBarangManual = (data) => {
  return (dispatch) => {
    dispatch(isLoading(true));
    let row = {
      kode_group: data?.kode_group?.toUpperCase(),
      kode_dept: data?.kode_dept?.toUpperCase(),
      kode_toko: data?.kode_baki?.toUpperCase(),
      kode_gudang: data?.kode_gudang?.toUpperCase(),
    };
    getDataParams("barang-manual", row)
      .then((res) => {
        dispatch(isLoading(false));
        dispatch({
          type: GET_DATA_BARANG_MANUAL,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        dispatch(isLoading(false));
        console.log(err);
      });
  };
};

export const getNokirim = (data) => {
  return (dispatch) => {
    getData("kirimbarang/get/nokirim")
      .then((res) => {
        dispatch({
          type: GET_NO_KIRIM,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getNokirimUnreceive = (data) => {
  return (dispatch) => {
    getData("kirim-barang/unreceive/list-no-kirim")
      .then((res) => {
        dispatch({
          type: GET_NO_KIRIM_UNRECEIVE,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const resetTimbangBaki = () => {
  return (dispatch) => {
    postDataNoTImeOut(`timbang-baki/reset`)
      .then((res) => {
        if (res.data.length === 0) {
          ToastNotification("info", "Data Tidak Tersedia");
          return false;
        }
        setItem("timbang_barang", []);
        dispatch({
          type: GET_TIMBANG_BARANG,
          payload: {
            data: [],
          },
        });
      })
      .catch((err) => {
        ToastNotification("info", err?.response?.data || "Data Tidak Tersedia");
        dispatch(
          simpanDataTmp({
            info: "Timbang Baki Tersdia",
            status: true,
          })
        );
        // console.log(err);
      });
  };
};
export const lanjutkanTimbangBaki = () => {
  return (dispatch) => {
    getDataNoTimeOut(`timbang-baki`)
      .then((res) => {
        if (res.data.length === 0) {
          ToastNotification("info", "Data Tidak Tersedia");
          return false;
        }
        setItem("timbang_barang", res.data);
        dispatch({
          type: GET_TIMBANG_BARANG,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        ToastNotification("info", err?.response?.data || "Data Tidak Tersedia");
        dispatch(
          simpanDataTmp({
            info: "Timbang Baki Tersdia",
            status: true,
          })
        );
        // console.log(err);
      });
  };
};
export const getTimbangBarang = (gudang, baki, tanggal) => {
  return (dispatch) => {
    if (gudang === undefined) {
      ToastNotification("info", "Mohon Untuk Pilih Kode Gudang");
      return false;
    }
    if (baki === undefined) {
      ToastNotification("info", "Mohon Untuk Pilih Kode Baki");
      return false;
    }
    dispatch(isLoading(true));
    postDataNoTImeOut(`timbang-baki/start`, {
      kode_gudang: gudang,
      kode_toko: baki,
    })
      .then((res) => {
        if (res.data.length === 0) {
          ToastNotification("info", "Data Tidak Tersedia");
          return false;
        }
        let dataLocal = getItem("timbang_barang");
        var dataLocalStorage = [];
        if (dataLocal.length !== 0) {
          for (let i = 0; i < dataLocal.length; i++) {
            dataLocalStorage.push(dataLocal[i]);
          }
        }

        for (let i = 0; i < res.data.data_timbang.length; i++) {
          dataLocalStorage.push(res.data.data_timbang[i]);
        }

        setItem("timbang_barang", dataLocalStorage);
        // setItem("timbang_barang", res.data.data_timbang);
        dispatch({
          type: GET_TIMBANG_BARANG,
          payload: {
            data: dataLocalStorage,
          },
        });
        dispatch(isLoading(false));
      })
      .catch((err) => {
        ToastNotification("info", err?.response?.data || "Data Tidak Tersedia");
        dispatch(
          simpanDataTmp({
            info: "Timbang Baki Tersdia",
            status: true,
          })
        );
        dispatch(isLoading(false));
        // console.log(err);
      });
  };
};
export const updateTimbangBarang = (data) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_TIMBANG_BARANG,
      payload: {
        data: data,
      },
    });
  };
};

export const getLaporanOtorisasi = (tgl_awal, tgl_akhir) => {
  return (dispatch) => {
    getDataNoTimeOut(
      `authorization/reports?tgl_from=${tgl_awal}&tgl_to=${tgl_akhir}`
    )
      .then((res) => {
        dispatch({
          type: REPORT_AUTHORIZATION,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getListBarangBarcode = (kode_gudang, kode_toko) => {
  return (dispatch) => {
    getData(
      `tambah-barang?kode_gudang=${kode_gudang || "ALL"}&kode_toko=${
        kode_toko || "ALL"
      }`
    )
      .then((res) => {
        dispatch({
          type: GET_BARANG_BARCODE,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
