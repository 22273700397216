import { getUangBanks } from "../../../actions/datamaster_action";
import {
  React,
  connect,
  reduxForm,
  PanelContent,
  // Tabel,
  showModal,
  ModalGlobal,
  ToastNotification,
  postDataNoTImeOut,
  hideModal,
} from "../../../components/helpers/library";
import HeadMutasiUangBank from "./HeadMutasiUangBank";
const maptostate = (state) => {
  return {
    databank: state.datamaster.getUangBanks,
  };
};
class MutasiUangBank extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      columns: [
        {
          dataField: "kategori",
          text: "Kategori",
          footer: "",
          footerAttrs: {
            colSpan: "2",
          },
        },
        {
          // dataField: "keterangan",
          dataField: "keterangan",
          text: "Keterangan",
          formatter: (cell, row) => {
            return <div className="text-left">{cell.toUpperCase()}</div>;
          },
        },
        // {
        //   dataField: "deskripsi",
        //   text: "Deskripsi",
        // },
        {
          dataField: "jumlah_in",
          text: "Jumlah",
          headerClasses: "text-right",
          formatter: (cell, row) => {
            return (
              <div className="text-right">
                {" "}
                {row.jumlah_in === 0
                  ? row.jumlah_out.toLocaleString("kr-KO")
                  : row.jumlah_in.toLocaleString("kr-KO")}{" "}
              </div>
            );
          },
          footer: (cell) => {
            return (
              <div className="text-right">
                {" "}
                {cell
                  .reduce((a, b) => a + parseInt(b), 0)
                  .toLocaleString("kr-KO")}{" "}
              </div>
            );
          },
        },
      ],
    };
    this.simpanMutasiUangBank = this.simpanMutasiUangBank.bind(this);
  }

 

  simpanMutasiUangBank(data) {
    if (data.rek_tujuan_mutasi_bank === data.rek_asal_mutasi_bank) {
      ToastNotification("info", "Rekening Tidak Boleh Sama");
      this.setState({
        isLoading: false,
      });
    } else {
      let data_post = {
        asal: data.asal_mutasi_bank,
        asal_rekening: data.rek_asal_mutasi_bank,
        tujuan: data.tujuan_mutasi_bank,
        tujuan_rekening: data.rek_tujuan_mutasi_bank,
        jumlah: parseInt(data.jumlah_rp || 0),
        keterangan: data.keterangan,
        kategori: data.kategori,
      };
      this.setState({
        isLoading: true,
      });
      postDataNoTImeOut("cash/mutasi-uang-bank", data_post, [], true)
        .then((res) => {
          this.props.dispatch(hideModal());
          ToastNotification("success", "Mutasi Uang Bank Berhasil Disimpan");
          this.setState({
            isLoading: false,
          });
          this.props.dispatch(getUangBanks());
        })
        .catch((err) => {
          this.props.dispatch(hideModal());
          ToastNotification("info", "Gagal Menambahkan Mutasi Uang Bank");
          this.setState({
            isLoading: false,
          });
        });
    }
  }
  

  componentDidMount() {
    // this.props.dispatch(getUangBanks());
  }
  showModal(row) {
    this.setState({
      form: row,
    });
    this.props.dispatch(showModal());
  }
  render() {
    return (
      <PanelContent menu="Mutasi Uang Bank">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => this.showModal("TAMBAH")}
        >
          Mutasi Uang Bank
        </button>
        {/* <Tabel
          keyField="_id"
          exportCsv={false}
          tambahData={true}
          handleClick={() => this.showModal("TAMBAH")}
          btnText="Mutasi Uang Bank"
        //   btnTextOptional2="Ambil Uang Bank"
        //   btnOptional2={true}
        //   iconBtnOptional2={
        //     <>
        //       <i className="fa fa-minus ml-3"> </i>
        //     </>
        //   }
          handleClickOptional2={() => this.showModal("AMBIL")}
          data={this.props.databank}
          columns={this.state.columns}
          empty={true}
          textEmpty="Uang Bank Kosong"
        /> */}
        <ModalGlobal
          size="P"
          title={"Mutasi Uang Bank"}
          content={
            <HeadMutasiUangBank onSubmit={(data) => this.simpanMutasiUangBank(data)} />
          }
        />
      </PanelContent>
    );
  }
}

MutasiUangBank = reduxForm({
  form: "MutasiUangBank",
  enableReinitialize: true,
})(MutasiUangBank);
export default connect(maptostate)(MutasiUangBank);
