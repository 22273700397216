import React, { Component } from "react";
import { connect } from "react-redux";
import { change, Field, reduxForm } from "redux-form";
import Loading from "react-fullscreen-loading";
import { hideModal } from "../../../../actions/datamaster_action";
import {
  ReanderField,
  ReanderSelect,
  ReanderSelectMultiple,
} from "../../../../components/helpers/field";
import { resizeFile } from "../../../../components/helpers/function";
// import { Gramasi } from "Validasi/normalize";
import { Gramasi } from "../../../../Validasi/normalize";
import ValidasiBarangKatalog from "../validation-form/validasi_barang_katalog";

const maptostate = (state) => {
  if (state.stateItemKatalog.ShowModal !== null) {
    return {
      gambar_path: state.stateItemKatalog.ShowModal.gambar_path,
      listBanner: state.stateBannerKatalog.bannerKatalog,
      listInfo: state.stateInfoKatalog.infoKatalog,
      listKategori: state.stateKategoriKatalog.kategoriKatalog,
      isLoading: state.utility.isLoading,
      isEdit: true,
      initialValues: {
        kode_barang: state.stateItemKatalog.ShowModal.kode_barang,
        nama_barang: state.stateItemKatalog.ShowModal.nama_barang,
        berat: state.stateItemKatalog.ShowModal.berat,
        harga_jual: state.stateItemKatalog.ShowModal.harga_jual,
        kode_kategori: state.stateItemKatalog.ShowModal.kode_kategori,
        kode_banner: state.stateItemKatalog.ShowModal.kode_banner,
        kode_info: state.stateItemKatalog.ShowModal.kode_info,
        direct_url: state.stateItemKatalog.ShowModal.direct_url,
        gambar_path: state.stateItemKatalog.ShowModal.gambar_path,
        gambar_name: state.stateItemKatalog.ShowModal.gambar_name,
        hashtag_jenis:
          state.stateItemKatalog.ShowModal.hashtag_jenis
            ?.map((data) => {
              if (data !== undefined) {
                return "#" + data?.trim();
              } else {
                return false;
              }
            })
            ?.join(",") || undefined,
        hashtag_kadar:
          state.stateItemKatalog.ShowModal.hashtag_kadar
            ?.map((data) => {
              if (data !== undefined) {
                return "#" + data?.trim();
              } else {
                return false;
              }
            })
            ?.join(",") || undefined,
        hashtag_lm:
          state.stateItemKatalog.ShowModal.hashtag_km
            ?.map((data) => {
              if (data !== undefined) {
                return "#" + data?.trim();
              } else {
                return false;
              }
            })
            ?.join(",") || undefined,
      },
    };
  } else {
    return {
      isLoading: state.utility.isLoading,
      listBanner: state.stateBannerKatalog.bannerKatalog,
      listInfo: state.stateInfoKatalog.infoKatalog,
      listKategori: state.stateKategoriKatalog.kategoriKatalog,
    };
  }
};
class FormItemKatalog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listImage: [],
    };
  }

  setFocus() {
    setTimeout(() => {
      document.getElementById("kode_barang").focus();
    }, 100);
  }

  getValue = async (event) => {
    const file = Array.from(event.target.files);

    await Promise.all(
      file.map(async (data) => {
        const base64 = await resizeFile(data);
        this.setState({
          listImage: [base64],
        });
      })
    );

    this.props.dispatch(
      change("FormItemKatalog", "gambar_path", this.state.listImage)
    );
  };

  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit}
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
        autoComplete="off"
      >
        <Field
          tabIndex="1"
          id="kode_kategori"
          name="kode_kategori"
          component={ReanderSelect}
          options={this.props.listKategori.map((data) => {
            return {
              value: data.kode_kategori,
              name: data.nama_kategori,
            };
          })}
          type="text"
          label="Kode Kategori"
          placeholder="Silahkan Pilih Kategori"
        />
        <Field
          tabIndex="2"
          id="nama_barang"
          name="nama_barang"
          component={ReanderField}
          type="text"
          label="Nama Item"
          placeholder="Masukan Deskripsi Item"
        />
        <Field
          tabIndex="3"
          id="berat"
          name="berat"
          component={ReanderField}
          type="text"
          label="Berat Item"
          normalize={Gramasi}
          placeholder="Masukan Berat Item"
        />
        <small>Masukan Kadar Dengan Awalan # dan Pemisan KOMA</small>
        <Field
          tabIndex="4"
          id="hashtag_kadar"
          name="hashtag_kadar"
          component={ReanderField}
          type="text"
          label="Hashtag Kadar"
          placeholder="contoh: #8K,#16K"
        />
        <small>Masukan Kadar Dengan Awalan # dan Pemisan KOMA</small>
        <Field
          tabIndex="5"
          id="hashtag_jenis"
          name="hashtag_jenis"
          component={ReanderField}
          type="text"
          label="Hashtag Jenis"
          placeholder="contoh: #anting,#anting anak"
        />
        <small>Masukan Kadar Dengan Awalan # dan Pemisan KOMA</small>
        <Field
          tabIndex="6"
          id="hashtag_lm"
          name="hashtag_lm"
          component={ReanderField}
          type="text"
          label="Hashtag LM"
          placeholder="contoh: #ubs,#antam"
        />
        <Field
          tabIndex="7"
          id="direct_url"
          name="direct_url"
          component={ReanderField}
          type="text"
          label="URL Pendukung"
          nouperCase
        />
        <Field
          tabIndex="8"
          id="kode_banner"
          name="kode_banner"
          component={ReanderSelectMultiple}
          options={this.props.listBanner.map((data) => {
            return {
              value: data.kode_banner,
              label: data.deskripsi,
            };
          })}
          type="text"
          label="Kode Banner"
          placeholder="Silahkan Pilih Banner"
        />
        <Field
          tabIndex="9"
          id="kode_info"
          name="kode_info"
          component={ReanderSelectMultiple}
          options={this.props.listInfo.map((data) => {
            return {
              value: data.kode_info,
              label: data.deskripsi,
            };
          })}
          type="text"
          label="Kode Info"
          placeholder="Silahkan Pilih Info"
        />
        <Field
          tabIndex="10"
          id="harga_jual"
          name="harga_jual"
          component={ReanderField}
          nouperCase
          type="text"
          label="Harga Jual"
          placeholder="Masukan Harga Jual"
        />
        <div className="form-group">
          <input
            type="file"
            id="gambar"
            name="gambar"
            onChange={(e) => this.getValue(e)}
          />
        </div>

        <Field
          tabIndex="11"
          id="gambar_path"
          name="gambar_path"
          component={ReanderField}
          type="text"
          label="Gambar Item"
          readOnly={true}
          placeholder="Masukan Gambar Item"
        />
        {this.props.gambar_path ? (
          this.state.listImage.length > 0 ? (
            <div className="col-lg-12">
              {this.state.listImage.map((e, index) => {
                return (
                  <img
                    alt={"previewIcon-" + index}
                    id={"preview-" + index}
                    className="img-thumbnail mb-2"
                    src={e}
                  />
                );
              })}
            </div>
          ) : (
            <div className="col-lg-12">
              {this.props.gambar_path.map((e, index) => {
                return (
                  <img
                    alt={"previewIcon-" + index}
                    id={"preview-" + index}
                    className="img-thumbnail mb-2"
                    src={e}
                  />
                );
              })}
            </div>
          )
        ) : (
          <div className="col-lg-12">
            {this.state.listImage.map((e, index) => {
              return (
                <img
                  alt={"previewIcon-" + index}
                  id={"preview-" + index}
                  className="img-thumbnail mb-2"
                  src={e}
                />
              );
            })}
          </div>
        )}
        <div className="row">
          <div className="col-12 text-right">
            <button
              className="btn btn-white"
              onClick={() => this.props.dispatch(hideModal())}
              type="button"
              disabled={this.props.isLoading}
            >
              Batal
            </button>
            &nbsp;
            <button
              tabIndex="8"
              className={this.state.aktif ? "btn btn-primary" : "btn btn-white"}
              disabled={this.props.submitting}
              type="submit"
            >
              {this.props.isLoading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                  Menyimpan
                </>
              ) : (
                "Simpan Data"
              )}
            </button>
          </div>
        </div>
        {this.props.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </form>
    );
  }
}

FormItemKatalog = reduxForm({
  form: "FormItemKatalog",
  enableReinitialize: true,
  validate: ValidasiBarangKatalog,
})(FormItemKatalog);
export default connect(maptostate, null)(FormItemKatalog);
