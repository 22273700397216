const ValidasiBannerKatalog = (value) => {
    const errors = {};
    if (!value.title) {
      errors.title = "Title Tidak Boleh Kosong";
    }
    if (!value.info) {
      errors.info = "Info Tidak Boleh Kosong";
    }
  
    if (!value.sub_info) {
      errors.sub_info = "Sub Info Tidak Boleh Kosong ";
    }
  
    if (!value.url_pendukung) {
      errors.url_pendukung = "URL Pendukung Tidak Boleh Kosong";
    }
  
    if (!value.nama_tombol) {
      errors.nama_tombol = "URL Pendukung Tidak Boleh Kosong";
    }
  
    return errors;
  };
  
  export default ValidasiBannerKatalog;
  