import React, { Component } from "react";
import { connect } from "react-redux";
import { change, Field, reduxForm } from "redux-form";
import Loading from "react-fullscreen-loading";
import { hideModal } from "../../../../actions/datamaster_action";
import { ReanderField, renderTextArea } from "../../../../components/helpers/field";
import { resizeFile } from "../../../../components/helpers/function";
import { NumberOnly } from "../../../../Validasi/normalize";
import ValidasiHadiahKatalog from "../validation-form/validasi_hadiah";

const maptostate = (state) => {
  if (state.stateHadiahKatalog.ShowModal !== null) {
    return {
      gambar_path: state.stateHadiahKatalog.ShowModal.gambar_path,
      isEdit: true,
      isLoading: state.utility.isLoading,
      initialValues: {
        kode_hadiah: state.stateHadiahKatalog.ShowModal.kode_hadiah,
        nama_hadiah: state.stateHadiahKatalog.ShowModal.nama_hadiah,
        info: state.stateHadiahKatalog.ShowModal.info,
        sub_info: state.stateHadiahKatalog.ShowModal.sub_info,
        deskripsi: state.stateHadiahKatalog.ShowModal.deskripsi,
        point: state.stateHadiahKatalog.ShowModal.point,
        gambar_path: state.stateHadiahKatalog.ShowModal.gambar_path,
        gambar_name: state.stateHadiahKatalog.ShowModal.gambar_name,
      },
    };
  } else {
    return {
      isLoading: state.utility.isLoading,
    };
  }
};
class FormHadiahKatalog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listImage: [],
    };
  }

  setFocus() {
    setTimeout(() => {
      document.getElementById("kode_barang").focus();
    }, 100);
  }

  getValue = async (event) => {
    const file = Array.from(event.target.files);

    await Promise.all(
      file.map(async (data) => {
        const base64 = await resizeFile(data);
        this.setState({
          listImage: base64,
        });
      })
    );

    this.props.dispatch(
      change("FormHadiahKatalog", "gambar_path", this.state.listImage)
    );
  };

  render() {
    return (
      <form onSubmit={this.props.handleSubmit} autoComplete="off">
        <Field
          tabIndex="1"
          id="nama_hadiah"
          name="nama_hadiah"
          component={ReanderField}
          type="text"
          nouperCase
          label="Nama Hadiah"
          placeholder="Masukan Deskripsi Hadiah"
        />
        <Field
          tabIndex="2"
          id="info"
          name="info"
          nouperCase
          component={ReanderField}
          type="text"
          label="Info Hadiah"
          placeholder="Masukan Info Hadiah"
        />
        <Field
          tabIndex="3"
          id="sub_info"
          name="sub_info"
          component={ReanderField}
          type="text"
          nouperCase
          label="SubInfo Hadiah"
          placeholder="Masukan SubInfo Hadiah"
        />
        <Field
          tabIndex="4"
          id="deskripsi"
          name="deskripsi"
          component={renderTextArea}
          type="text"
          nouperCase
          label="Deskripsi Hadiah"
          placeholder="Masukan Deskripsi Hadiah"
        />
        <Field
          tabIndex="5"
          id="point"
          name="point"
          component={ReanderField}
          type="text"
          normalize={NumberOnly}
          label="Point Hadiah"
          placeholder="Masukan Point Hadiah"
        />
        <div className="form-group">
          <input
            type="file"
            id="gambar"
            name="gambar"
            onChange={(e) => this.getValue(e)}
          />
        </div>

        <Field
          tabIndex="5"
          id="gambar_path"
          name="gambar_path"
          component={ReanderField}
          type="text"
          label="Gambar Hadiah"
          readOnly={true}
          placeholder="Masukan Gambar Hadiah"
        />
        {this.props.gambar_path ? (
          this.state.listImage.length > 0 ? (
            <img
              alt={"previewIcon"}
              id={"preview"}
              className="img-thumbnail mb-2"
              src={this.state.listImage}
            />
          ) : (
            <img
              alt={"previewIcon"}
              id={"preview"}
              className="img-thumbnail mb-2"
              src={this.props.gambar_path}
            />
          )
        ) : (
          <img
            alt={"previewIcon"}
            id={"preview"}
            className="img-thumbnail mb-2"
            src={this.state.listImage}
          />
        )}
        <div className="row">
          <div className="col-12 text-right">
            <button
              className="btn btn-white"
              onClick={() => this.props.dispatch(hideModal())}
              type="button"
              disabled={this.props.isLoading}
            >
              Batal
            </button>
            &nbsp;
            <button
              tabIndex="8"
              className={this.state.aktif ? "btn btn-primary" : "btn btn-white"}
              disabled={this.props.submitting}
              type="submit"
            >
              {this.props.isLoading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                  Menyimpan
                </>
              ) : (
                "Simpan Data"
              )}
            </button>
          </div>
        </div>
        {this.props.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </form>
    );
  }
}

FormHadiahKatalog = reduxForm({
  form: "FormHadiahKatalog",
  enableReinitialize: true,
  validate: ValidasiHadiahKatalog,
})(FormHadiahKatalog);
export default connect(maptostate, null)(FormHadiahKatalog);
