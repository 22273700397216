import { FORM_MODAL } from "../actions/databarang_action";
import {
  GET_DATA_PROVINSI,
  GET_DATA_BATU_COLOR,
  GET_DATA_CLARITY,
  GET_DATA_SHAPE,
  SET_FOCUS,
  SHOW_DATA_MODAL,
  GET_UANG_BANK,
  HIDE_MODAL,
  SHOW_MODAL,
  EDIT_DATA_PROVINSI,
  SHOW_MODAL_KATEGORI,
  SHOW_MODAL_JENIS,
  SHOW_MODAL_KODE_TRANSAKSI,
  SHOW_MODAL_GUDANG,
  SHOW_MODAL_BAKI,
  SHOW_MODAL_SALES,
  SHOW_MODAL_TUKANG,
  SHOW_MODALKONDISI_BARANG,
  SHOW_MODOAL_KONDISI_PEMBELIAN,
  SHOW_MODAL_KONDISI_PINJAMAN,
  SHOW_MODAL_DATA_HADIAH,
  SHOW_MODAL_RUGI_NOTA,
  SHOW_MODAL_GROUP,
  GET_DATA_GROUP,
  GET_DATA_JENIS,
  GET_DATA_KADAR_EOD,
  SHOW_MODAL_KADAR,
  GET_DATA_KADAR,
  GET_DATA_GUDANG,
  GET_DATA_BAKI,
  GET_BANDROL,
  SHOW_MODAL_BANDROL,
  GET_DATA_SALES,
  GET_DATA_TUKANG,
  GET_KODE_TRANSAKSI,
  GET_DATA_KONDISI_BARANG,
  GET_KONDISI_PEMBELIAN,
  GET_KONDISI_PINJAMAN,
  GET_PARAMETER_POINT,
  SHOW_MODAL_PARAMETER_POINT,
  GET_DATA_HADIAH,
  SHOW_MODAL_PARAMETER_HARGA_EMAS,
  GET_PARAMETER_HARGA_EMAS,
  GET_PARAMETER_RUGI_NOTA,
  ISLOADING,
  ISEDIT,
  SHOW_MODAL_MARKETPLACE,
  GET_DATA_MARKETPLCAE,
  SET_NO,
  GET_DATA_KAS,
  GET_DATA_FLU,
  GET_DATA_SYEMETRY,
  GET_DATA_POLISH,
  GET_DATA_CUTGRAD,
  GET_BANK,
  GET_PARAM_HARGA_BELI,
  GET_REKENING,
  GET_JENIS_BATU,
  GET_DATA_PENJUALAN_PERBULAN,
  GET_DATA_PEMBELIAN_PERBULAN,
  GET_PENJUALAN_TODAY,
  GET_PEMBELIAN_TODAY,
  GET_PESANAN_TODAY,
  GET_SERVICE_TODAY,
  GET_PARAMETER_ONGKOS,
  GET_DATA_JENIS_BERLIAN,
  SHOW_MODAL_SUPPLIER_BERLIAN,
  GET_DATA_SUPPLIER_BERLIAN,
  GET_MASTER_TOKO,
  SHOW_MODAL_MASTER_TOKO,
  SHOW_MODAL_DATA_ONGKOS,
  GET_PLAFOND,
  GET_PARAMETER_KETERANGAN_NOTA,
  GET_JENIS_GROUP,
  SHOW_MODAL_JENIS_GROUP,
  KATEGORI_HARGA,
  GET_MASTER_POTONGAN_PENJUALAN,
  GET_DATA_ULANGTAHUN,
  GET_BONUS_SALES,
  SET_STOCK_OPNAME_GLOBAL,
  GET_DATA_SUPPLIER,
  GET_DATA_BUNGA_HUTANG,
  GET_PLAFOND_HUTANG_KADAR,
  DATA_TMP,
  GET_KELOMPOK_MASTER_HARGA,
  DATA_SERVICE,
  PARA_HUTANG_POKOK,
  GET_DATA_PENGALIHAN_SALDO,
  DATA_GESEK,
  PRE_ORDER,
  GET_PO_FINISH,
  GET_PARAMETER_CICILAN,
  GET_PARAMETER_KUPON,
  AKTIF_TGL,
  GET_PARAM_POINT_MEMBER,
} from "../actions/datamaster_action";

const initialState = {
  getUangBanks: [],
  getDataProvinsi: false,
  modalDialog: false,
  editModalProvinsi: false,
  ShowtModalKategori: false,
  ShowModalJenis: false,
  ShowModalKodeTransaksi: false,
  ShowModalGudang: false,
  ShowModalBaki: false,
  ShowModalSales: false,
  ShowModalSupplierBerlian: false,
  ShowModalTukang: false,
  ShowModalKondisiBarang: false,
  ShowModalKondisiPembelian: false,
  ShowModalKondisiPinjaman: false,
  ShowModalDataHadiah: false,
  ShowModalParameterRugiNota: false,
  ShowModalGroup: false,
  GetDataGroup: [],
  getDataJenis: [],
  getDataKadarEod: [],
  ShowModalDataKadar: false,
  getDataKadar: false,
  getDataGudang: [],
  getDataBaki: [],
  getBandrol: false,
  ShowModalBandrol: false,
  getDataSales: [],
  getDataSupplierBerlian: [],
  getDataTukang: false,
  getParamterTransaksi: [],
  getDataKondisiBarang: [],
  getKondisiPembelian: [],
  getKondisiPinjaman: [],
  getParameterPoint: [],
  ShowModalParameterPoint: [],
  ShowModalParameterHargaEmas: [],
  getParameterHargaEmas: [],
  getDataHadiah: [],
  getParameterRugiNota: [],
  isLoading: false,
  isEdit: false,
  getAktifTgl: false,
  setFocus: false,
  getDataMarketplace: [],
  showModalMarketPlace: [],
  getDataKas: [],
  setNo: "",
  ShowDataModal: false,
  getDataClarity: [],
  getDataShape: [],
  getBatuColor: [],
  getDataFluorencence: [],
  getDataSymetry: [],
  getDataPolish: [],
  getDataCutgrad: [],
  getDataBanks: [],
  getDataRekening: [],
  getJenisBatu: [],
  FormModal: "",
  getDataPenjualanPerbulan: [],
  getDataPembelianPerbulan: [],
  getJenisBerlian: [],
  getMasterToko: [],
  showModalDataOngkos: [],
  getDataOngkos: [],
  showModalDataToko: [],
  getPembelianToday: 0,
  getPesananToday: 0,
  getServicesToday: 0,
  getDataPlafond: [],
  getParameterKeteranganNota: [],
  GetJenisGroup: [],
  getMasterPotonganPenjualan: [],
  getDataUlangTahun: [],
  getBonusSales: [],
  setStockOpnameGlobal: [],
  getDataSupplier: [],
  getDataBungaHutang: [],
  getPlafondHutangKadar: [],
  getDataTmp: [],
  getMasterKelompokHarga: [],
  getDataService: [],
  getParaHutangPokok: [],
  getDataPengalihanSaldo: [],
  getDataGesek: [],
  getParameterCicilan: [],
  getDataParamHrgBeli: [],
  getParamterKupon: [],
  getPoFinish: {
    count: 0,
    preorders: [],
  },
  getDataPreOrder: {
    count: 0,
    preorders: [],
    skip: 0,
    limit: 50,
    page: 1,
  },
};

const datamaster = (state = initialState, actions) => {
  switch (actions.type) {
    case AKTIF_TGL:
      return {
        ...state,
        getAktifTgl: actions.payload,
      };
    case GET_PARAMETER_KUPON:
      return {
        ...state,
        getParamterKupon: actions.payload.data,
      };
    case GET_PARAMETER_CICILAN:
      return {
        ...state,
        getParameterCicilan: actions.payload.data,
      };
    case GET_PO_FINISH:
      return {
        ...state,
        getPoFinish: actions.payload.data,
      };
    case PRE_ORDER:
      return {
        ...state,
        getDataPreOrder: actions.payload.data,
      };
    case DATA_GESEK:
      return {
        ...state,
        getDataGesek: actions.payload.data,
      };
    case GET_DATA_PENGALIHAN_SALDO:
      return {
        ...state,
        getDataPengalihanSaldo: actions.payload.data,
      };
    case DATA_SERVICE:
      return {
        ...state,
        getDataService: actions.payload.data,
      };
    case PARA_HUTANG_POKOK:
      return {
        ...state,
        getParaHutangPokok: actions.payload.data,
      };
    case GET_KELOMPOK_MASTER_HARGA:
      return {
        ...state,
        getMasterKelompokHarga: actions.payload.data,
      };
    case DATA_TMP:
      return {
        ...state,
        getDataTmp: actions.payload.data,
      };
    case GET_PLAFOND_HUTANG_KADAR:
      return {
        ...state,
        getPlafondHutangKadar: actions.payload.data,
      };
    case GET_DATA_BUNGA_HUTANG:
      return {
        ...state,
        getDataBungaHutang: actions.payload.data,
      };
    case GET_DATA_SUPPLIER:
      return {
        ...state,
        getDataSupplier: actions.payload.data,
      };
    case SET_STOCK_OPNAME_GLOBAL:
      return {
        ...state,
        setStockOpnameGlobal: actions.payload.data,
      };
    case GET_DATA_ULANGTAHUN:
      return {
        ...state,
        getDataUlangTahun: actions.payload.data,
      };
    case GET_BONUS_SALES:
      return {
        ...state,
        getBonusSales: actions.payload.data,
      };
    case GET_MASTER_POTONGAN_PENJUALAN:
      return {
        ...state,
        getMasterPotonganPenjualan: actions.payload.data,
      };
    case GET_PARAMETER_KETERANGAN_NOTA:
      return {
        ...state,
        getParameterKeteranganNota: actions.payload.data,
      };
    case GET_PLAFOND:
      return {
        ...state,
        getDataPlafond: actions.payload.data,
      };
    case SHOW_MODAL_DATA_ONGKOS:
      return {
        ...state,
        showModalDataOngkos: actions.payload.data,
      };
    case GET_PARAMETER_ONGKOS:
      return {
        ...state,
        getDataOngkos: actions.payload.data,
      };
    case SHOW_MODAL_MASTER_TOKO:
      return {
        ...state,
        showModalDataToko: actions.payload.data,
      };
    case GET_MASTER_TOKO:
      return {
        ...state,
        getMasterToko: actions.payload.data,
      };
    case GET_DATA_JENIS_BERLIAN:
      return {
        ...state,
        getJenisBerlian: actions.payload.data,
      };
    case GET_SERVICE_TODAY:
      return {
        ...state,
        getServicesToday: actions.payload.data,
      };
    case GET_PESANAN_TODAY:
      return {
        ...state,
        getPesananToday: actions.payload.data,
      };
    case GET_PEMBELIAN_TODAY:
      return {
        ...state,
        getPembelianToday: actions.payload.data,
      };
    case GET_PENJUALAN_TODAY:
      return {
        ...state,
        getPenjualanToday: actions.payload.data,
      };
    case GET_DATA_PEMBELIAN_PERBULAN:
      return {
        ...state,
        getDataPembelianPerbulan: actions.payload.data,
      };
    case GET_DATA_PENJUALAN_PERBULAN:
      return {
        ...state,
        getDataPenjualanPerbulan: actions.payload.data,
      };
    case GET_JENIS_BATU:
      return {
        ...state,
        getJenisBatu: actions.payload.data,
      };
    case FORM_MODAL:
      return {
        ...state,
        FormModal: actions.payload.data,
      };
    case GET_REKENING:
      return {
        ...state,
        getDataRekening: actions.payload.data,
      };
    case GET_BANK:
      return {
        ...state,
        getDataBanks: actions.payload.data,
      };
    case GET_PARAM_HARGA_BELI:
      return {
        ...state,
        getDataParamHrgBeli: actions.payload.data,
      };
    case GET_DATA_CUTGRAD:
      return {
        ...state,
        getDataCutgrad: actions.payload.data,
      };
    case GET_DATA_POLISH:
      return {
        ...state,
        getDataPolish: actions.payload.data,
      };
    case GET_DATA_SYEMETRY:
      return {
        ...state,
        getDataSymetry: actions.payload.data,
      };
    case GET_DATA_FLU:
      return {
        ...state,
        getDataFluorencence: actions.payload.data,
      };
    case GET_DATA_BATU_COLOR:
      return {
        ...state,
        getBatuColor: actions.payload.data,
      };
    case GET_DATA_SHAPE:
      return {
        ...state,
        getDataShape: actions.payload.data,
      };
    case GET_DATA_CLARITY:
      return {
        ...state,
        getDataClarity: actions.payload.data,
      };
    case GET_DATA_KAS:
      return {
        ...state,
        getDataKas: actions.payload.data,
      };
    case SHOW_DATA_MODAL:
      return {
        ...state,
        ShowDataModal: actions.payload.data,
      };
    case SET_NO:
      return {
        ...state,
        setNo: actions.payload.data,
      };
    case SET_FOCUS:
      return {
        ...state,
        setFocus: actions.payload.data,
      };
    case SHOW_MODAL_MARKETPLACE:
      return {
        ...state,
        showModalMarketPlace: actions.payload.data,
      };
    case GET_DATA_MARKETPLCAE:
      return {
        ...state,
        getDataMarketplace: actions.payload.data,
      };
    case GET_UANG_BANK:
      return {
        ...state,
        getUangBanks: actions.payload.data,
      };
    case ISLOADING:
      return {
        ...state,
        isLoading: actions.payload.data,
      };
    case ISEDIT:
      return {
        ...state,
        isEdit: actions.payload.data,
      };
    case GET_PARAMETER_RUGI_NOTA:
      return {
        ...state,
        getParameterRugiNota: actions.payload.data,
      };
    case GET_PARAMETER_HARGA_EMAS:
      return {
        ...state,
        getParameterHargaEmas: actions.payload.data,
      };
    case SHOW_MODAL_PARAMETER_HARGA_EMAS:
      return {
        ...state,
        ShowModalParameterHargaEmas: actions.payload.data,
      };
    case GET_DATA_PROVINSI:
      return {
        ...state,
        getDataProvinsi: actions.payload.data,
      };
    case GET_PARAMETER_POINT:
      return {
        ...state,
        getParameterPoint: actions.payload.data,
      };
    case GET_DATA_HADIAH:
      return {
        ...state,
        getDataHadiah: actions.payload.data,
      };
    case SHOW_MODAL_PARAMETER_POINT:
      return {
        ...state,
        ShowModalParameterPoint: actions.payload.data,
      };
    case HIDE_MODAL:
      return {
        ...state,
        modalDialog: actions.payload.data,
      };
    case SHOW_MODAL:
      return {
        ...state,
        modalDialog: actions.payload.data,
      };
    case EDIT_DATA_PROVINSI:
      return {
        ...state,
        editModalProvinsi: actions.payload.data,
      };
    case SHOW_MODAL_KATEGORI:
      return {
        ...state,
        ShowtModalKategori: actions.payload.data,
      };

    //Data Jenis
    case SHOW_MODAL_JENIS:
      return {
        ...state,
        ShowModalJenis: actions.payload.data,
      };
    case GET_DATA_JENIS:
      return {
        ...state,
        getDataJenis: actions.payload.data,
      };
    case GET_DATA_KADAR_EOD:
      return {
        ...state,
        getDataKadarEod: actions.payload.data,
      };
    //End Jenis

    //Data Kadar
    case SHOW_MODAL_KADAR:
      return {
        ...state,
        ShowModalDataKadar: actions.payload.data,
      };
    case GET_DATA_KADAR:
      return {
        ...state,
        getDataKadar: actions.payload.data,
      };

    //Data Kode Transaksi
    case GET_KODE_TRANSAKSI:
      return {
        ...state,
        getParamterTransaksi: actions.payload.data,
      };
    case SHOW_MODAL_KODE_TRANSAKSI:
      return {
        ...state,
        ShowModalKodeTransaksi: actions.payload.data,
      };

    //Data Gudang
    case SHOW_MODAL_GUDANG:
      return {
        ...state,
        ShowModalGudang: actions.payload.data,
      };
    case GET_DATA_GUDANG:
      return {
        ...state,
        getDataGudang: actions.payload.data,
      };
    //End Data Gudang

    //Data Baki
    case SHOW_MODAL_BAKI:
      return {
        ...state,
        ShowModalBaki: actions.payload.data,
      };
    case GET_DATA_BAKI:
      return {
        ...state,
        getDataBaki: actions.payload.data,
      };

    //ENd Data Baki

    case GET_BANDROL:
      return {
        ...state,
        getBandrol: actions.payload.data,
      };
    case GET_PARAM_POINT_MEMBER:
      return {
        ...state,
        getParamGetPoinMember: actions.payload.data,
      };
    case SHOW_MODAL_BANDROL:
      return {
        ...state,
        ShowModalBandrol: actions.payload.data,
      };

    //Data Sales
    case SHOW_MODAL_SALES:
      return {
        ...state,
        ShowModalSales: actions.payload.data,
      };
    case GET_DATA_SALES:
      return {
        ...state,
        getDataSales: actions.payload.data,
      };

    //End Data Sales

    //Data Supplier Berlian
    case SHOW_MODAL_SUPPLIER_BERLIAN:
      return {
        ...state,
        ShowModalSupplierBerlian: actions.payload.data,
      };
    case GET_DATA_SUPPLIER_BERLIAN:
      return {
        ...state,
        getDataSupplierBerlian: actions.payload.data,
      };

    //End Data Supplier Berlian
    //Function Data Sales
    case SHOW_MODAL_TUKANG:
      return {
        ...state,
        ShowModalTukang: actions.payload.data,
      };
    case GET_DATA_TUKANG:
      return {
        ...state,
        getDataTukang: actions.payload.data,
      };

    //Function Kondisi Barang
    case SHOW_MODALKONDISI_BARANG:
      return {
        ...state,
        ShowModalKondisiBarang: actions.payload.data,
      };
    case GET_DATA_KONDISI_BARANG:
      return {
        ...state,
        getDataKondisiBarang: actions.payload.data,
      };

    //Function Kondisi Pembelian
    case GET_KONDISI_PEMBELIAN:
      return {
        ...state,
        getKondisiPembelian: actions.payload.data,
      };
    case SHOW_MODOAL_KONDISI_PEMBELIAN:
      return {
        ...state,
        ShowModalKondisiPembelian: actions.payload.data,
      };

    //FUnction Kondisi Pinjaman
    case GET_KONDISI_PINJAMAN:
      return {
        ...state,
        getKondisiPinjaman: actions.payload.data,
      };
    case SHOW_MODAL_KONDISI_PINJAMAN:
      return {
        ...state,
        ShowModalKondisiPinjaman: actions.payload.data,
      };

    case SHOW_MODAL_DATA_HADIAH:
      return {
        ...state,
        ShowModalDataHadiah: actions.payload.data,
      };
    case SHOW_MODAL_RUGI_NOTA:
      return {
        ...state,
        ShowModalParameterRugiNota: actions.payload.data,
      };
    case SHOW_MODAL_GROUP:
      return {
        ...state,
        ShowModalGroup: actions.payload.data,
      };
    case GET_DATA_GROUP:
      return {
        ...state,
        GetDataGroup: actions.payload.data,
      };
    case GET_JENIS_GROUP:
      return {
        ...state,
        GetJenisGroup: actions.payload.data,
      };
    case SHOW_MODAL_JENIS_GROUP:
      return {
        ...state,
        ShowModalJenisGroup: actions.payload.data,
      };
    case KATEGORI_HARGA:
      return {
        ...state,
        KategoriHarga: actions.payload.data,
      };
    default:
      return state;
  }
};

export default datamaster;
