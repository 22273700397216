import { hideModal, isLoading, showModal } from "../../../../actions/datamaster_action.jsx";
import { dataURLtoFile, makeID } from "../../../../components/helpers/function";
import { NotifSucces, ToastNotification } from "../../../../components/helpers/library";
import { NotifError } from "../../../../components/helpers/notification";
import {
  deleteData,
  deleteImageApp,
  getData,
  postData,
  postImageApp,
  putData,
} from "../../../../config/axios";
// import {
//   setLoading,
//   stopLoading,
// } from "pages/Penjualan/TransaksiPenjualan/penjualan_action";
import Swal from "sweetalert2";

export const GET_BANNER_KATALOG = "GET_BANNER_KATALOG";
export const SET_DATA_BANNER_KATALOG = "SET_DATA_BANNER_KATALOG";
export const DELETE_BANNER_KATALOG = "DELETE_BANNER_KATALOG";
export const EDIT_BANNER_KATALOG = "EDIT_BANNER_KATALOG";
export const POST_BANNER_KATALOG = "POST_BANNER_KATALOG";

export const fetchBannerKatalog = () => {
  return (dispatch) => {
    dispatch(isLoading(true));
    getData("app/banner-katalog")
      .then((res) => {
        dispatch({
          type: GET_BANNER_KATALOG,
          payload: {
            data: res.data,
          },
        });
        dispatch(isLoading(false));
      })
      .catch((err) => {
        dispatch(isLoading(false));
        console.log(err);
      });
  };
};

export const setEditBannerKatalog = (data) => {
  return async (dispatch) => {
    dispatch({
      type: SET_DATA_BANNER_KATALOG,
      payload: data,
    });
    dispatch(showModal());
  };
};

export const setDeleteBannerKatalog = (data) => {
  return async (dispatch) => {
    Swal.fire({
      html:
        "Apakah Anda Yakin Ingin" +
        "Menghapus " +
        "<h1><b>Kode Banner " +
        data.kode_banner +
        "</b> ?</h1>",
      icon: "warning",
      position: "top-center",
      cancelButtonText: "Tidak",
      showCancelButton: true,
      confirmButtonText: "OK",
      showConfirmButton: true,
    })
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(deleteBannerKatalog(data.kode_banner, data.gambar_name));
        }
      })
      .catch((err) => {
        ToastNotification("error", err.response.data);
      });
  };
};

export const setTambahBannerKatalog = (data) => {
  return async (dispatch) => {
    dispatch({
      type: SET_DATA_BANNER_KATALOG,
      payload: null,
    });
    dispatch(showModal());
  };
};

export const deleteBannerKatalog = (kode_banner, gambar_name) => {
  return (dispatch) => {
    dispatch(isLoading(true));
    deleteData("app/banner-katalog/delete/" + kode_banner)
      .then((res) => {
        NotifSucces("Data Berhasil Di Hapus").then(async () => {
          await deleteImageApp(`BANNER_ICON/${gambar_name}`);
          dispatch(fetchBannerKatalog());
          dispatch(isLoading(false));
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(isLoading(false));
        NotifError("Gagal Menghapus Banner Katalog, Coba Beberapa Saat Lagi");
      });
  };
};

export const updateBannerKatalog = (kode_banner, data) => {
  return (dispatch) => {
    dispatch(isLoading(true));
    putData("app/banner-katalog/edit/" + kode_banner, data)
      .then(async (res) => {
        await NotifSucces("Data Berhasil Dirubah");
        dispatch(fetchBannerKatalog());
        dispatch(hideModal());
        dispatch(isLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(isLoading(false));
        NotifError("Gagal Mengubah Banner Katalog, Coba Beberapa Saat Lagi");
      });
  };
};

export const sendBannerKatalog = (data) => {
  return (dispatch) => {
    dispatch(isLoading(true));
    postData("app/banner-katalog", data)
      .then(async () => {
        await NotifSucces("Data Berhasil Ditambahkan");
        dispatch(fetchBannerKatalog());
        dispatch(hideModal());
        dispatch(isLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(isLoading(false));
        NotifError("Gagal Menambahkan Banner Katalog, Coba Beberapa Saat Lagi");
      });
  };
};

export const handleSubmitBannerKatalog = (data) => {
  return async (dispatch, getState) => {
    dispatch(isLoading(true));
    const state = getState();
    const current = state.stateBannerKatalog.ShowModal;
    let gambar_name = makeID();
    if (current) {
      if (data.gambar_path !== current.gambar_path) {
        var imgUrl = await postImageApp(
          dataURLtoFile(data.gambar_path),
          `BANNER_ICON/${data.gambar_name}`
        );
        data.gambar_path = imgUrl;
      }
      const kode_banner = data.kode_banner;
      delete data.kode_banner;
      if (data.sub_title === undefined) {
        data.sub_title = " ";
      }
      if (data.deskripsi === undefined) {
        data.deskripsi = " ";
      }
      dispatch(updateBannerKatalog(kode_banner, data));
      dispatch(isLoading(false));
    } else {
      if (data.gambar_path !== undefined) {
        var imgUrl2 = await postImageApp(
          dataURLtoFile(data.gambar_path),
          `BANNER_ICON/${gambar_name}`
        );
        data.gambar_path = imgUrl2;
      } else {
        NotifError("Gambar Wajib Dimasukan");
        dispatch(isLoading(false));
        return false;
      }
      if (data.sub_title === undefined) {
        data.sub_title = " ";
      }
      if (data.deskripsi === undefined) {
        data.deskripsi = " ";
      }
      dispatch(isLoading(true));
      data.gambar_name = gambar_name;
      dispatch(sendBannerKatalog(data));
    }
  };
};
