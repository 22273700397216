import { getDataParams, getDatatPusat } from "../../../../../config/axios";
import {
  ToastNotification,
  isLoading,
} from "../../../../../components/helpers/library";

export const SET_LAPORAN_KIRIM_UANG_PUSAT = "SET_LAPORAN_KIRIM_UANG_PUSAT";

export const getLaporanKirimUangPusat = () => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      dispatch(isLoading(true));
      const selected = state.form.LaporanKirimUangPusat?.values;
      if (!selected?.ip_cabang || false) {
        ToastNotification("error", "Mohon Pilih Toko");
        dispatch(isLoading(false));
        return false;
      }
      const response = await getDatatPusat(
        `kirim-uang?status=${selected?.status || "ALL"}`
      );
      let databarang = [];
      if (response.status === "error") {
        dispatch(isLoading(false));

        ToastNotification(
          "info",
          "Terjadi Kesalahan Saat Mengirim Data Silahkan Coba Lagi"
        );
        return false;
      } else {
        response.forEach((row) => {
          row.data.forEach((element) => {
            let row = {
              ...element,
            };
            databarang.push(row);
          });
        });
        let cekdata = response.every((list) => list.data.length === 0);
        if (cekdata) {
          ToastNotification("info", "Data Tidak Ditemukan");
          // databarang = []
          dispatch({
            type: SET_LAPORAN_KIRIM_UANG_PUSAT,
            payload: [],
          });
          dispatch(isLoading(false));
          return false;
        }
      }
      dispatch({
        type: SET_LAPORAN_KIRIM_UANG_PUSAT,
        payload: databarang,
      });
      dispatch(isLoading(false)); 
    } catch (error) {
      ToastNotification(error);
      dispatch(isLoading(false));
      return false;
    }
  };
};

export const getLaporanTerimaUangPusat = () => {
  return async (dispatch, getState) => {
    const response = await getDataParams("kirim-uang", {
      status: "DONE",
    });
    dispatch({
      type: SET_LAPORAN_KIRIM_UANG_PUSAT,
      payload: response.data,
    });
  };
};
export const kosongkan = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_LAPORAN_KIRIM_UANG_PUSAT,
      payload: [],
    });
  };
};
