import {GET_CHART, GET_CHART_PENJUALAN, GET_CHART_PENJUALAN_PANTES_PUSAT} from "../actions/dashboard_action";


const initialState = {
    dataChart: [
        {
            key: "num",
            values: [{ x: "A0", y: 5 },{ x: "A1", y: 5 },{ x: "A2", y: 5 },{ x: "A3", y: 5 }]
        }
    ],
    dataChartPenjualan: [],
    total_penjualan: 0,
    total_pembelian: 0,
    total_service: 0,
    total_hutang: 0
}

const dashboard = (state = initialState, actions) => {
    switch (actions.type) {
        case GET_CHART_PENJUALAN:
            return {
                ...state,
                dataChartPenjualan: actions.payload
            }

        case GET_CHART:
            return {
                ...state,
                dataChart : actions.payload.data,
                total_penjualan: actions.payload.total_penjualan,
                total_pembelian: actions.payload.total_pembelian,
                total_service: actions.payload.total_service,
                total_hutang: actions.payload.total_hutang,
                datagram_penjualan: actions.payload.datagram_penjualan,
                datagram_pembelian: actions.payload.datagram_pembelian,
            }
            case GET_CHART_PENJUALAN_PANTES_PUSAT:
                return{
                    ...state,
                    dataChartPenjualanPantesPusat:actions.payload
                }
        default:
            return state;
    }
}
export default dashboard;