import React, { useEffect } from "react";
import { Field } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import { ReanderSelect } from "./field";
import { getDataJenis } from "../../actions/datamaster_action";

let KelompokHarga = ({col,name,label,onChange}) => {
  const dispatch = useDispatch();
  const dataJenis = useSelector((state) => state.datamaster.getDataJenis);
  useEffect(() => {
    dispatch(getDataJenis());
  }, [dispatch]);

  let databaru = [{
    value : "ALL",
    name : "SEMUA"
  }]
  dataJenis.forEach(list => {
    const first = list.kode_dept.charAt(0);
    const last = list.kode_dept.charAt(list.kode_dept.length - 1);
    let row = {
      value: first + last,
      name: first + last,
    };
    databaru.push(row)
  })
  return (
    <div className={`col-lg-${col}`}>
      <Field
        name={name || "kelompok_harga"}
        component={ReanderSelect}
        options={databaru.filter((list,index,self)=> self.findIndex((list2)=> list2.value === list.value) === index)}
        label={label || "Kelompok Harga"}
        placeholder={`Silahkan ${label ||'Kelompok Harga' } `}
        onChange={onChange}
      />
    </div>
  );
};

export default KelompokHarga;
