import {
    GET_HARGA_KATALOG,
    SET_DATA_HARGA_KATALOG,
  } from "./hargaKatalogAction";
  
  const initialState = {
   hargaKatalog: [],
    ShowModal: null,
  };
  
  const stateHargaKatalog = (state = initialState, actions) => {
    switch (actions.type) {
      case GET_HARGA_KATALOG:
        return {
          ...state,
         hargaKatalog: actions.payload.data,
        };
      case SET_DATA_HARGA_KATALOG:
        return {
          ...state,
          ShowModal: actions.payload,
        };
      default:
        return state;
    }
  };
  export default stateHargaKatalog;
  