import {SET_TERIMA_UANG_PUSAT} from "./terima_uang_pusat_action";

const initialState = {
listTerimaUangPusat: []
}

const terimaUangPusat = (state = initialState, action) => {
    switch (action.type) {
        case SET_TERIMA_UANG_PUSAT:
            return {
                ...state,
                listTerimaUangPusat : action.payload
            }
        default:
            return state
    }
}

export default terimaUangPusat