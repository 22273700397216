import React, { Component } from "react";
import {
  FingerPrintComponent,
  HiidenFiled,
  ReanderField,
  Field,
  reduxForm,
  connect,
  findModule,
  io,
  server,
  TextFile,
  getDataParams,
  postData,
  ToastNotification,
  isLoading,
  getItem,
  ReanderSelect,
  ReanderCheckBox,
  lang,
} from "./library";
import { getDataUser } from "../../actions";

const moduleOtorisasiFingerPrint = findModule("FINGERPRINT_MODULE");

class FormOtorisasiFingerDanManual extends Component {
  constructor(props) {
    super(props);
    this.state = {
      switchOtorisasi: true,
      modeOtorisasi: false,
      statusFingerPrint: false,
      feedBackFingerPirnt: false,
    };
  }
  componentDidMount() {
    this.props.dispatch(getDataUser());
  }

  switchOtorisasi = async (e) => {
    this.props.dispatch(isLoading(true));
    try {
      let result = await getDataParams("user-fingerprint", {
        user_id: this.state.username,
      });
      this.setState({
        modeOtorisasi: true,
      });
      this.setState({
        fp: result.data[0].fingerprints[0].bytes,
      });
      document.getElementById("nota_ganerate").value =
        this.state.username +
        "~" +
        getItem("userdata").user_id +
        "~" +
        result.data[0].fingerprints[0].bytes;
      TextFile("oto_fingerprint");
      this.props.dispatch(isLoading(false));

      this.openSocket();
    } catch (error) {
      this.props.dispatch(isLoading(false));
      ToastNotification("info", "Username Tidak Tidak Terdaftar");
    }
  };

  async openSocket() {
    this.socket = io.connect(server.replace("/api/v1/", ""), {
      extraHeaders: { "ngrok-skip-browser-warning": 1 },
    });
    this.setState({
      statusFingerPrint: true,
      feedBackFingerPirnt: false,
    });
    this.socket.on("fingerprint-auth", async (data) => {
      // console.log(data);
      if (data.status !== false) {
        if (
          data.auth_for === getItem("userdata").user_id &&
          data.user_id === this.state.username
        ) {
          this.setState({
            statusFingerPrint: false,
            feedBackFingerPirnt: true,
          });

          // setTimeout(async () => {
          try {
            let hasil = {
              user_id: this.state.username,
              fp: this.state.fp,
              kategori: this.props.featurName,
            };
            await postData("authorization/fingerprint", hasil);
            this.setState({
              username: "",
            });
            this.props.onSubmitFingerPrint();
          } catch (error) {
            console.log(error);
            ToastNotification(
              "info",
              error?.response?.data || "Terjadi Kesalahan Saat Mengirim Data"
            );
          }
        }
        // }, 300);
      } else {
        ToastNotification("info", "Silahkan Coba Lagi");
      }
    });
  }
  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit}
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
        autoComplete="off"
      >
        <div className="row">
          {this.state.modeOtorisasi ? (
            <div className="col-lg-12 mt-4">
              <FingerPrintComponent
                statusFingerPrint={true}
                feedBackSocketFingerPirnt={false}
              />
            </div>
          ) : (
            <>
              {this.state.modeOtorisasi && <div className="col-12 mt-5"></div>}
              <Field
                name="no_faktur_group"
                component={HiidenFiled}
                type="hidden"
              />
              <Field name="alasan" component={HiidenFiled} type="hidden" />

              {this.state.switchOtorisasi ? (
                <>
                  <div className="col-lg-6">
                    <Field
                      id="username"
                      name="username"
                      component={ReanderField}
                      type="text"
                      nouperCase
                      onChange={(e) =>
                        this.setState({ username: e.target.value })
                      }
                      label={lang("Username")}
                      placeholder={`${lang("Masukkan")} ${lang("Username")}`}
                    />
                  </div>
                  <div className="col-lg-6">
                    <Field
                      name="password"
                      component={ReanderField}
                      type="text"
                      customeCss={"password-hide"}
                      nouperCase
                      onChange={(e) =>
                        this.setState({ password: e.target.value })
                      }
                      label={`${lang("Password")}`}
                      placeholder={`${lang("Masukkan")} ${lang("Password")}`}
                    />
                  </div>
                  {this.props.keterangan && (
                    <div className={"col-lg-12"}>
                      <Field
                        name="keterangan"
                        component={ReanderField}
                        label={`${lang("Keterangan")}`}
                        onChange={(e) =>
                          this.setState({ keterangan: e.target.value })
                        }
                        placeholder={`${lang("Masukkan")} ${lang(
                          "Keterangan"
                        )}`}
                      />
                    </div>
                  )}
                </>
              ) : (
                moduleOtorisasiFingerPrint && (
                  <div className="col-lg-12">
                    <Field
                      id="username"
                      name="username"
                      component={ReanderSelect}
                      options={this.props.DataUsers.filter(
                        (list) => list.level === "SPV" || list.level === "OWN"
                      ).map((list2) => {
                        return {
                          value: list2.user_id,
                          name: list2.user_id,
                        };
                      })}
                      onChange={(e) => this.setState({ username: e })}
                      label={lang("Username")}
                      placeholder={`${lang("Masukkan")} ${lang("Username")}`}
                    />
                  </div>
                )
              )}

              {moduleOtorisasiFingerPrint && (
                <div className="col-lg-12 text-center mt-2">
                  <Field
                    name="switch_otorisasi"
                    label={`Switch Otorisasi ${
                      this.state.switchOtorisasi ? "Fingerprint " : "Manual"
                    }`}
                    id="switcher_checkbox_1"
                    onClick={(e) => {
                      this.setState({
                        switchOtorisasi: !this.state.switchOtorisasi,
                      });
                    }}
                    enabledEnter
                    component={ReanderCheckBox}
                    type="checkbox"
                  />{" "}
                </div>
              )}

              <div className="col-lg-12">
                <label> &nbsp; </label>
                {this.state.switchOtorisasi ? (
                  <button
                    type={"submit"}
                    name="type_btn"
                    // disabled={this.props.loadingRedux}
                    className="btn btn-primary btn-block"
                    disabled={
                      this.props.isLoadingOtorisasi || this.props.loadingRedux
                    }
                  >
                    {this.props.isLoadingOtorisasi ? (
                      <>
                        <i className="fas fa-spinner fa-spin"></i> &nbsp;
                        {lang("Otorisasi")}
                      </>
                    ) : (
                      lang("Otorisasi")
                    )}
                  </button>
                ) : (
                  moduleOtorisasiFingerPrint && (
                    <button
                      type="button"
                      disabled={this.props.loadingRedux}
                      name="type_btn"
                      onClick={(e) => this.switchOtorisasi()}
                      className="btn btn-primary btn-block"
                    >
                      {lang("Otorisasi")}
                    </button>
                  )
                )}
              </div>
            </>
          )}
        </div>
      </form>
    );
  }
}
const maptostate = (state) => {
  return {
    loadingRedux: state.datamaster.isLoading,
    DataUsers: state.manageusers.getDataUser,
  };
};

FormOtorisasiFingerDanManual = reduxForm({
  form: "FormOtorisasiFingerDanManual",
  enableReinitialize: true,
  //   validate: ValidateOtorisasi,
})(FormOtorisasiFingerDanManual);
export default connect(maptostate)(FormOtorisasiFingerDanManual);
