import React from "react";
import { Field } from "redux-form";
import { ReanderSelect } from "./field";

const ShortBy = ({ name, col, label, onChange, filter }) => {
  return (
    <div className={`col-lg-${col}`}>
      <Field
        name={name || "sort_by"}
        component={ReanderSelect}
        options={[
          {
            value: "kode_barcode",
            name: "Barcode",
          },
          {
            value: "nama_barang",
            name: "Nama Barang",
          },
          {
            value: "kode_toko",
            name: "Kode Baki",
          },
        ].filter((list) => (filter ? list.value === filter : true))}
        label={label || "Sort By"}
        placeholder={`Silahkan Pilih ${label || "Sort By"} `}
        onChange={onChange}
      />
    </div>
  );
};

export default ShortBy;
