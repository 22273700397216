import { lang } from "../components";

const ValidasiMasterSales = (value, event) => {
  const errors = {};
  // console.log(event.charCode==13);
  if (!value.kode_sales) {
    errors.kode_sales = `${lang("Kode Sales")} ${lang("Tidak Boleh Kosong")}`;
  }
  if (!value.nama_sales) {
    errors.nama_sales = `${lang("Nama Sales")} ${lang("Tidak Boleh Kosong")}`;
  }
  if (!value.kode_bank) {
    errors.kode_bank = `${lang("Kode Bank")} ${lang("Tidak Boleh Kosong")}`;
  }
  if (!value.nama_bank) {
    errors.nama_bank = `${lang("Nama Bank")} ${lang("Tidak Boleh Kosong")}`;
  }
  if (!value.no_rekening) {
    errors.no_rekening = `${lang("No Rekening")} ${lang("Tidak Boleh Kosong")}`;
  }
  if (!value.nama_rekening) {
    errors.nama_rekening = `${lang("Nama Rekening")} ${lang(
      "Tidak Boleh Kosong"
    )}`;
  }
  // if (!value.kadar) {
  //   errors.nama_rekening = `${lang("Nama Rekening")} ${lang(
  //     "Tidak Boleh Kosong"
  //   )}`;
  // }
  return errors;
};

export default ValidasiMasterSales;
