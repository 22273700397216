import { GET_PARAMETER_KUPON_ID } from "../actions/kupon_action";

const initialState = {
  getParameterKuponID: undefined,
};

const kupon = (state = initialState, actions) => {
  switch (actions.type) {
    case GET_PARAMETER_KUPON_ID:
      return {
        ...state,
        getParameterKuponID: actions.payload.data,
      };
    default:
      return state;
  }
};

export default kupon;
