import {
  getDataParamHrgBeli,
  hideModal,
  ShowDataModal,
  showModal,
} from "../../../actions/datamaster_action";
import {
  React,
  connect,
  reduxForm,
  Tabel,
  ModalGlobal,
  ToastNotification,
  reset,
  postData,
  putData,
  deleteData,
  Swal,
  lang,
  PanelContent,
} from "../../../components/helpers/library";

import HeadParameterHargaBeli from "./HeadParameterHargaBeli";

const maptostate = (state) => {
  return {
    data: state.datamaster.getDataParamHrgBeli,
  };
};
class ParameterHargaBeli extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      settings: "",
      columns: [
        {
          dataField: "kode_harga",
          text: "Kode Harga",
          // sort: true,
        },
        {
          dataField: "harga_per_gram",
          text: "Harga/Gram",
          // sort: true,
          headerClasses: "text-right",
          formatter: (cell) => {
            return (
              <div className="text-right">
                {" "}
                {cell === undefined || cell === null
                  ? 0
                  : cell.toLocaleString("kr-KO")}{" "}
              </div>
            );
          },
        },

        

        {
          dataField: "action",
          text: "Action",
          csvExport: false,
          headerClasses: "text-center",
          formatter: (rowcontent, row) => {
            let dataEdit = {
              id: row._id,
              kode_harga: row.kode_harga,
              hrg_per_gram: row.harga_per_gram,
            };

            return (
              <div className="row text-center">
                <div className="col-12">
                  <button
                    onClick={() => this.modalEdit(dataEdit)}
                    className="btn btn-primary mr-3"
                  >
                    <i className="fa fa-edit"></i>
                  </button>
                  <button
                    onClick={() => this.hapusData(row)}
                    className="btn btn-danger"
                  >
                    <i className="fa fa-trash"></i>
                  </button>
                </div>
              </div>
            );
          },
        },
      ],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.print = this.print.bind(this);
  }

  handleSubmit(data) {
    this.setState({
      isLoading: true,
    });

    let data_post = {
      kode_harga: data.kode_harga,
      harga_per_gram: parseInt(data.hrg_per_gram || 0),
    };

    let dataEdit = {
      harga_per_gram: parseInt(data.hrg_per_gram || 0),
    };
    this.state.isEdit
      ? putData("para-harga-beli/update/" + data.kode_harga, dataEdit, true)
          .then((res) => {
            console.log(res, "response_update");
            ToastNotification(
              "success",
              res?.data?.message || "Berhasil Mengedit Data"
            );
            this.setState({
              isLoading: false,
            });
            this.props.dispatch(hideModal());
            this.props.dispatch(getDataParamHrgBeli());
          })
          .catch((err) => {
            this.setState({
              isLoading: false,
            });
            ToastNotification(
              "info",
              err.response?.data?.message ||
                "Terjadi Kesalhaan Saat Mengirim Data"
            );
          })
      : postData("para-harga-beli/create", data_post, true)
          .then((res) => {
            ToastNotification(
              "success",
              res?.data?.message || "Berhasil Menyimpan Data"
            );
            this.setState({
              isLoading: false,
            });
            this.props.dispatch(hideModal());
            this.props.dispatch(getDataParamHrgBeli());
            this.props.dispatch(reset());
          })
          .catch((err) => {
            this.setState({
              isLoading: false,
            });
            ToastNotification(
              "info",
              err.response?.data || "Terjadi Kesalhaan Saat Mengirim Data"
            );
          });
  }

  componentDidMount() {
    this.props.dispatch(getDataParamHrgBeli());
  }
  hapusData(row) {
    Swal.fire({
      html: `${lang("Apakah Anda Yakin Ingin Menghapus")} <h1>
        "Parameter Harga Beli"
       ${row.kode_harga}</h1>`,
      icon: "warning",
      position: "top-center",
      cancelButtonText: `${lang("Tidak")}`,
      showCancelButton: true,
      confirmButtonText: lang("Ya"),
      showConfirmButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteData("para-harga-beli/delete/" + row.kode_harga)
          .then((res) => {
            ToastNotification(
              "success",
              res.data.message || `${lang("Berhasil Menghapus Data")}`
            );
            this.setState({
              isLoading: false,
            });
            this.props.dispatch(getDataParamHrgBeli());
          })
          .catch((err) => {
            ToastNotification(
              "info",
              err.response?.data?.message || "Gagal Menghapus Data"
            );
            this.setState({
              isLoading: false,
            });
          });
      }
    });
  }
  modalEdit(row) {
    this.props.dispatch(showModal());
    this.props.dispatch(ShowDataModal(row));
    this.setState({
      isEdit: true,
    });
  }
  showModal() {
    this.props.dispatch(showModal());
    this.props.dispatch(ShowDataModal(false));
    this.setState({
      isEdit: false,
    });
  }
  render() {
    return (
      <PanelContent
        menu={lang("Parameter Harga Beli")}
        submenu={lang("Data Master")}
      >
        <Tabel
          tambahData={true}
          handleClick={() => this.showModal()}
          featur={true}
          keyField="_id"
          data={this.props.data}
          columns={this.state.columns}
          empty={[]}
          textEmpty="Parameter Harga Beli Kosong"
        />
        <ModalGlobal
          size="P"
          title={this.state.isEdit ? "Edit Data" : "Tambah Data"}
          content={
            <HeadParameterHargaBeli
              isLoading={this.state.isLoading}
              onSubmit={(data) => this.handleSubmit(data)}
              isEdit={this.state.isEdit}
            />
          }
        />
      </PanelContent>
    );
  }
}

ParameterHargaBeli = reduxForm({
  form: "ParameterHargaBeli",
  enableReinitialize: true,
})(ParameterHargaBeli);
export default connect(maptostate)(ParameterHargaBeli);
