import {
  SHOW_MENU_PEMBAYARAN_CICILAN,
  SET_DETAIL_TRANSAKSI_CICILAN,
  SHOW_MENU_BATAL_PENJUALAN_CICILAN,
  SHOW_MENU_PENJUALAN_LELANG_CICILAN,
  SHOW_MENU_PENGAMBILAN_BARANG,
  SET_DATA_LIST_CICILAN,
  SET_DATA_BAKI_BALIK_CICILAN,
  SET_NO_FAKTUR_CICILAN,
  SET_DATA_PEMBAYARAN_CICILAN,
  SET_DATA_LIST_CICILAN_LELANG,
  SET_NO_FAKTUR_CICILAN_LELANG,
  SET_DETAIL_TRANSAKSI_CICILAN_LELANG,
  SET_DATA_PEMBAYARAN_DP_CICILAN,
} from "../actions/cicilan_action";

const initialState = {
  ShowMenuPembayaranCicilan: false,
  ShowMenuPembayaranLelangCicilan: false,
  ShowMenuBatalCicilan: false,
  ShowMenuPengambilanBarang: false,
  setDetailTransaksiCicilan: undefined,
  setDetailTransaksiCicilanLelang: undefined,
  setDataListCicilan: [],
  setDataListCicilanLelang: [],
  baki: undefined,
  noFakturCicil: undefined,
  noFakturCicilLelang: undefined,
  dataPembayaran: [],
  dataDP: [],
};

const cicilan = (state = initialState, actions) => {
  switch (actions.type) {
    case SHOW_MENU_PEMBAYARAN_CICILAN:
      return {
        ...state,
        ShowMenuPembayaranCicilan: actions.payload.data,
      };
    case SHOW_MENU_PENJUALAN_LELANG_CICILAN:
      return {
        ...state,
        ShowMenuPembayaranLelangCicilan: actions.payload.data,
      };
    case SHOW_MENU_BATAL_PENJUALAN_CICILAN:
      return {
        ...state,
        ShowMenuBatalCicilan: actions.payload.data,
      };
    case SHOW_MENU_PENGAMBILAN_BARANG:
      return {
        ...state,
        ShowMenuPengambilanBarang: actions.payload.data,
      };
    case SET_DETAIL_TRANSAKSI_CICILAN:
      return {
        ...state,
        setDetailTransaksiCicilan: actions.payload.data,
      };
    case SET_DETAIL_TRANSAKSI_CICILAN_LELANG:
      return {
        ...state,
        setDetailTransaksiCicilanLelang: actions.payload.data,
      };
    case SET_DATA_LIST_CICILAN:
      return {
        ...state,
        setDataListCicilan: actions.payload.data,
      };
    case SET_DATA_LIST_CICILAN_LELANG:
      return {
        ...state,
        setDataListCicilanLelang: actions.payload.data,
      };
    case SET_DATA_BAKI_BALIK_CICILAN:
      return {
        ...state,
        baki: actions.payload.data,
      };
    case SET_NO_FAKTUR_CICILAN:
      return {
        ...state,
        noFakturCicil: actions.payload.data,
      };
    case SET_NO_FAKTUR_CICILAN_LELANG:
      return {
        ...state,
        noFakturCicilLelang: actions.payload.data,
      };
    case SET_DATA_PEMBAYARAN_CICILAN:
      return {
        ...state,
        dataPembayaran: actions.payload.data,
      };
    case SET_DATA_PEMBAYARAN_DP_CICILAN:
      return {
        ...state,
        dataDP: actions.payload.data,
      };
    default:
      return state;
  }
};

export default cicilan;
