import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const ModalLocal = ({
  id,
  isOpen,
  disabledheader,
  children,
  hideModal,
  size,
  title,
}) => {
  return (
    <Modal
      backdrop="static"
      keyboard={false}
      id={id}
      isOpen={isOpen}
      toggle={() => hideModal()}
      size={size || "xl"}
    >
      {disabledheader === false ? (
        <ModalHeader toggle={() => hideModal()}>{title}</ModalHeader>
      ) : null}
      <ModalBody>{children}</ModalBody>
    </Modal>
  );
};

export default ModalLocal;
