import { lazy } from "react";

const BatalCicilan = lazy(() => import("./BatalCicilan"));
const LihatCicilan = lazy(() => import("./LihatCicilan"));
const ParameterCicilan = lazy(() => import("./ParameterCicilan"));
const TransaksiCicilan = lazy(() => import("./TransaksiCicilan"));
const LelangCicilan = lazy(() => import("./LelangCicilan"));

export {
  BatalCicilan,
  TransaksiCicilan,
  ParameterCicilan,
  LihatCicilan,
  LelangCicilan,
};
