import {
  formatGram,
  isPos,
  React,
  Component,
  Tabel,
  ReanderSelect,
  Field,
  reduxForm,
  connect,
  Loading,
  CabangSelector,
} from "../../../../components/helpers/library";
import LaporanAnalisisHargaBarangExel from "./excel/CetakLapAnalisisHargaBarangExcel";
import CetakLaporanAnalisisHargaBarang from "./pdf/CetakLaporanAnalisisHargaBarang";

class FilterLaporanAnalisisHargaBarang extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      isChecked: true,
      type_laporan: "LANDSCAPE",
    };
    this.setStartDate = this.setStartDate.bind(this);
    this.setLastDate = this.setLastDate.bind(this);
  }

  exportPdf() {
    CetakLaporanAnalisisHargaBarang(this.props.LaporanDataBarang.databarang);
  }
  setStartDate(date) {
    this.setState({
      tgl_awal: new Date(date),
    });
  }
  setLastDate(date) {
    this.setState({
      tgl_akhir: new Date(date),
    });
  }

  componentDidMount() {
    this.props.change("kode_dept", "ALL");
    this.props.change("kode_gudang", "ALL");
    this.props.change("jenis_group", "ALL");
    this.props.change("type_laporan", "MARGA_MAX");
  }

  componentWillUnmount() {}

  render() {
    let dataJenis = [
      {
        value: "ALL",
        name: "SEMUA",
      },
    ];
    this.props.dataJenis.forEach((list) => {
      let data = {
        value: list.kode_dept,
        name: list.kode_dept + " - " + list.nama_dept.slice(0, 8),
      };
      dataJenis.push(data);
    });
    let datagroup = [
      {
        value: "ALL",
        name: "SEMUA",
      },
    ];
    this.props.GetDataGroup.forEach((list) => {
      let data = {
        value: list.kode_group,
        name: list.kode_group,
      };
      datagroup.push(data);
    });

    const columns = [
      {
        dataField: "kode_barcode",
        text: "Kode",
      },
      {
        dataField: "kode_group",
        text: "Group",
      },
      {
        dataField: "kode_dept",
        text: "Jenis",
      },
      {
        dataField: "kode_intern",
        text: "Intern",
      },
      {
        dataField: "nama_barang",
        text: "Nama Barang",
      },

      {
        dataField: "berat",
        text: "Berat",
        headerClasses: "text-right",
        // footer:"",
        // footer: (cell, row, num, index) => {
        //   return (
        //     <div className="text-right">
        //       {cell.reduce((a, b) => a + parseFloat(b || 0), 0)?.toFixed(3)}{" "}
        //     </div>
        //   );
        // },
        formatter: (cell) => {
          return <div className="text-right">{formatGram(cell, 3)}</div>;
        },
      },

      {
        dataField: "harga_jual",
        text: "Harga Jual",
        headerClasses: "text-right",
        // footer: (cell, row, num, index) => {
        //   return (
        //     <div className="text-right">
        //       {cell
        //         .reduce((a, b) => a + parseFloat(b || 0), 0)
        //         ?.toLocaleString("kr-ko")}{" "}
        //     </div>
        //   );
        // },
        // footer:"",
        formatter: (cell) => {
          return (
            <div className="text-right">{cell?.toLocaleString("kr-KO")}</div>
          );
        },
      },
    ];

    let jenisGroup = [
      {
        value: "ALL",
        name: "SEMUA",
      },
    ];
    this.props.GetDataJenisGroup.forEach((list) => {
      jenisGroup.push({
        value: list.jenis_group,
        name: list.jenis_group,
      });
    });

    return (
      <form onSubmit={this.props.handleSubmit} autoComplete="off">
        <div className="row">
          {this.props.pusat && (
            <div className="col-lg-3">
              <CabangSelector />
            </div>
          )}

          <div className="col-3">
            <Field
              label="Jenis"
              name="kode_dept"
              options={dataJenis}
              placeholder={"PILIH Kode Jenis"}
              component={ReanderSelect}
            />
          </div>

          <div className="col-lg-3">
            <Field
              name="type_laporan"
              component={ReanderSelect}
              onChange={(e) =>
                this.setState({
                  type_laporan: e,
                })
              }
              options={[
                {
                  value: "HARGA_MAX",
                  name: "Harga Tertinggi",
                },
                {
                  value: "HARGA_MIN",
                  name: "Harga Terendah",
                },
              ]}
              type="text"
              label="Sort By"
              placeholder="Pilih Type Laporan"
            />
          </div>

          <div className="col-3 mb-4">
            <label> &nbsp; </label>
            <button
              className="btn btn-primary btn-block"
              //   disabled={this.submitting}
              type="submit"
            >
              {this.props.isLoading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                  Memfilter
                </>
              ) : (
                "Cari Laporan"
              )}
            </button>
          </div>

          <div className="col-12 mt-3">
            <Tabel
              keyField="baki"
              data={
                this.props.export ? this.props.LaporanDataBarang.databarang : []
              }
              //   data={this.props.export ? databarang : []}
              columns={columns}
              empty={true}
              textEmpty="Data Laporan Analisis Harga Barang Kosong"
            />
          </div>
          <div className={this.props.export ? "col-lg-12" : "col-lg-12 d-none"}>
            {/* <label htmlFor="">&nbsp;</label> */}
            <div className="row">
              <div className={isPos() ? "col-lg-12" : "col-lg-6"}>
                <button
                  type="button"
                  onClick={() => this.exportPdf()}
                  className="btn btn-warning btn-block"
                >
                  {" "}
                  Export Pdf{" "}
                </button>
              </div>
              <div className={"col-lg-6"}>
                <LaporanAnalisisHargaBarangExel
                  data={this.props.LaporanDataBarang.databarang}                  
                />
              </div>
            </div>
          </div>
        </div>
        {this.props.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </form>
    );
  }
}

FilterLaporanAnalisisHargaBarang = reduxForm({
  form: "FilterLaporanAnalisisHargaBarang",
  enableReinitialize: true,
  //   validate: ValidasiTanggalLaporan,
})(FilterLaporanAnalisisHargaBarang);
export default connect((state) => {
  return {
    dataJenis: state.datamaster.getDataJenis,
    GetDataGroup: state.datamaster.GetDataGroup,
    GetDataJenisGroup: state.datamaster.GetJenisGroup,
  };
})(FilterLaporanAnalisisHargaBarang);
