import React, { Component } from "react";
import { connect } from "react-redux";
import { change, Field, reduxForm } from "redux-form";
import Loading from "react-fullscreen-loading";
import { hideModal } from "../../../../actions/datamaster_action";
import { ReanderField, ReanderSelect, renderTextArea } from "../../../../components/helpers/field";
import { resizeFileSplashscreen } from "../../../../components/helpers/function";

const maptostate = (state) => {
  if (state.stateSplashscreenKatalog.ShowModal !== null) {
    return {
      gambar_path: state.stateSplashscreenKatalog.ShowModal.url,
      isEdit: true,
      isLoading: state.utility.isLoading,
      initialValues: {
        gambar_path: state.stateSplashscreenKatalog.ShowModal.url,
        kode: state.stateSplashscreenKatalog.ShowModal.kode,
        text: state.stateSplashscreenKatalog.ShowModal.text,
        position: state.stateSplashscreenKatalog.ShowModal.position,
      },
    };
  } else {
    return {
      isLoading: state.utility.isLoading,
    }
  }
  
};
class FormSplashscreenKatalog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aktif: false,
      listgroup: [],
      parameter_bandrol: "2301",
    };
  }

  setFocus() {
    setTimeout(() => {
      document.getElementById("kode_kategori").focus();
    }, 100);
  }

  getValue = async (event) => {
    const file = event.target.files[0];
    document.getElementById("gambar_path").value = file.name;
    const base64 = await resizeFileSplashscreen(file);
    document.getElementById("preview").src = base64;
    this.props.dispatch(change("FormSplashscreenKatalog", "gambar_path", base64));
    console.log(base64);
  };

  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit}
        autoComplete="off"
      >
         <Field
          tabIndex="1"
          id="text"
          name="text"
          component={renderTextArea}
          type="text"
          label="Text"
          placeholder="Masukan Text"
        />
        <Field
          tabIndex="2"
          id="position"
          name="position"
          component={ReanderSelect}
          label="Posisi"
          placeholder="Masukan Posisi"
          options={[
            {
              value : "top",
              name : "ATAS"
            },
            {
              value : "bottom",
              name : "BAWAH"
            }
          ]}
        />
        
        <div className="form-group">
          <input
            type="file"
            id="gambar"
            name="gambar"
            onChange={(e) => this.getValue(e)}
          />
        </div>
        <div className="form-group d-none">
        <Field
          tabIndex="0"
          id="kode"
          name="kode"
          component={ReanderField}
          type="text"
          label="Kode"
        />
        </div>

        <Field
          tabIndex="3"
          id="gambar_path"
          name="gambar_path"
          component={ReanderField}
          type="text"
          label="Gambar Icon"
          readOnly={true}
          placeholder="Masukan Gambar Icon"
        />
        <div className="d-none">
          <Field
            id="gambar_name"
            name="gambar_name"
            component={ReanderField}
            type="text"
            label="Gambar Banner"
            readOnly={true}
            placeholder="Masukan Gambar Banner"
          />
        </div>
        <div className="col-lg-12">
          <img
            alt="previewIcon"
            id="preview"
            className="img-thumbnail"
            src={this.props.gambar_path}
          />
        </div>
        <div className="row">
          <div className="col-12 text-right">
            <button
              className="btn btn-white"
              onClick={() => this.props.dispatch(hideModal())}
              type="button"
              disabled={this.props.isLoading}
            >
              Batal
            </button>
            &nbsp;
            <button
              tabIndex="5"
              className={this.state.aktif ? "btn btn-primary" : "btn btn-white"}
              disabled={this.props.submitting}
              type="submit"
            >
              {this.props.isLoading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                  Menyimpan
                </>
              ) : (
                "Simpan Data"
              )}
            </button>
          </div>
        </div>
        {this.props.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </form>
    );
  }
}

FormSplashscreenKatalog = reduxForm({
  form: "FormSplashscreenKatalog",
  enableReinitialize: true,
})(FormSplashscreenKatalog);
export default connect(maptostate, null)(FormSplashscreenKatalog);
