import { GetDataGroup } from "../../../actions/datamaster_action";
import {
  ModalGlobal,
  Panel,
  PanelBody,
  PanelHeader,
  Tabel,
} from "../../../components/helpers/library";
import React from "react";
import { Loading } from "react-fullscreen-loading";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  fetchGroupDescriptionKatalog,
  handleSubmitGroupDescriptionKatalog,
  setDeleteGroupDescriptionKatalog,
  setEditGroupDescriptionKatalog,
  setTambahGroupDescriptionKatalog,
} from "./redux/groupDescriptionKatalogAction";
import FormGroupDescriptionKatalog from "./widgets/FormGroupDescriptionKatalog";

class GroupDescriptionKatalog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          dataField: "kode_group",
          text: "Kode Group ",
          sort: true,
        },
        {
          dataField: "description",
          text: "Deskripsi",
        },
        {
          dataField: "action",
          text: "Action",
          csvExport: false,
          headerClasses: "text-center",
          formatter: (rowcontent, row) => {
            return (
              <div className="row text-center">
                <div className="col-12">
                  <button
                    className="btn btn-primary mr-3"
                    onClick={() => {
                      this.props.dispatch(setEditGroupDescriptionKatalog(row));
                    }}
                  >
                    <i className="fa fa-edit"></i>
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={() => {
                      this.props.dispatch(
                        setDeleteGroupDescriptionKatalog(row)
                      );
                    }}
                  >
                    <i className="fa fa-trash"></i>
                  </button>
                </div>
              </div>
            );
          },
        },
      ],
    };
  }

  componentDidMount() {
    this.props.dispatch(fetchGroupDescriptionKatalog());
    this.props.dispatch(GetDataGroup());
  }

  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Deskripsi Group Katalog</Link>
          </li>
          <li className="breadcrumb-item active">Deskripsi Group Katalog</li>
        </ol>
        <h1 className="page-header">Deskripsi Group Katalog </h1>
        <Panel>
          {this.state.isLoading ? (
            <Loading
              loading
              background="rgba(0, 0, 0, 0.35)"
              loaderColor="rgba(94, 147, 117, 1)"
            />
          ) : null}
          <PanelHeader>Deskripsi Group Katalog</PanelHeader>
          <br />
          <PanelBody>
            <Tabel
              keyField="kode_group"
              tambahData={true}
              handleClick={() =>
                this.props.dispatch(setTambahGroupDescriptionKatalog())
              }
              exportCsv={true}
              data={this.props.GroupDescriptionKatalog}
              columns={this.state.columns}
              empty={this.props.GroupDescriptionKatalog}
              textEmpty="Data Harga Kosong"
              fileNameCsv={`kategori-katalog`}
            />
          </PanelBody>
          <br />
        </Panel>
        <ModalGlobal
          size="P"
          title={
            this.state.isEdit
              ? "Edit Group Description Katalog"
              : "Tambah Group Description Katalog"
          }
          content={
            <FormGroupDescriptionKatalog
              isLoading={this.state.isLoading}
              isEdit={this.state.isEdit}
              onSubmit={(data) =>
                this.props.dispatch(handleSubmitGroupDescriptionKatalog(data))
              }
            />
          }
        />
      </div>
    );
  }
}

export default connect((state) => {
  return {
    GroupDescriptionKatalog:
      state.stateGroupDescriptionKatalog.groupDescription,
  };
})(GroupDescriptionKatalog);
