const loading = (state) => state.datamaster.isLoading;
const datatmp = (state) => state.datamaster.getDataTmp;
const datagudang = (state) => state.datamaster.getDataGudang;
const datajenis = (state) => state.datamaster.getDataJenis;
const databaki = (state) => state.datamaster.getDataBaki;
const datasales = (state) => state.datamaster.getDataSales;
const databank = (state) => state.datamaster.getDataBanks;
const datarekening = (state) => state.datamaster.getDataRekening;
const databarangManual = (state) => state.databarang.getDataBarangManual;
const datagroup = (state) => state.datamaster.GetDataGroup;
const kelompokharga = (state) => state.datamaster.getMasterKelompokHarga;
const jenisGroup = (state) => state.datamaster.GetJenisGroup;
const datauser = (state) => state.manageusers.getDataUser;
const kategoriharga = (state) => state.datamaster.KategoriHarga;
const parameterService = (state) => state.datamaster.getDataService;
const paraHutangPokok = (state) => state.datamaster.getParaHutangPokok;
const parameterKondisiBeli = (state) => state.datamaster.getKondisiPembelian;
const datahadiah = (state) => state.datamaster.getDataHadiah;
const datapengalihansaldo = (state) => state.datamaster.getDataPengalihanSaldo;
const getDataGesek = (state) => state.datamaster.getDataGesek;
const getDataPreOrder = (state) => state.datamaster.getDataPreOrder;
const getPoFinish = (state) => state.datamaster.getPoFinish;
const datamarketplace = (state) => state.datamaster.getDataMarketplace;
const parameterCicilan = (state) => state.datamaster.getParameterCicilan;
const parameterKupon = (state) => state.datamaster.getParamterKupon;
const getAktifTgl = (state) => state.datamaster.getAktifTgl;

const ispusat = (state) => state.utility.isPusat;

const selectorMaster = {
  loading,
  parameterCicilan,
  datamarketplace,
  getDataPreOrder,
  getPoFinish,
  getDataGesek,
  datapengalihansaldo,
  parameterService,
  parameterKondisiBeli,
  paraHutangPokok,
  kelompokharga,
  datatmp,
  ispusat,
  datagudang,
  getAktifTgl,
  datahadiah,
  jenisGroup,
  kategoriharga,
  datajenis,
  databaki,
  databarangManual,
  datasales,
  datauser,
  databank,
  datarekening,
  datagroup,
  parameterKupon,
};
export { selectorMaster };
