import { getDataUser } from "../../actions/mageuser_action";
import {
  React,
  selectorMaster,
  useDispatch,
  useSelector,
  useEffect,
  Field,
  ReanderSelect,
} from "./library";

const ValidBy = () => {
  const dispatch = useDispatch();
  let data = useSelector(selectorMaster.datauser);
  useEffect(() => {
    dispatch(getDataUser());
  }, [dispatch]);

  return (
    <Field
      name="valid_by"
      component={ReanderSelect}
      options={[
        { value: "ALL", name: "SEMUA" },
        ...data
          .filter((list) => list.level === "KASIR")
          .map((list) => ({
            value: list.user_id,
            name: list.user_id,
          })),
      ]}
      label="Valid By"
      placeholder="Pilih Valid By"
    />
  );
};

export default ValidBy;
