import {
    React,
    connect,
    Link,
    reduxForm,
    Panel,
    PanelHeader,
    getDataParams,
    convertDate,
    PanelBody,
    ToastNotification,
    setItem,
  } from "../../../../components/helpers/library";
import HeadFormLaporanBatalTitip from "./HeadFormLaporanBatalTitip";
  
  class FormLaporanBataltitip extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoading: false,
        LaporanPesanan: [],
        export: false,
      };
      this.handleSubmit = this.handleSubmit.bind(this);
    }
  
    handleSubmit(data) {
      let data_post = {
        tgl_from: convertDate(data.tgl_awal),
        tgl_to: convertDate(data.tgl_akhir),      
      };
      this.setState({
        isLoading: true,
      });
      getDataParams("titip/report-batal-titip-uang", data_post)
        .then((res) => {
          console.log(res);
        //   return false
          if (res.data.length === 0) {
            ToastNotification("info", "Laporan Batal Titip Kosong");
            this.setState({
              export: false,
              isLoading: false,
            });
  
            return false;
          }
          ToastNotification("success", "Laporan Batal Titip Tersedia");
          setItem("tgl_laporan", data_post);
          this.setState({
            LaporanPesanan: res.data,
            isLoading: false,
            export: true,
          });
        })
        .catch((err) => {
          ToastNotification("info", "Data Yang Di Cari Tidak Ada !!!");
          this.setState({
            export: false,
            isLoading: false,
          });
        });
    }
    componentDidMount() {
      localStorage.removeItem("D2A2E4A8D4DCA6");
      localStorage.removeItem("D2A2E4A8D4DCA7");
    }
    componentWillUnmount() {
      localStorage.removeItem("D2A2E4A8D4DCA6");
      localStorage.removeItem("D2A2E4A8D4DCA7");
    }
    render() {
      return (
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="#">Laporan</Link>
            </li>
            <li className="breadcrumb-item active">Laporan Batal Titip</li>
          </ol>
          <h1 className="page-header">Laporan Batal Titip </h1>
          <Panel>
            <PanelHeader>Laporan Batal Titip</PanelHeader>
            <br />
            <PanelBody>
              <HeadFormLaporanBatalTitip
                export={this.state.export}
                isLoading={this.state.isLoading}
                LaporanPesanan={this.state.LaporanPesanan}
                onSubmit={(data) => this.handleSubmit(data)}
              />
            </PanelBody>
  
            <br />
          </Panel>
        </div>
      );
    }
  }
  
  FormLaporanBataltitip = reduxForm({
    form: "FormLaporanBataltitip",
    enableReinitialize: true,
  })(FormLaporanBataltitip);
  export default connect(null)(FormLaporanBataltitip);
  