import {
    ModalGlobal,
    Panel,
    PanelBody,
    PanelHeader,
    Tabel,
  } from "../../../components/helpers/library";
  import React from "react";
  import { Loading } from "react-fullscreen-loading";
  import { connect } from "react-redux";
  import { Link } from "react-router-dom";
  import { fetchBannerKatalog } from "../BannerKatalog/redux/bannerKatalogAction";
  import { fetchInfoKatalog } from "../InfoKatalog/redux/infoKatalogAction";
  import { fetchKategoriKatalog } from "../KategoriKatalog/redux/kategoriKatalogAction";
  import {
    fetchHadiahKatalog,
    handleSubmitHadiahKatalog,
    setDeleteHadiahKatalog,
    setEditHadiahKatalog,
    setTambahHadiahKatalog,
  } from "./redux/hadiahKatalogAction";
  import FormHadiahKatalog from "./widgets/FormHadiahKatalog";
  
  class HadiahKatalog extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        columns: [
          {
            dataField: "kode_hadiah",
            text: "Kode Hadiah ",
            sort: true,
          },
          {
            dataField: "nama_hadiah",
            text: "Nama Hadiah",
          },
          {
            dataField: "info",
            text: "Info",
          },
          {
            dataField: "sub_info",
            text: "Sub Info",
          },
          {
            dataField: "deskripsi",
            text: "Deskripsi",
          },
          {
            dataField: "gambar_path",
            text: "Gambar",
            formatter: (data) => {
              return <img src={`${data}`} alt="IMAGES" class="img-thumbnail" />;
            },
          },
          {
            dataField: "action",
            text: "Action",
            csvExport: false,
            headerClasses: "text-center",
            formatter: (rowcontent, row) => {
              return (
                <div className="row text-center">
                  <div className="col-12">
                    <button
                      className="btn btn-primary mr-3"
                      onClick={() => {
                        this.props.dispatch(setEditHadiahKatalog(row));
                      }}
                    >
                      <i className="fa fa-edit"></i>
                    </button>
                    <button
                      className="btn btn-danger"
                      onClick={() => {
                        this.props.dispatch(setDeleteHadiahKatalog(row));
                      }}
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                  </div>
                </div>
              );
            },
          },
        ],
      };
    }
  
    componentDidMount() {
      this.props.dispatch(fetchHadiahKatalog());
      this.props.dispatch(fetchKategoriKatalog());
      this.props.dispatch(fetchBannerKatalog());
      this.props.dispatch(fetchInfoKatalog());
    }
  
    render() {
      return (
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-hadiah">
              <Link to="#">Hadiah Katalog</Link>
            </li>
            <li className="breadcrumb-hadiah active">Hadiah Katalog</li>
          </ol>
          <h1 className="page-header">Hadiah Katalog </h1>
          <Panel>
            <PanelHeader>Hadiah Katalog</PanelHeader>
            <br />
            <PanelBody>
              <Tabel
                keyField="kode_hadiah"
                tambahData={true}
                handleClick={() => this.props.dispatch(setTambahHadiahKatalog())}
                exportCsv={true}
                data={this.props.HadiahKatalog}
                columns={this.state.columns}
                empty={this.props.HadiahKatalog}
                textEmpty="Data Hadiah Kosong"
                fileNameCsv={`kategori-katalog`}
              />
            </PanelBody>
            <br />
          </Panel>
          <ModalGlobal
            size="P"
            title={
              this.state.isEdit ? "Edit Hadiah Katalog" : "Tambah Hadiah Katalog"
            }
            content={
              <FormHadiahKatalog
                isEdit={this.state.isEdit}
                onSubmit={(data) =>
                  this.props.dispatch(handleSubmitHadiahKatalog(data))
                }
              />
            }
          />
          {this.state.isLoading ? (
            <Loading
              loading
              background="rgba(0, 0, 0, 0.35)"
              loaderColor="rgba(94, 147, 117, 1)"
            />
          ) : null}
        </div>
      );
    }
  }
  
  export default connect((state) => {
    return {
      HadiahKatalog: state.stateHadiahKatalog.hadiahKatalog,
    };
  })(HadiahKatalog);
  