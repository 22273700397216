import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  getToday,
  getUserdata,
  getItem,
  getDataNoTimeOut,
} from "../../../../../components/helpers/library";
const LaporanPembelianPerfakturPdf = async (data = "", tgl_laporan) => {
  const doc = new jsPDF("l", "mm", [297, 210]);
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];
  // let footRows = [];
  let tableColumn = [];
  let finalY = 35;
  doc.setFontSize(15);
  //   doc.text("Laporan Pembelian Per Faktur", 210, 15);
  let judulLaporan = doc.splitTextToSize("Laporan Pembelian Per Faktur", 100);
  doc.text(judulLaporan, 210, 15);
  doc.text(getUserdata().nama_toko, 14, 15);
  doc.setFontSize(8);
  let jml_alamat = getUserdata().alamat_toko.length;
  if (jml_alamat > 20) {
    doc.text(getUserdata().alamat_toko.slice(0, 48), 14, 20);
  }
  if (jml_alamat > 50) {
    doc.text(getUserdata().alamat_toko.slice(48, 90), 14, 25);
  }
  doc.setFontSize(10);
  doc.setProperties({
    title: "Laporan Pembelian Per Faktur",
  });
  doc.text(
    `Tanggal : ${tgl_laporan.tgl_from} s/d ${tgl_laporan.tgl_to}`,
    210,
    23
  );

  let no = 0;
  // doc.text(
  //   tgl_laporan.kelompok_harga === "ALL" ? "SEMUA" : tgl_laporan.kelompok_harga,
  //   210,
  //   30
  // );
  let masterkelompokHarga = await getDataNoTimeOut("kelompok-harga");

  let kelompokharga = masterkelompokHarga.data
    .filter(
      (cek) =>
        tgl_laporan.kelompok_harga === "ALL" ||
        cek.kelompok_harga === tgl_laporan.kelompok_harga
    )
    .map((list) => {
      return list.kelompok_harga;
    });

  tableColumn = [
    [
      {
        content: kelompokharga.join(","),
        colSpan: 12,
        styles: {
          textColor: "#000",
          halign: "right",
          fillColor: "#fff",
        },
      },
    ],
    [
      {
        content: `NO NOTA BELI`,
      },
      {
        content: `SALES`,
      },
      {
        content: `NAMA BARANG`,
      },
      {
        content: `KATEGORI`,
      },
      {
        content: `HRG SKRG`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `BRT ASL`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `BERAT`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `HRG NOTA`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `HRG BELI`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `RATA ASL`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `RATA2`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `(%)`,
        styles: {
          halign: "right",
        },
      },
    ],
  ];

  data.forEach((row, index) => {
    let header = [
      {
        content: `${row.kondisi || "-"}`,
        colSpan: 9,
        // styles: {
        //   lineWidth: 0.1,
        // },
      },
    ];
    tableRows.push(header);
    row.detail.forEach((item) => {
      no++;
      let rataasal = parseInt(parseInt(item.harga) / parseFloat(item.berat));
      let rows = [
        item.no_faktur_beli,
        item.kode_sales,
        item.nama_barang,
        item.kode_group,
        {
          content: item.harga_emas?.toLocaleString("kr-KO"),
          styles: {
            halign: "right",
          },
        },
        {
          content: item.berat_nota.toFixed(3),
          styles: {
            halign: "right",
          },
        },
        {
          content: item.berat.toFixed(3),
          styles: {
            halign: "right",
          },
        },
        {
          content: item.harga_nota.toLocaleString("kr-KO"),
          styles: {
            halign: "right",
          },
        },
        {
          content: item.harga.toLocaleString("kr-KO"),
          styles: {
            halign: "right",
          },
        },
        {
          content: !item.berat_nota
            ? 0
            : parseInt(
                parseInt(item.harga_nota) / parseFloat(item.berat_nota)
              ).toLocaleString("kr-KO"),
          styles: {
            halign: "right",
          },
        },
        {
          content: parseInt(
            parseFloat(item.harga) / parseFloat(item.berat)
          ).toLocaleString("kr-KO"),
          styles: {
            halign: "right",
          },
        },
        {
          // content: ((parseInt(item.harga || 0) - parseInt(item.harga_rata || 0)) / (parseInt(item.harga_emas) * 100)).toFixed(2),
          // ((parseInt(item.harga_emas) - parseInt(rataasal)) /
          content: (
            ((parseInt(item.harga_emas) - parseInt(rataasal)) /
              parseInt(item.harga_emas)) *
            100
          ).toFixed(2),
          styles: {
            halign: "right",
          },
        },
        // {
        //   content: isNaN(rataasal)
        //     ? "-"
        //     : Math.abs(
        //         100 -
        //           ((parseInt(item.harga) / parseFloat(item.berat)) * 100) /
        //             (!item.berat_nota
        //               ? 0
        //               : parseInt(
        //                   parseInt(item.harga_nota) /
        //                     parseFloat(item.berat_nota)
        //                 ))
        //       ).toFixed(2),
        //   styles: {
        //     halign: "right"
        //   }
        // }
      ];

      tableRows.push(rows);
    });
  });

  let footer = [
    {
      content: `Total Pembelian Per Faktur : ${no}`,
      colSpan: 3,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "left",
      },
    },
    {
      content: `Grand Total`,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    },
    {
      content: ``,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    },

    // {
    //   content: `${data
    //     .map((list) =>
    //       list.detail.reduce((a, b) => a + parseFloat(b.harga_emas), 0)
    //     )
    //     .reduce((a, b) => a + parseInt(b), 0)
    //     .toLocaleString("kr-ko")}`,
    //   styles: {
    //     fillColor: "#E8E5E5",
    //     textColor: "#000",
    //     halign: "right"
    //   }
    // },
    {
      content: `${data
        .map((list) =>
          list.detail.reduce((a, b) => a + parseFloat(b.berat_nota), 0)
        )
        .reduce((a, b) => a + parseFloat(b), 0)
        .toFixed(3)}`,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    },
    {
      content: `${data
        .map((list) => list.detail.reduce((a, b) => a + parseFloat(b.berat), 0))
        .reduce((a, b) => a + parseFloat(b), 0)
        .toFixed(3)}`,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    },
    {
      content: `${data
        .map((list) =>
          list.detail.reduce((a, b) => a + parseFloat(b.harga_nota), 0)
        )
        .reduce((a, b) => a + parseInt(b), 0)
        .toLocaleString("kr-ko")}`,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    },
    {
      content: `${data
        .map((list) => list.detail.reduce((a, b) => a + parseFloat(b.harga), 0))
        .reduce((a, b) => a + parseInt(b), 0)
        .toLocaleString("kr-ko")}`,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    },
    {
      content: `${parseInt(
        data
          .map((list) =>
            list.detail.reduce((a, b) => a + parseFloat(b.rata_asal), 0)
          )
          .reduce((a, b) => a + parseFloat(b), 0) /
          parseInt(
            data
              .map((list) => list.detail.length)
              .reduce((a, b) => a + parseInt(b), 0)
          )
      ).toLocaleString("kr-ko")}`,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    },
    {
      content: `${parseInt(
        data
          .map((list) =>
            list.detail.reduce(
              (a, b) => a + parseFloat(b.harga) / parseFloat(b.berat),
              0
            )
          )
          .reduce((a, b) => a + parseInt(b), 0) /
          parseInt(
            data
              .map((list) => list.detail.length)
              .reduce((a, b) => a + parseInt(b), 0)
          )
      ).toLocaleString("kr-ko")}`,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    },
    {
      content: ``,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    },

    // `${sub_qty}`,
  ];
  tableRows.push(footer);
  let printed = [
    {
      colSpan: 6,
      content: `Printed By ${getItem("userdata").user_id} / ${getToday()}`,
      styles: {
        // lineWidth: 0.1,
        fontStyle: "italic",
        textColor: "#000",
        // fillColor: "#E8E5E5"
      },
    },
  ];
  tableRows.push(printed);
  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    // foot: footRows,
    startY: finalY,
    theme: "plain",
    rowPageBreak: "avoid",
    // pageBreak: "avoid",
    margin: { top: 10 },
    bodyStyles: {
      // lineWidth: 0.02,
      // lineColor: "#000",
      fontSize: 8,
    },
    headStyles: {
      fontSize: 8,
      // lineWidth: 0.02,
      // lineColor: "#000",
      textColor: "#000",
      fillColor: "#E8E5E5",
    },
  });
  finalY = doc.autoTableEndPosY() + 3;
  tableRows = [];

  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  var string = doc.output("datauristring");
  let jmldata = data
    .map((list) => list.detail.length)
    .reduce((a, b) => a + b, 0);
  if (jmldata > 500) {
    doc.save(`Laporan Pembelian Per Faktur.pdf`);
  } else {
    var x = window.open();
    x.document.open();
    x.document.write(
      "<html><head><title>Laporan Pembelian Per Faktur</title></head><body style='margin:0 !important'><embed width='100%' height='100%'  src=" +
        string +
        "></embed></body></html>"
    );
  }
};

export default LaporanPembelianPerfakturPdf;
