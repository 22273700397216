export const SET_HUTANG = "SET_HUTANG";
export const SHOW_MODAL_LIHAT_HUTANG = "SHOW_MODAL_LIHAT_HUTANG";
export const GET_VALIDASI_HUTANG = "GET_VALIDASI_HUTANG";
export const SAVE_VALIDASI_JUAL = "SAVE_VALIDASI_JUAL";
export const SAVE_VALIDASI_JUAL_MANUAL = "SAVE_VALIDASI_JUAL_MANUAL";
export const SAVE_VALIDASI_BELi = "SAVE_VALIDASI_BELi";
export const SAVE_VALIDASI_BELI_MANUAL = "SAVE_VALIDASI_BELI_MANUAL";
export const SAVE_VALIDASI_TITIPAN = "SAVE_VALIDASI_TITIPAN";
export const SAVE_VALIDASI_PESANAN = "SAVE_VALIDASI_PESANAN";
export const DISABLE_BUTTON_VALIDASI = "DISABLE_BUTTON_VALIDASI";

export const setBarangHutang = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_HUTANG,
      payload: {
        data: data,
      },
    });
  };
};
export const showModalLihatHutang = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_LIHAT_HUTANG,
      payload: {
        data: data,
      },
    });
  };
};
export const getDataValidasiHutang = (data) => {
  return (dispatch) => {
    dispatch({
      type: GET_VALIDASI_HUTANG,
      payload: {
        data: data,
      },
    });
  };
};
export const saveValidasiJual = (data) => {
  return (dispatch) => {
    dispatch({
      type: SAVE_VALIDASI_JUAL,
      payload: {
        data: data,
      },
    });
  };
};
export const saveValidasiJualManual = (data) => {
  return (dispatch) => {
    dispatch({
      type: SAVE_VALIDASI_JUAL_MANUAL,
      payload: {
        data: data,
      },
    });
  };
};
export const saveValidasiPesanan = (data) => {
  return (dispatch) => {
    dispatch({
      type: SAVE_VALIDASI_PESANAN,
      payload: {
        data: data,
      },
    });
  };
};
export const saveValidasiBeli = (data) => {
  return (dispatch) => {
    dispatch({
      type: SAVE_VALIDASI_BELi,
      payload: {
        data: data,
      },
    });
  };
};
export const saveValidasiBeliManual = (data) => {
  return (dispatch) => {
    dispatch({
      type: SAVE_VALIDASI_BELI_MANUAL,
      payload: {
        data: data,
      },
    });
  };
};
export const saveValidasiTitipan = (data) => {
  return (dispatch) => {
    dispatch({
      type: SAVE_VALIDASI_TITIPAN,
      payload: {
        data: data,
      },
    });
  };
};
export const disableButtonValidasi = (data) => {
  return (dispatch) => {
    dispatch({
      type: DISABLE_BUTTON_VALIDASI,
      payload: {
        data: data,
      },
    });
  };
};

