import {
  PanelContent,
  useDispatch,
} from "../../../../components/helpers/library";
import React from "react";
import FormLaporanPenebusan from "./Form";
import { cariLaporan } from "./Redux";

const LaporanPenebusanDetail = () => {
  const dispatch = useDispatch();
  return (
    <PanelContent menu="Laporan Penebusan Detail" submenu="Laporan">
      <FormLaporanPenebusan onSubmit={(data) => dispatch(cariLaporan(data))} />
    </PanelContent>
  );
};

export default LaporanPenebusanDetail;
