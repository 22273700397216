import {
  findModule,
  validateDateRange,
} from "../../components/helpers/function";

const ValidasiLaporanPenjualanSales = (value) => {
  let moduleProteksiqBulan = findModule("REPORT_SATU_BULAN_PROTECTION_MODULE");

  const errors = {};
  if (!value.tanggal_awal) {
    errors.tanggal_awal = "Tanggal Tidak Boleh Kosong";
  }
  if (!value.sampai_tgl) {
    errors.sampai_tgl = "Tanggal Tidak Boleh Kosong";
  }
  if (!value.kode_sales) {
    errors.kode_sales = "Kode Tidak Boleh Kosong";
  }

  if (moduleProteksiqBulan) {
    if (value.tgl_awal && value.tgl_akhir) {
      const isDateRangeValid = validateDateRange(
        value.tgl_awal,
        value.tgl_akhir
      );
      if (!isDateRangeValid.status) {
        errors.tgl_awal = isDateRangeValid.pesan;
        errors.tgl_akhir = isDateRangeValid.pesan;
      }
    }
  }

  return errors;
};

export default ValidasiLaporanPenjualanSales;
