import { getTglSystem } from "../components/helpers/function";
import { getDataNoTimeOut, getDataParams } from "../config/axios";

export const GET_CHART = "GET_CHART";
export const GET_CHART_PENJUALAN = "GET_CHART_PENJUALAN";
export const GET_CHART_PENJUALAN_PANTES_PUSAT =
  "GET_CHART_PENJUALAN_PANTES_PUSAT";

export const getChartData = () => {
  return async (dispatch, getState) => {
    const response = await getDataNoTimeOut(
      "cash-pusat/daily-transaction-chart"
    );
    console.log(
      response?.data?.[0]?.resultGram[1]?.y,
      "DATADTADTADTATDTADTADTATDADA"
    );
    dispatch({
      type: GET_CHART,
      payload: {
        data: response?.data,
        total_penjualan: response?.data?.[0]?.values[0]?.y,
        total_pembelian: response?.data?.[0]?.values[1]?.y,
        total_service: response?.data?.[0]?.values[2]?.y,
        total_hutang: response?.data?.[0]?.values[3]?.y,
        datagram_penjualan: response?.data?.[0]?.resultGram[0]?.y,
        datagram_pembelian: response?.data?.[0]?.resultGram[1]?.y,
      },
    });
  };
};

export const getChartPenjualan = (param) => {
  return async (dispatch, getState) => {
    const response = await getDataParams(
      "cash-pusat/period-transaction-chart",
      param
    );
    dispatch({
      type: GET_CHART_PENJUALAN,
      payload: response.data,
    });
  };
};
export const getChartPenjualanPantesPusat = (param) => {
  return async (dispatch, getState) => {
    const response = await getDataParams(
      "transaction/daily-transaction-get-year",
      param
    );

    dispatch({
      type: GET_CHART_PENJUALAN_PANTES_PUSAT,
      payload: response.data,
    });
  };
};

export const getDetailChart = (kategori, isCash = false, type) => {
  return async (dispatch, getState) => {
    let response = [];
    if (isCash) {
      response = await getDataParams("cash-pusat/daily-cash-chart", {});
    } else {
      if (kategori === "JUMLAH_BIAYA") {
        response = await getDataParams("cash-pusat/daily-biaya", {
          tgl_system: getTglSystem(),
        });
      } else if (kategori === "NON_CASH_OUTSTAND") {
        response = await getDataParams("cash-pusat/outstand-noncash", {
          tgl_system: getTglSystem(),
        });
      } else {
        response = await getDataParams("cash-pusat/daily-specific-chart", {
          kategori: kategori,
          type: type,
        });
      }
    }
    dispatch({
      type: GET_CHART,
      payload: {
        data: response.data,
        total_penjualan: 0,
        total_pembelian: 0,
        total_service: 0,
        total_hutang: 0,
      },
    });
  };
};
