import {
  GET_BARANG_BARCODE,
  GET_NO_KIRIM,
  GET_NO_KIRIM_UNRECEIVE,
  GET_TIMBANG_BARANG,
  REPORT_AUTHORIZATION,
  SET_BARANG_PINDAH,
  SET_BARANG_PINDAH_SATU_BAKI,
  SET_CAMERA,
  SET_HANCUR_BARANG,
  SHOW_MODAL_EDIT,
  SHOW_MODAL_PESANAN,
  SHOW_MODAL_TERIMA_BARANG,
  UPDATE_TIMBANG_BARANG,
  GET_DATA_BARANG_MANUAL
} from "../actions/databarang_action";

const initialState = {
  getDataBarangManual: [],
  getNokirim: [],
  ShowModalTerimaBarang: false,
  setCamera: false,
  setBarangPindah: [],
  setBarangPindahSatuBaki: [],
  getTimbangBarang: [],
  setHancurBarang: [],
  showEditBarang: [],
  showModalPesanan: false,
  dataAuthorization: [],
  barangBarcode : [],
  getNokirimUnreceive : [],
};

const databarang = (state = initialState, actions) => {
  switch (actions.type) {
    case GET_DATA_BARANG_MANUAL:
      return {
        ...state,
        getDataBarangManual: actions.payload.data,
      };
    case GET_NO_KIRIM_UNRECEIVE:
      return {
        ...state,
        getNokirimUnreceive: actions.payload.data,
      };
    case SHOW_MODAL_TERIMA_BARANG:
      return {
        ...state,
        ShowModalTerimaBarang: actions.payload.data,
      };
    case GET_NO_KIRIM:
      return {
        ...state,
        getNokirim: actions.payload.data,
      };
    case SET_CAMERA:
      return {
        ...state,
        setCamera: actions.payload.data,
      };
    case SET_BARANG_PINDAH:
      return {
        ...state,
        setBarangPindah: actions.payload.data,
      };
    case SET_BARANG_PINDAH_SATU_BAKI:
      return {
        ...state,
        setBarangPindahSatuBaki: actions.payload.data,
      };
    case SET_HANCUR_BARANG:
      return {
        ...state,
        setHancurBarang: actions.payload.data,
      };
    case SHOW_MODAL_EDIT:
      return {
        ...state,
        showEditBarang: actions.payload.data,
      };
    case SHOW_MODAL_PESANAN:
      return {
        ...state,
        showModalPesanan: actions.payload.data,
      };
    case GET_TIMBANG_BARANG:
      return {
        ...state,
        getTimbangBarang : actions.payload.data
      }
    case UPDATE_TIMBANG_BARANG:
      return {
        ...state,
        getTimbangBarang : actions.payload.data
      }
    case REPORT_AUTHORIZATION:
      return  {
        ...state,
        dataAuthorization: actions.payload.data
      }
    case GET_BARANG_BARCODE:
      return {
        ...state,
        barangBarcode : actions.payload.data
      }
    default:
      return state;
  }
};
export default databarang;
