import { DISABLE_BUTTON_VALIDASI, GET_VALIDASI_HUTANG, SAVE_VALIDASI_BELI_MANUAL, SAVE_VALIDASI_BELi, SAVE_VALIDASI_JUAL, SAVE_VALIDASI_JUAL_MANUAL, SAVE_VALIDASI_PESANAN, SAVE_VALIDASI_TITIPAN, SET_HUTANG, SHOW_MODAL_LIHAT_HUTANG } from "../actions/hutang_action";

const initialState = {
    setBarangHutang: [],
    showModalLihatHutang: [],
    getDataValidasiHutang: [],
    saveValidasiJual: [],
    saveValidasiBeli: [],
    saveValidasiTitipan: [],
    saveValidasiPesanan: [],
    saveValidasiJualManual: [],
    saveValidasiBeliManual: [],
    disableButtonValidasi: false
};

const hutang = (state = initialState, actions) => {
    switch (actions.type) {
        case SET_HUTANG:
            return {
                ...state,
                setBarangHutang: actions.payload.data,
            };
        case SHOW_MODAL_LIHAT_HUTANG:
            return {
                ...state,
                showModalLihatHutang: actions.payload.data,
            };
        case GET_VALIDASI_HUTANG:
            return {
                ...state,
                getDataValidasiHutang: actions.payload.data,
            };
        case SAVE_VALIDASI_JUAL:
            return {
                ...state,
                saveValidasiJual: actions.payload.data,
            };
        case SAVE_VALIDASI_TITIPAN:
            return {
                ...state,
                saveValidasiTitipan: actions.payload.data,
            };
        case SAVE_VALIDASI_PESANAN:
            return {
                ...state,
                saveValidasiPesanan: actions.payload.data,
            };
        case DISABLE_BUTTON_VALIDASI:
            return {
                ...state,
                disableButtonValidasi: actions.payload.data,
            };
        case SAVE_VALIDASI_BELi:
            return {
                ...state,
                saveValidasiBeli: actions.payload.data,
            };
        case SAVE_VALIDASI_BELI_MANUAL:
            return {
                ...state,
                saveValidasiBeliManual: actions.payload.data,
            };
        case SAVE_VALIDASI_JUAL_MANUAL:
            return {
                ...state,
                saveValidasiJualManual: actions.payload.data,
            };
        default:
            return state;
    }
}

export default hutang;