import React, { Component } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import {
  getItem,
  getUserdata,
} from "../../../../../components/helpers/function";
class LaporanBatalPesananExel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="btn btn-success btn-block"
          table="table-to-xls"
          filename="Laporan Pesanan"
          sheet="Laporan Pesanan"
          buttonText="Export Excel"
        />
        <table id="table-to-xls" style={{ display: "none" }}>
          <thead>
            <tr>
              <th colSpan="9" style={{ textAlign: "center" }}>
                {" "}
                LAPORAN BATAL PESANAN{" "}
              </th>
            </tr>
            <tr>
              <th colSpan="9"> Periode </th>
            </tr>
            <tr>
              <th colSpan="9">
                {" "}
                {getItem("tgl_laporan").length === 0
                  ? null
                  : getItem("tgl_laporan").tgl_from +
                    " s/d " +
                    getItem("tgl_laporan").tgl_to}{" "}
              </th>
            </tr>
            <tr>
              <th colSpan="9"> TOKO : {getUserdata().nama_toko} </th>
            </tr>

            <tr>
              <th colSpan="9"> ALAMAT : {getUserdata().alamat_toko} </th>
            </tr>
            <tr></tr>
            <tr>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                NO PESANAN
              </td>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                SALES
              </td>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                TGL PESAN
              </td>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                TOTAL BERAT
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                TOTAL QTY
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                ONGKOS
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                TOTAL RP
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                BAYAR RP
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                SISA RP
              </td>
            </tr>
          </thead>
          <tbody>
            {this.props.data.map((row, index) => (
              <tr key={index}>
                <td>{row.no_pesanan}</td>
                <td>{row.kode_sales}</td>
                <td>{row.tanggal}</td>
                <td style={{ textAlign: "right" }}>
                  {row.total_berat.toFixed(2)}
                </td>
                <td style={{ textAlign: "right" }}>
                  {row.total_qty.toLocaleString("kr-KO")}
                </td>
                <td style={{ textAlign: "right" }}>
                  {row.ongkos.toLocaleString("kr-KO")}
                </td>
                <td style={{ textAlign: "right" }}>
                  {row.total_harga.toLocaleString("kr-KO")}
                </td>
                <td style={{ textAlign: "right" }}>
                  {row.jumlah_bayar.toLocaleString("kr-KO")}
                </td>
                <td style={{ textAlign: "right" }}>
                  {row.sisa_bayar.toLocaleString("kr-KO")}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td
                colSpan="3"
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "center",
                }}
              >
                Grand Total Barang :
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {" "}
                {this.props.data
                  .map((list) => list.total_berat)
                  .reduce((a, b) => a + b, 0)
                  .toFixed(2)}{" "}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {" "}
                {this.props.data
                  .map((list) => list.total_qty)
                  .reduce((a, b) => a + b, 0)
                  .toLocaleString("ko-KO")}{" "}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {" "}
                {this.props.data
                  .map((list) => list.ongkos)
                  .reduce((a, b) => a + b, 0)
                  .toLocaleString("ko-KO")}{" "}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {" "}
                {this.props.data
                  .map((list) => list.total_harga)
                  .reduce((a, b) => a + b, 0)
                  .toLocaleString("ko-KO")}{" "}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {" "}
                {this.props.data
                  .map((list) => list.jumlah_bayar)
                  .reduce((a, b) => a + b, 0)
                  .toLocaleString("ko-KO")}{" "}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {" "}
                {this.props.data
                  .map((list) => list.sisa_bayar)
                  .reduce((a, b) => a + b, 0)
                  .toLocaleString("ko-KO")}{" "}
              </td>
            </tr>
          </tfoot>
        </table>
      </>
    );
  }
}
export default LaporanBatalPesananExel;
