export const GET_PARAMETER_KUPON_ID = "GET_PARAMETER_KUPON_ID";

export const getParameterKuponID = (data) => {
  return (dispatch) => {
    dispatch({
      type: GET_PARAMETER_KUPON_ID,
      payload: {
        data: data,
      },
    });
  };
};
