import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FormModal } from "../../actions/databarang_action";
import { hideModal, simpanDataTmp } from "../../actions/datamaster_action";
import { removeItem } from "./function";
// import Draggable from "react-draggable";
const maptostate = (state) => {
  return {
    isOpen: state.datamaster.modalDialog,
  };
};
class ModalGlobal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  hideModal() {
    this.props.dispatch(hideModal());
    this.props.dispatch(simpanDataTmp([]));
    removeItem("total_rp_services");
  }
  render() {
    return (
      <Modal
        backdrop="static"
        keyboard={false}
        id={this.props.id}
        isOpen={this.props.isOpen}
        toggle={() => this.props.dispatch(hideModal())}
        size={this.props.size || "xl"}
      >
        <ModalHeader
          toggle={() =>
            this.props.form === "formBerlian"
              ? this.props.dispatch(FormModal("FormTambahBarang"))
              : this.props.form === "FormEditBerlian"
              ? this.props.dispatch(FormModal("FormEditBarang"))
              : this.hideModal()
          }
        >
          {this.props.title}
        </ModalHeader>
        <ModalBody>{this.props.content}</ModalBody>
      </Modal>
    );
  }
}

export default connect(maptostate, null)(ModalGlobal);
