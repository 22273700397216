import { getItem } from "../components/helpers/function";
import { getData } from "../config/axios";

export const GET_DATA_TIMBANGAN = "GET_DATA_TIMBANGAN";
export const GET_PARA_SYSTEM = "GET_PARA_SYSTEM";
export const GET_PARA_SYSTEM_ID = "GET_PARA_SYSTEM_ID";
export const SET_LOADING = "SET_LOADING";
export const GET_DATA_MODAL = "GET_DATA_MODAL";
export const DATA_NOTA = "DATA_NOTA";
export const FORM_MODAL_NAME = "FORM_MODAL_NAME";
export const IS_DISABLED = "IS_DISABLED";

export const disabled = (data) => {
  return (dispatch) => {
    dispatch({
      type: IS_DISABLED,
      payload: {
        data: data,
      },
    });
  };
};
export const setFormModalName = (data) => {
  return (dispatch) => {
    dispatch({
      type: FORM_MODAL_NAME,
      payload: {
        data: data,
      },
    });
  };
};
export const setDataNota = (data) => {
  return (dispatch) => {
    dispatch({
      type: DATA_NOTA,
      payload: {
        data: data,
      },
    });
  };
};

export const getDataModal = (data) => {
  return (dispatch) => {
    dispatch({
      type: GET_DATA_MODAL,
      payload: {
        data: data,
      },
    });
  };
};

export const getTimbangan = (data) => {
  let timbangan = getItem("timbangan");
  return (dispatch) => {
    dispatch({
      type: GET_DATA_TIMBANGAN,
      payload: {
        data:
          timbangan.length === 0
            ? 0
            : timbangan.split("g")[timbangan.split("g").length - 2],
      },
    });
  };
};

export const getParaSystemId = (key) => {
  return (dispatch) => {
    getData("para-system/key/" + key)
      .then((res) => {
        // console.log(res);
        dispatch({
          type: GET_PARA_SYSTEM_ID,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getParaSystem = () => {
  return (dispatch) => {
    getData("para-system")
      .then((res) => {
        // console.log(res);
        dispatch({
          type: GET_PARA_SYSTEM,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const doLoading = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_LOADING,
      payload: true,
    });
  };
};

export const stopLoading = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_LOADING,
      payload: false,
    });
  };
};
