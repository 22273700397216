import React from "react";
import { Card, CardBody, CardHeader, Collapse } from "reactstrap";
import PropTypes from "prop-types";

export class Accordion extends React.Component {
  state = {
    open: this.props.open
  };

  toggleSection = (index) => () => {
    this.setState(({ open }) => ({
      open: index === open ? undefined : index
    }));
  };

  render() {
    return (
      <div className="accordion">
        {React.Children.map(this.props.children, (child, index) => {
          if (child.type !== AccordionItem) return null;
          return React.cloneElement(child, {
            isOpen: child.props.open || this.state.open === index,
            onClick: this.toggleSection(index)
          });
        })}
      </div>
    );
  }
}

Accordion.propTypes = {
  open: PropTypes.number
};

const AccordionItem = ({ children, isOpen, onClick }) => (
  <Card>
    {React.Children.map(children, (child, index) => {
      if (child.type === AccordionHeader) {
        return React.cloneElement(child, { onClick, isOpen, index });
      }

      if (child.type === AccordionBody) {
        return React.cloneElement(child, { isOpen });
      }

      return null;
    })}
  </Card>
);

const AccordionHeader = ({ children, onClick, isOpen, index }) => (
  <CardHeader>
    {/* <p onClick={onClick} style={{ cursor: "pointer" }}>
      {isOpen ? (
        <i class="fa fa-chevron-down"></i>
      ) : (
        <i class="fa fa-chevron-right"></i>
      )} */}
    {/* &nbsp;  */}
    <span onClick={onClick} style={{ cursor: "pointer" }}>
      {children}
    </span>
    {/* </p> */}
  </CardHeader>
);

const AccordionBody = ({ children, isOpen }) => (
  <Collapse isOpen={isOpen}>
    <CardBody>{children}</CardBody>
  </Collapse>
);
const AccordionBodyItem = ({ children, isOpen }) => (
  <div>
    {children}
    <hr />
  </div>
);

Accordion.Item = AccordionItem;
Accordion.Header = AccordionHeader;
Accordion.Body = AccordionBody;
Accordion.BodyItem = AccordionBodyItem;
