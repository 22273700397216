import React from "react";
import SelectSearch from "react-select-search";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Skeleton from "react-loading-skeleton";
import { isPos } from "./function";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

export const renderTextArea = ({
  input,
  label,
  placeholder,
  id,
  tabIndex,
  meta: { touched, error, warning },
}) => (
  <div className="form-group">
    <label htmlFor="" className="text-black">
      {label}
    </label>
    <textarea
      {...input}
      tabIndex={tabIndex}
      autoComplete="off"
      id={id}
      className="form-control"
      placeholder={placeholder}
      rows="4"
    ></textarea>

    {touched &&
      ((error && (
        <ul className="parsley-errors-list filled">
          <li className="parsley-required"> {error}.</li>
        </ul>
      )) ||
        (warning && <p>{warning}</p>))}
  </div>
);

export const ReanderTextArea = ({
  input,
  label,
  type,
  readOnly,
  placeholder,
  id,
  tabIndex,
  ref,
  customeCss,
  stylelabel,
  maxLength,
  height,
  width,
  meta: { touched, error, warning },
}) => (
  <div className="form-group">
    <label htmlFor="" className={stylelabel ? "text-white" : "text-black"}>
      {label || <> &nbsp; </>}
    </label>
    <textarea
      {...input}
      tabIndex={tabIndex}
      ref={ref}
      autoComplete="off"
      type={type}
      id={id}
      className={"form-control " + customeCss}
      readOnly={readOnly}
      rows={height}
      cols={width}
      maxLength={maxLength}
      placeholder={placeholder}
    />
    {touched &&
      ((error && (
        <ul className="parsley-errors-list filled">
          <li className="parsley-required"> {error}.</li>
        </ul>
      )) ||
        (warning && <p>{warning}</p>))}
  </div>
);

export const ReanderSelectMultiple = ({
  input,
  label,
  readOnly,
  placeholder,
  options,
  value,
  id,
  disabled,
  tabIndex,
  defaultValue,
  onChange,
  meta: { touched, error, warning },
}) => (
  <div className="form-group">
    <label htmlFor="" className="text-black">
      {label}
    </label>
    <Select
      defaultValue={defaultValue}
      onInputKeyDown={(event) => {
        if (event.key === "Enter") {
          event.preventDefault();
        } else {
        }
      }}
      {...input}
      options={options}
      isSearchable={true}
      id={label}
      isMulti
      openMenuOnFocus
      tabIndex={tabIndex}
      isDisabled={readOnly}
      onChange={(value) => input.onChange(value)}
      onBlur={() => input.onBlur(input.value)}
    />

    {touched &&
      ((error && (
        <ul className="parsley-errors-list filled">
          <li className="parsley-required"> {error}.</li>
        </ul>
      )) ||
        (warning && <p>{warning}</p>))}
  </div>
);

export const RenderFieldGroup = ({
  input,
  label,
  readOnly,
  placeholder,
  onInputChange,
  disabled,
  tabIndex,
  inputValue,
  isivalue,
  onKeyDown,
  isLoading,
  isClearable,
  isMulti,
  meta: { touched, error, warning },
  textColor = "text-black",
}) => (
  <div className="form-group">
    <label htmlFor="" className={textColor}>
      {label}
    </label>
    <CreatableSelect
      {...input}
      isClearable={isClearable}
      isMulti={isMulti}
      inputValue={inputValue}
      isLoading={isLoading}
      readOnly={readOnly}
      menuIsOpen={false}
      onChange={(value) => input.onChange(value)}
      onBlur={() => input.onBlur(input.value)}
      components={{ DropdownIndicator: null }}
      onInputChange={onInputChange}
      onKeyDown={onKeyDown}
      tabIndex={tabIndex}
      disabled={disabled}
      placeholder={placeholder}
      value={isivalue}
    />
    {touched &&
      ((error && (
        <ul className="parsley-errors-list filled">
          <li className="parsley-required"> {error}.</li>
        </ul>
      )) ||
        (warning && <p>{warning}</p>))}
  </div>
);
export const ReanderSelect2 = ({
  input,
  label,
  readOnly,
  placeholder,
  options,
  onChange,
  id,
  isMulti,
  disabled,
  tabIndex,
  closeMenuOnSelect,
  meta: { touched, error, warning },
  value,
  textColor = "text-black",
}) => (
  <div className="form-group">
    <label htmlFor="" className={textColor}>
      {label}
    </label>
    <Select
      {...input}
      id={id}
      isClearable={true}
      closeMenuOnSelect={closeMenuOnSelect}
      isMulti={isMulti}
      readOnly={readOnly}
      onChange={(value) => input.onChange(value)}
      onBlur={() => input.onBlur(input.value)}
      tabIndex={tabIndex}
      disabled={disabled}
      placeholder={placeholder}
      options={options}
      styles={{
        // Fixes the overlapping problem of the component
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
      }}
    />
    {touched &&
      ((error && (
        <ul className="parsley-errors-list filled">
          <li className="parsley-required"> {error}.</li>
        </ul>
      )) ||
        (warning && <p>{warning}</p>))}
  </div>
);
export const HiidenFiled = ({
  input,
  label,
  type,
  readOnly,
  placeholder,
  value,
  id,
  tabIndex,
  meta: { touched, error, warning },
}) => (
  <>
    <input
      onKeyPress={(event) => {
        if (event.key === "Enter") {
          event.preventDefault(); //<===== This stops the form from being submitted
        } else {
        }
      }}
      {...input}
      tabIndex={tabIndex}
      autoComplete="off"
      type={type}
      id={id}
      className="form-control"
      readOnly={readOnly}
      defaultValue={value}
      placeholder={placeholder}
    />
  </>
);

export const inputGroup = ({
  input,
  label,
  type,
  readOnly,
  placeholder,
  id,
  tabIndex,
  textValue,
  ref,
  customeCss,
  btnAction,
  nouperCase,
  enableenter,
  meta: { touched, error, warning },
}) => (
  <div className="form-group">
    <label htmlFor="" className="text-black">
      {label || <> &nbsp; </>}
    </label>
    <div className="input-group">
      <input
        onKeyPress={(event) => {
          if (enableenter) {
          } else {
            if (event.key === "Enter") {
              event.preventDefault(); //<===== This stops the form from being submitted
            } else {
            }
          }
        }}
        {...input}
        tabIndex={tabIndex}
        ref={ref}
        autoComplete="off"
        type={type}
        id={id}
        style={{ textTransform: !nouperCase ? "uppercase" : "none" }}
        className={"form-control " + customeCss}
        readOnly={readOnly}
        // defaultValue={defaultValue}
        placeholder={placeholder}
      />
      <div className="input-group-prepend">
        <span
          onClick={btnAction}
          style={{ cursor: "pointer" }}
          className="input-group-text"
        >
          {textValue}
        </span>
      </div>
    </div>
    {touched &&
      ((error && <p className="parsley-errors-list filled"> {error}.</p>) ||
        (warning && <p>{warning}</p>))}
  </div>
);
export const ReanderField = ({
  input,
  label,
  type,
  readOnly,
  placeholder,
  id,
  tabIndex,
  autoFocus,
  ref,
  customeCss,
  minLength,
  defaultValue,
  maxLength,
  nouperCase,
  textColor = "text-black",
  meta: { touched, error, warning },
}) => (
  <div className="form-group">
    <label htmlFor="" className={textColor}>
      {label || <> &nbsp; </>}
    </label>
    <input
      onKeyPress={(event) => {
        if (event.key === "Enter") {
          event.preventDefault(); //<===== This stops the form from being submitted
        } else {
        }
      }}
      {...input}
      tabIndex={tabIndex}
      ref={ref}
      autoComplete="off"
      type={type}
      id={id}
      style={{ textTransform: !nouperCase ? "uppercase" : "none" }}
      className={"form-control " + customeCss}
      readOnly={readOnly}
      minLength={minLength}
      maxLength={maxLength}
      placeholder={placeholder}
    />
    {touched &&
      ((error && (
        <ul className="parsley-errors-list filled">
          <li className="parsley-required"> {error}.</li>
        </ul>
      )) ||
        (warning && <p>{warning}</p>))}
  </div>
);
export const ReanderCheckBox = ({
  input,
  label,
  type,
  readOnly,
  placeholder,
  id,
  tabIndex,
  autoFocus,
  ref,
  onClick,
  disabledGroup,
  enabledEnter,
  meta: { touched, error, warning },
}) => (
  <div className={disabledGroup ? "" : "form-group"}>
    {enabledEnter && <br />}
    {label && <>{label || <> &nbsp; </>}</>}
    {enabledEnter && <br />}
    <div className="switcher">
      <input
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            event.preventDefault(); //<===== This stops the form from being submitted
          } else {
          }
        }}
        {...input}
        defaultChecked
        onClick={onClick}
        tabIndex={tabIndex}
        ref={ref}
        autoComplete="off"
        type={type}
        id={id}
        readOnly={readOnly}
        placeholder={placeholder}
      />{" "}
      <label htmlFor={id}></label>
    </div>

    {touched &&
      ((error && (
        <ul className="parsley-errors-list filled">
          <li className="parsley-required"> {error}.</li>
        </ul>
      )) ||
        (warning && <p>{warning}</p>))}
  </div>
);

export const SkeletonLoading = ({ label }) => (
  <div className="form-group">
    <label> {label} </label>
    <Skeleton className="form-control" />
  </div>
);

export const ReanderSelect = ({
  input,
  label,
  readOnly,
  placeholder,
  options,
  value,
  id,
  disabled,
  tabIndex,
  getOptions,
  meta: { touched, error, warning },
  textColor = "text-black",
}) => (
  <div className="form-group">
    <label htmlFor="" className={textColor}>
      {label}
    </label>
    <SelectSearch
      autoComplete="off"
      onInputKeyDown={(event) => {
        if (event.key === "Enter") {
          event.preventDefault(); //<===== This stops the form from being submitted
        } else {
        }
      }}
      {...input}
      id={id}
      readOnly={readOnly}
      search
      tabIndex={tabIndex}
      disabled={disabled}
      placeholder={placeholder}
      options={options}
      getOptions={getOptions}
      classNamePrefix={"my-custom-react-select"}
    />
    {/* <Select
        value={value}
        readOnly={readOnly}
        disabled={disabled}
        placeholder={placeholder}
        options={options}
     
      /> */}

    {touched &&
      ((error && (
        <ul className="parsley-errors-list filled">
          <li className="parsley-required"> {error}.</li>
        </ul>
      )) ||
        (warning && <p>{warning}</p>))}
  </div>
);

export const InputDate = ({
  input,
  label,
  readOnly,
  placeholder,
  id,
  selected,
  customInput,
  minDate,
  maxDate,
  meta: { touched, error, warning },
}) => (
  <div className="input-group mb-3">
    <label htmlFor="" className="text-black">
      {label}
    </label>
    <div className="customDatePickerWidth">
      <DatePicker
        id={id}
        maxDate={maxDate}
        todayButton="Hari Ini"
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        dateFormat="yyyy-MM-dd"
        autoComplete="off"
        minDate={minDate}
        {...input}
        onKeyDown={(event) => {
          event.preventDefault(); //<===== This stops the form from being submitted
          return false;
        }}
        onFocus={() =>
          setTimeout(() => {
            if (isPos()) {
              // eslint-disable-next-line no-undef
              HideKeyboard.postMessage("");
            }
          }, 50)
        }
        selected={selected}
        disabledKeyboardNavigation={true}
        className="form-control"
        readOnly={readOnly}
        placeholder={placeholder}
        customInput={customInput}
      />
      {touched &&
        ((error && (
          <ul className="parsley-errors-list filled">
            <li className="parsley-required"> {error}.</li>
          </ul>
        )) ||
          (warning && <p>{warning}</p>))}
    </div>
  </div>
);
