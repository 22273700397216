import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  getToday,
  getUserdata,
  getItem,
} from "../../../../../components/helpers/function";
// Date Fns is used to format the dates we receive

// define a generatePDF function that accepts a tickets argument
const LaporanBatalTitipPdf = (data = "") => {
  // initialize jsPDF
  const doc = new jsPDF("l", "mm", [297, 210]);

  let tableRows = [];
  let tableColumn = [];
  let finalY = 30;

  let total_dp = 0;

  doc.setFontSize(15);
  let judulLaporan = doc.splitTextToSize("LAPORAN BATAL TITIP", 100);
  doc.text(getUserdata().nama_toko, 14, 15);
  doc.setFontSize(20);
  doc.text(judulLaporan, 200, 15);

  doc.setFontSize(8);
  let jml_alamat = getUserdata().alamat_toko.length;
  if (jml_alamat > 20) {
    doc.text(getUserdata().alamat_toko.slice(0, 48), 14, 20);
  }
  if (jml_alamat > 50) {
    doc.text(getUserdata().alamat_toko.slice(48, 90), 14, 25);
  }
  doc.setFontSize(10);
  doc.setProperties({
    title: "LAPORAN  TITIP UANG",
  });
  //row 1
  doc.text(
    `PERIODE : ${
      getItem("tgl_laporan").length === 0
        ? null
        : getItem("tgl_laporan").tgl_from +
          " s/d " +
          getItem("tgl_laporan").tgl_to
    }`,
    200,
    20
  );
  tableColumn = [
    [
      {
        content: `Nama Barang`,
      },
      {
        content: `BERAT`,
        styles: {
          halign: "right",
        },
      },

      {
        content: `HARGA JUAL`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `ONGKOS`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `SUB TOTAL`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `DP RP`,
        styles: {
          halign: "right",
        },
      },
    ],
  ];

  data.forEach((item, index) => {
    total_dp = total_dp + parseInt(item.dp_rp || 0);
    let rows = [
      item.nama_barang,
      {
        content: item.berat.toFixed(3),
        styles: {
          halign: "right",
        },
      },
      {
        content: parseInt(item.harga_jual).toLocaleString("kr-KO"),
        styles: {
          halign: "right",
        },
      },

      {
        content: parseInt(item.ongkos).toLocaleString("kr-KO"),
        styles: {
          halign: "right",
        },
      },
      {
        content: parseInt(item.harga_total).toLocaleString("kr-KO"),
        styles: {
          halign: "right",
        },
      },
      {
        content: parseInt(item.dp_rp || 0).toLocaleString("kr-KO"),
        styles: {
          halign: "right",
        },
      },
    ];

    tableRows.push(rows);
  });

  let grand_total = [
    {
      content: `Grand Total`,
      colSpan: 1,
      styles: {
        halign: "center",
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },

    {
      content: `${data
        .reduce((a, b) => a + parseFloat(b.berat), 0)
        .toFixed(3)}`,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    },
    {
      content: `${data
        .reduce((a, b) => a + b.harga_jual, 0)
        .toLocaleString("kr-KO")}`,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    },
    {
      content: `${data
        .reduce((a, b) => a + b.ongkos, 0)
        .toLocaleString("kr-KO")}`,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    },
    {
      content: `${data
        .reduce((a, b) => a + b.harga_total, 0)
        .toLocaleString("kr-KO")}`,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    },
    {
      content: `${total_dp.toLocaleString("kr-KO")}`,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    },
  ];
  tableRows.push(grand_total);
  let printed = [
    {
      colSpan: 3,
      content: `Printed By ${getItem("userdata").user_id} / ${getToday()}`,
      styles: {
        fontStyle: "italic",
        textColor: "#000",
      },
    },
  ];
  tableRows.push(printed);
  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    startY: finalY,
    theme: "plain",
    rowPageBreak: "avoid",
    margin: { top: 10 },
    footStyles: {
      fontSize: 8,
    },
    bodyStyles: {
      fontSize: 8,
    },
    headStyles: {
      fontSize: 8,
      fillColor: "#E8E5E5",
      textColor: "#000",
    },
  });
  finalY = doc.autoTableEndPosY() + 3;
  tableRows = [];
  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  // doc.autoPrint();
  doc.save(`LAPORAN BATAL TITIP.pdf`);
};

export default LaporanBatalTitipPdf;
