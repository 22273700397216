import {
  isLoading,
  convertDate,
  ToastNotification,
  getDataParams,
} from "../../../../../components/helpers/library";
import LaporanPembelianPerfakturPdf from "../Laporan";

const cariLaporan = (data) => {
  return async (dispatch, getState) => {
    dispatch(isLoading(true));
    try {
      let data_post = {
        tgl_from: convertDate(data.tgl_awal),
        tgl_to: convertDate(data.tgl_akhir),
        kelompok_harga: data.kelompok_harga || "ALL",
        kode_group: data.kode_group || "ALL",
        kondisi: data.kondisi_beli || "ALL",
      };

      let hasil = await getDataParams(
        "pembelian/hidup/report-limited",
        data_post
      );
      if (hasil.data.length === 0) {
        ToastNotification("info", "Laporan Tidak Tersedia");
        dispatch(isLoading(false));
        return false;
      }
      ToastNotification("success", "Laporan Tersedia");
      LaporanPembelianPerfakturPdf(hasil.data, data_post);
      dispatch(isLoading(false));
    } catch (error) {
      ToastNotification("info", "Laporan Tidak Tersedia");
      dispatch(isLoading(false));
    }
  };
};

export { cariLaporan };
