export const SHOW_MENU_PEMBAYARAN_CICILAN = "SHOW_MENU_PEMBAYARAN_CICILAN";
export const SHOW_MENU_BATAL_PENJUALAN_CICILAN =
  "SHOW_MENU_BATAL_PENJUALAN_CICILAN";
export const SHOW_MENU_PENJUALAN_LELANG_CICILAN =
  "SHOW_MENU_PENJUALAN_LELANG_CICILAN";
export const SHOW_MENU_PENGAMBILAN_BARANG = "SHOW_MENU_PENGAMBILAN_BARANG";
export const SET_DETAIL_TRANSAKSI_CICILAN = "SET_DETAIL_TRANSAKSI_CICILAN";
export const SET_DETAIL_TRANSAKSI_CICILAN_LELANG =
  "SET_DETAIL_TRANSAKSI_CICILAN_LELANG";
export const SET_DATA_LIST_CICILAN_LELANG = "SET_DATA_LIST_CICILAN_LELANG";
export const SET_DATA_LIST_CICILAN = "SET_DATA_LIST_CICILAN";
export const SET_DATA_BAKI_BALIK_CICILAN = "SET_DATA_BAKI_BALIK_CICILAN";
export const SET_NO_FAKTUR_CICILAN = "SET_NO_FAKTUR_CICILAN";
export const SET_NO_FAKTUR_CICILAN_LELANG = "SET_NO_FAKTUR_CICILAN_LELANG";
export const SET_DATA_PEMBAYARAN_CICILAN = "SET_DATA_PEMBAYARAN_CICILAN";
export const SET_DATA_PEMBAYARAN_DP_CICILAN = "SET_DATA_PEMBAYARAN_DP_CICILAN";

export const ShowMenuPengambilanBarang = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MENU_PENGAMBILAN_BARANG,
      payload: {
        data: data,
      },
    });
  };
};

export const ShowMenuPembayaranCicilan = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MENU_PEMBAYARAN_CICILAN,
      payload: {
        data: data,
      },
    });
  };
};

export const ShowMenuPembayaranLelangCicilan = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MENU_PENJUALAN_LELANG_CICILAN,
      payload: {
        data: data,
      },
    });
  };
};

export const ShowMenuBatalPenjualanCicilan = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MENU_BATAL_PENJUALAN_CICILAN,
      payload: {
        data: data,
      },
    });
  };
};

export const setDetailTransaksiCicilan = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_DETAIL_TRANSAKSI_CICILAN,
      payload: {
        data: data,
      },
    });
  };
};

export const setDetailTransaksiCicilanLelang = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_DETAIL_TRANSAKSI_CICILAN_LELANG,
      payload: {
        data: data,
      },
    });
  };
};

export const setDataListCicilan = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_DATA_LIST_CICILAN,
      payload: {
        data: data,
      },
    });
  };
};

export const setDataListCicilanLelang = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_DATA_LIST_CICILAN_LELANG,
      payload: {
        data: data,
      },
    });
  };
};

export const setDataBakiBalikCicilan = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_DATA_BAKI_BALIK_CICILAN,
      payload: {
        data: data,
      },
    });
  };
};

export const setNoFakturCicil = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_NO_FAKTUR_CICILAN,
      payload: {
        data: data,
      },
    });
  };
};

export const setNoFakturCicilLelang = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_NO_FAKTUR_CICILAN_LELANG,
      payload: {
        data: data,
      },
    });
  };
};

export const setDataPembayaranCicilan = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_DATA_PEMBAYARAN_CICILAN,
      payload: {
        data: data,
      },
    });
  };
};

export const setDataDPCicilan = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_DATA_PEMBAYARAN_DP_CICILAN,
      payload: {
        data: data,
      },
    });
  };
};
