import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { hideModal } from "../../../actions/datamaster_action";
import { HiidenFiled, ReanderField } from "../../../components/helpers/field";
import ValidasiMasterSales from "../../../Validasi/ValidasiMasterSales.jsx";
import Loading from "react-fullscreen-loading";
import { currencyMask, lang } from "../../../components";

const maptostate = (state) => {
  if (state.datamaster.ShowDataModal !== null) {
    return {
      initialValues: {
        id: state.datamaster.ShowDataModal.id,
        kode_harga: state.datamaster.ShowDataModal.kode_harga,
        hrg_per_gram: state.datamaster.ShowDataModal.hrg_per_gram,
      },
    };
  }
};
class HeadParameterHargaBeli extends Component {
  state = {
    aktif: false,
  };
  componentDidMount() {
    setTimeout(() => {
      if (this.props.isEdit) {
        document.getElementById("kode_harga").focus();
      }
    }, 100);
  }
  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit}
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
        autoComplete="off"
      >
        {this.props.isEdit ? (
          <>
            <Field
              name="id"
              component={HiidenFiled}
              type="hidden"
              readOnly={this.props.isEdit}
            />
          </>
        ) : null}

        <div className="col-12">
          <Field
            tabIndex="1"
            id="kode_harga"
            name="kode_harga"
            component={ReanderField}
            type="text"
            label={"Kode Harga"}
            readOnly={this.props.isEdit}
            placeholder={"Masukkan Kode Harga"}
          />
        </div>

        <div className="col-12">
          <Field
            id="hrg_per_gram"
            name="hrg_per_gram"
            tabIndex="1"
            {...currencyMask}
            component={ReanderField}
            type="text"
            label="Harga Per Gram"
            onBlur={() => this.setState({ aktif: true })}
            placeholder={"Masukkan Harga Per Gram"}
          />
        </div>
        <div className="row">
          <div className="col-12 text-right">
            <button
              className="btn btn-white"
              onClick={() => this.props.dispatch(hideModal())}
              type="button"
              disabled={this.props.isLoading}
            >
              {lang("Batal")}
            </button>
            &nbsp;
            <button
              tabIndex="3"
              className={this.state.aktif ? "btn btn-primary" : "btn btn-white"}
              disabled={this.props.submitting}
              type="submit"
            >
              {this.props.isLoading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                  Menyimpan
                </>
              ) : (
                `${lang("Simpan Data")}`
              )}
            </button>
          </div>
        </div>
        {this.props.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </form>
    );
  }
}

HeadParameterHargaBeli = reduxForm({
  form: "ModalDataSales",
  enableReinitialize: true,
  validate: ValidasiMasterSales,
})(HeadParameterHargaBeli);
export default connect(maptostate, null)(HeadParameterHargaBeli);
