import React, { Component } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import {
  formatGram,
  getUserdata,  
} from "../../../../../components/helpers/function";
class LaporanAnalisisHargaBarangExel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let total_berat = 0;
    let total_harga_jual = 0;

    this.props.data.forEach((item, index) => {
      total_berat = total_berat + item.berat;
      total_harga_jual = total_harga_jual + item.harga_jual;
    });
    let jml = 7;

    return (
      <>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="btn btn-success btn-block"
          table="table-to-xls"
          filename="Laporan Barang Summary Rekap / Baki"
          sheet="Laporan Barang Summary Rekap / Baki"
          buttonText="Export Excel"
        />
        <table
          rowkey="kode_member"
          id="table-to-xls"
          style={{ display: "none" }}
        >
          <thead>
            <tr>
              <th colSpan={jml} style={{ textAlign: "center" }}>
                {" "}
                LAPORAN ANALISIS HARGA BARANG{" "}
              </th>
            </tr>

            <tr>
              <th colSpan={jml}> TOKO : {getUserdata().nama_toko} </th>
            </tr>
            <tr>
              <th colSpan={jml}> ALAMAT : {getUserdata().alamat_toko} </th>
            </tr>

            <tr>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                KODE
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                GROUP
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                JENIS
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                INTERN
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                NAMA BARANG
              </th>

              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                BERAT
              </th>

              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                HARGA JUAL
              </th>
            </tr>
          </thead>

          <tbody>
            {this.props.data?.map((item, index) => (
              <tr key={index}>
                <td style={{ textAlign: "center" }}>
                  {" "}
                  &nbsp;{item.kode_barcode}
                </td>
                <td style={{ textAlign: "right" }}>{item.kode_group}</td>
                <td style={{ textAlign: "right" }}>{item.kode_jenis}</td>
                <td style={{ textAlign: "right" }}>{item.kode_intern}</td>
                <td style={{ textAlign: "right" }}>{item.nama_barang}</td>
                <td style={{ textAlign: "right" }}>
                  {formatGram(item.berat, 3)}
                </td>
                <td style={{ textAlign: "right" }}>
                  &nbsp;{item.harga_jual?.toLocaleString("kr-ko") || 0}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                Total
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {this.props.data.length}{" "}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {}{" "}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {}
              </td>

              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {formatGram(total_berat, 3)}
              </td>

              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {total_harga_jual.toLocaleString("kr-ko")}
              </td>
            </tr>
          </tfoot>
        </table>
      </>
    );
  }
}
export default LaporanAnalisisHargaBarangExel;
