import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { createNumberMask } from "redux-form-input-masks";
import { ReanderField, ReanderSelect } from "../../../components/helpers/field";
import Loading from "react-fullscreen-loading";
import ValidasiMutasiUang from "../../../Validasi/MutasiUang/ValidasiMutasiUang";
import {
  getDataBanks,
  getParamterTransaksi,
} from "../../../actions/datamaster_action";
import {getData, getDataParams, lang } from "../../../components";

const currencyMask = createNumberMask({
  prefix: "Rp. ",
  suffix: " ,-",
  locale: "kr-KO",
});
const maptostate = (state) => {
  return {
    dataTransaksi: state.datamaster.getParamterTransaksi,
    dataBank: state.datamaster.getDataBanks,
  };
};
class HeadMutasiUangCash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      asal: "",
      tujuan: "",
      rekAsal: true,
      rekTujuan: true,
      DataRekening: [],
      DataRekeningTujuan: [],
    };
  }

  async getDataRekening(data) {
    try {
      let result = await getDataParams("rekenings/get-by-kode-bank", {
        kode_bank: data,
      });
      if (this.state.rekAsal === false) {
        this.setState({
          DataRekening: result.data,
        });
      }

      if (this.state.rekAsal === true) {
        this.props.change("rek_asal_mutasi_bank", "");
      }

      if (this.state.rekTujuan === false) {
        this.setState({
          DataRekeningTujuan: result.data,
        });
      }

      if (this.state.rekTujuan === true) {
        this.props.change("rek_tujuan_mutasi_bank", "");
      }
    } catch (error) {}
  }

  componentDidMount() {
    console.log(this.state.tujuan, "STATE_TUJUAN");
    console.log(this.state.asal, "STATE_ASAL");
    this.props.dispatch(getParamterTransaksi());
    this.props.dispatch(getDataBanks());
    getData("paratransaksi/get/all")
      .then((res) => {
        this.setState({
          parametertransaksi: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  setValueCash() {
    console.log("Masuk_set");
  }

  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit}
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <div className="row">
          <div className="col-lg-12">
            <Field
              id="asal_mutasi_bank"
              name="asal_mutasi_bank"
              component={ReanderSelect}
              options={[
                {
                  value: "CASH",
                  name: "CASH",
                },
                ...this.props.dataBank.map((list) => {
                  return {
                    name: list.nama_bank,
                    value: list.kode_bank,
                  };
                }),
              ].filter((list) =>
                this.state.tujuan === ""
                  ? list
                  : this.state.tujuan === "CASH"
                  ? list.value !== "CASH"
                  : list.value === "CASH"
              )}
              label="Asal"
              // onChange={(e) =>
              //   this.setState({
              //     asal: e,
              //   })
              // }

              onChange={(e) => {
                this.setState({
                  asal: e,
                });
                if (e !== "CASH") {
                  this.setState({
                    rekAsal: false,
                  });
                } else {
                  this.setState({
                    rekAsal: true,
                  });
                }

                setTimeout(() => {
                  this.getDataRekening(e);
                }, 100);
              }}
              placeholder={`${lang("Masukkan")} ${lang("kode Bank Asal")}`}
            />
          </div>
          <div className="col-lg-12">
            <Field
              id="rek_asal_mutasi_bank"
              name="rek_asal_mutasi_bank"
              component={ReanderSelect}
              disabled={this.state.rekAsal}
              options={this.state.DataRekening.filter(
                (list) => list.no_rekening !== undefined
              ).map((list) => {
                let data = {
                  value: list.no_rekening,
                  name: list.no_rekening + " - " + list.nama_rekening,
                };
                return data;
              })}
              label="Rekening Asal"
              onChange={(e) => {
                // if (e === "CASH") {
                //   this.setState({
                //     DataRekening: [],
                //   });
                // }
              }}
              placeholder={`${lang("Pilih")} ${lang("Rekening Asal")}`}
            />
          </div>

          <div className="col-lg-12">
            <Field
              id="tujuan_mutasi_bank"
              name="tujuan_mutasi_bank"
              component={ReanderSelect}
              options={[
                {
                  value: "CASH",
                  name: "CASH",
                },
                ...this.props.dataBank.map((list) => {
                  return {
                    name: list.nama_bank,
                    value: list.kode_bank,
                  };
                }),
              ].filter((list) =>
                this.state.asal === ""
                  ? list
                  : this.state.asal === "CASH"
                  ? list.value !== "CASH"
                  : list.value === "CASH"
              )}
              label="Tujuan"
              onChange={(e) => {
                this.setState({
                  tujuan: e,
                });
                if (e !== "CASH") {
                  this.setState({
                    rekTujuan: false,
                  });
                } else {
                  this.setState({
                    rekTujuan: true,
                  });
                }
                this.getDataRekening(e);
              }}
              placeholder={`${lang("Pilih")} ${lang("kode Bank Tujuan")}`}
            />
          </div>

          <div className="col-lg-12">
            <Field
              id="rek_tujuan_mutasi_bank"
              name="rek_tujuan_mutasi_bank"
              component={ReanderSelect}
              disabled={this.state.rekTujuan}
              options={this.state.DataRekeningTujuan.filter(
                (list) => list.no_rekening !== undefined
              ).map((list) => {
                let data = {
                  value: list.no_rekening,
                  name: list.no_rekening + " - " + list.nama_rekening,
                };
                return data;
              })}
              label="Rekening Tujuan"
              onChange={(e) => {}}
              placeholder={`${lang("Pilih")} ${lang("Rekening Tujuan")}`}
            />
          </div>

          <div className="col-lg-12">
            <Field
              name="jumlah_rp"
              component={ReanderField}
              type="telp"
              label="Jumlah Uang Rp."
              placeholder="Masukkan Jumlah"
              {...currencyMask}
            />
          </div>
          <div className="col-lg-12">
            <Field
              name="keterangan"
              component={ReanderField}
              type="text"
              label="Untuk Keperluan"
              placeholder="Masukkan Keterangan"
            />
          </div>

          <div className="col-lg-12">
            <Field
              name="kategori"
              component={ReanderSelect}
              options={this.props.dataTransaksi.map((list) => {
                let data = {
                  value: list.kode_transaksi,
                  name: list.kode_transaksi,
                };
                return data;
              })}
              label="Kategori"
              placeholder="Masukkan Kategori"
            />
          </div>
          <div className="col-lg-12">
            <div className="text-right">
              <button className="btn btn-primary">
                {this.props.isLoading ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                    Menyimpan
                  </>
                ) : (
                  <>
                    Simpan <i className="fa fa-paper-plane ml-3"></i>
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
        {this.props.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </form>
    );
  }
}

HeadMutasiUangCash = reduxForm({
  form: "HeadMutasiUangCash",
  enableReinitialize: true,
  validate: ValidasiMutasiUang,
})(HeadMutasiUangCash);
export default connect(maptostate)(HeadMutasiUangCash);
