import { getUangBanks } from "../../../actions/datamaster_action";
import {
  React,
  connect,
  reduxForm,
  PanelContent,
  // Tabel,
  showModal,
  ModalGlobal,
  ToastNotification,
  postDataNoTImeOut,
  hideModal,  
} from "../../../components/helpers/library";
import HeadMutasiUangCash from "./HeadMutasiUangCash";
const maptostate = (state) => {
  return {
    databank: state.datamaster.getUangBanks,
  };
};
class MutasiUangCash extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      columns: [
        {
          dataField: "kategori",
          text: "Kategori",
          footer: "",
          footerAttrs: {
            colSpan: "2",
          },
        },
        {
          dataField: "keterangan",
          text: "Keterangan",
          formatter: (cell, row) => {
            return <div className="text-left">{cell.toUpperCase()}</div>;
          },
        },

        {
          dataField: "jumlah_in",
          text: "Jumlah",
          headerClasses: "text-right",
          formatter: (cell, row) => {
            return (
              <div className="text-right">
                {" "}
                {row.jumlah_in === 0
                  ? row.jumlah_out.toLocaleString("kr-KO")
                  : row.jumlah_in.toLocaleString("kr-KO")}{" "}
              </div>
            );
          },
          footer: (cell) => {
            return (
              <div className="text-right">
                {" "}
                {cell
                  .reduce((a, b) => a + parseInt(b), 0)
                  .toLocaleString("kr-KO")}{" "}
              </div>
            );
          },
        },
      ],
    };
    this.simpanMutasiUangCash = this.simpanMutasiUangCash.bind(this);
  }

  simpanMutasiUangCash(data) {
    let data_post = {
      asal: data.asal_mutasi_bank ? data.asal_mutasi_bank : "CASH",
      asal_rekening: data.rek_asal_mutasi_bank,
      tujuan: data.tujuan_mutasi_bank ? data.tujuan_mutasi_bank : "CASH",
      tujuan_rekening: data.rek_tujuan_mutasi_bank,
      jumlah: parseInt(data.jumlah_rp || 0),
      keterangan: data.keterangan,
      kategori: data.kategori,
    };
    this.setState({
      isLoading: true,
    });
    console.log(data_post, "DATA_POST");
    postDataNoTImeOut("cash/mutasi-uang-cash", data_post, [], true)
      .then((res) => {
        this.props.dispatch(hideModal());
        ToastNotification("success", "Mutasi Uang Cash Berhasil Disimpan");
        this.setState({
          isLoading: false,
        });
        // this.props.dispatch(getUangBanks());
      })
      .catch((err) => {
        this.props.dispatch(hideModal());
        ToastNotification(
          "info",
          err?.response?.data || "Gagal Menambahkan Mutasi Cash"
        );
        this.setState({
          isLoading: false,
        });
      });
  }

  componentDidMount() {
    this.props.dispatch(getUangBanks());
  }
  showModal(row) {
    this.setState({
      form: row,
    });
    this.props.dispatch(showModal());
  }
  render() {
    return (
      <PanelContent menu="Mutasi Uang Cash">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => this.showModal("TAMBAH")}
        >
          Mutasi Uang Cash
        </button>

        <ModalGlobal
          size="P"
          title={"Mutasi Uang Cash"}
          content={
            <HeadMutasiUangCash
              onSubmit={(data) => this.simpanMutasiUangCash(data)}
            />
          }
        />
      </PanelContent>
    );
  }
}

MutasiUangCash = reduxForm({
  form: "MutasiUangCash",
  enableReinitialize: true,
})(MutasiUangCash);
export default connect(maptostate)(MutasiUangCash);
