import { ToastNotification, getData } from "../components/helpers/library";

export const PARAMATER_AKUN = "PARAMATER_AKUN";
export const DATA_AKUN = "DATA_AKUN";

export const getParameterAkun = () => {
  return async (dispatch) => {
    try {
      let akun = await getData("para-akun-transaksi");
      dispatch({
        type: PARAMATER_AKUN,
        payload: {
          data: akun.data,
        },
      });
    } catch (error) {
      ToastNotification(
        "info",
        error?.response?.data || "Terjadi Kesalahan Saat Mengirim Data"
      );
    }
  };
};
export const getNoAkun = () => {
  return async (dispatch) => {
    try {
      let akun = await getData("akuns");
      dispatch({
        type: DATA_AKUN,
        payload: {
          data: akun.data,
        },
      });
    } catch (error) {
      ToastNotification(
        "info",
        error?.response?.data || "Terjadi Kesalahan Saat Mengirim Data"
      );
    }
  };
};
