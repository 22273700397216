import {getDataNoTimeOut, postData} from "../../../../config/axios";
import {ToastNotification} from "../../../../components/helpers/library";
import {doLoading, stopLoading} from "../../../../actions/utility_action";
import {reset} from "redux-form";

export const SET_TERIMA_UANG_PUSAT = "SET_TERIMA_UANG_PUSAT";


export const getTerimaUangPusat = () => {    
    return async (dispatch, getState)=> {
        const state = getState();
        const selected = state.form.TerimaUangPusat.values;
        if(!selected){
            ToastNotification("error", "Mohon Masukkan Nomor Referensi")
            return false
        }
        const response = await getDataNoTimeOut("kirim-uang/"+ selected.no_reff);
        console.log(response.data.length ===0, "DATA_RESPONSE")
        if(response.data.length === 0){
            ToastNotification("error", "No Terima Tidak Ditemukan");
            dispatch(stopLoading())
        }else{
            dispatch({ 
                type : SET_TERIMA_UANG_PUSAT,        
                payload : response.data
            })
        }   
    }
}

export const sendTerimaUangPusat = () => {
    return async (dispatch, getState) => {
        dispatch(doLoading())
        const state = getState();
        const selected = state.form.TerimaUangPusat.values;
        try {
            await postData(`kirim-uang/${selected.no_reff}/reception`)
            ToastNotification("success",  "Terima Uang Berhasil")
            dispatch(stopLoading())
            dispatch(reset("TerimaUangPusat"))
            dispatch({
                type : SET_TERIMA_UANG_PUSAT,
                payload : []
            })
        } catch (e) {
            
            ToastNotification("error", "Terima Uang Pusat Gagal");
        dispatch(stopLoading())
        }
    }
}