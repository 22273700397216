import {
  PanelContent,
  useDispatch,
  React,
  simpanDataTmp,
  useEffect,
  removeItem,
} from "../../../../components/helpers/library";
import FilterLaporanKelompok from "./Form";
import { getReportKelompokBarang } from "./Redux";
import TabelLaporanKelompok from "./Tabel";

const LaporanKelompokBarang = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(simpanDataTmp([]));
    removeItem("laporanPenjualanSales");
    return () => {
      dispatch(simpanDataTmp([]));
      removeItem("laporanPenjualanSales");
    };
  }, [dispatch]);

  return (
    <PanelContent
      menu="Laporan Kelompok Barang"
      submenu="Laporan"
      link="/SubMenuLaporan"
    >
      <FilterLaporanKelompok
        onSubmit={(data) => dispatch(getReportKelompokBarang(data))}
      />
      <TabelLaporanKelompok />
    </PanelContent>
  );
};

export default LaporanKelompokBarang;
