import React from "react";

const FingerPrintComponent = ({
  statusFingerPrint, // Untuk menggerakan icon
  feedBackSocketFingerPirnt, // jika feedback socket berhasil maka akan mengubah warna
}) => {
  return (
    <div className="text-center" style={{ marginTop: 50, marginBottom: 50 }}>
      <i
        className={`fa-solid fa-fingerprint fa-lg ${
          statusFingerPrint ? "fa-bounce" : ""
        }`}
        style={{
          fontSize: "10em",
          color: `${feedBackSocketFingerPirnt ? "#218838" : "#000000"}`,
        }}
      ></i>
      <br />
      <br />
      <br />
      {feedBackSocketFingerPirnt
        ? "Fingerprint Berhasil Di Scan"
        : "Silahkan Scan Fingerprint"}
    </div>
  );
};

export default FingerPrintComponent;
