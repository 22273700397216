import React from "react";
import { connect, useDispatch } from "react-redux";
import { Field, reduxForm, reset } from "redux-form";
import {
  PanelContent,
  ToastNotification,
  inputGroup,
  isLoading,
  lang,
  putData,
} from "../../../components";
import { useState } from "react";

let GantiPassword = (props) => {
  const { handleSubmit } = props;
  const [passwordLama, setPasswordLama] = useState(true);
  const [passwordBaru, setPasswordBaru] = useState(true);
  const dispatch = useDispatch();
  const save = async (data) => {
    let hasil = {
      password: data.password_lama,
      new_password: data.password_baru,
    };

    try {
      dispatch(isLoading(true));
      await putData("users/change-password", hasil);
      ToastNotification("success", "Update password berhasil");
      dispatch(reset("GantiPassword"));
      dispatch(isLoading(false));
    } catch (error) {
      dispatch(isLoading(false));
      ToastNotification(
        "info",
        error?.response?.data || "Terjadi Kesalahan Saat update password"
      );
    }
  };

  return (
    <PanelContent menu="Ganti Password">
      <form onSubmit={handleSubmit(save)}>
        <div className="row">
          <div className="col-4">
            <Field
              name="password_lama"
              component={inputGroup}
              nouperCase
              label={lang("Password Lama")}
              placeholder={lang("Masukkan Password Lama")}
              textValue={
                passwordLama ? (
                  <i className="fa-regular fa-eye-slash"></i>
                ) : (
                  <i className="fa fa-eye"></i>
                )
              }
              enableenter
              customeCss={passwordLama ? "password-hide" : ""}
              type={"text"}
              btnAction={() => setPasswordLama(!passwordLama)}
            />
          </div>
          <div className="col-4">
            <Field
              name="password_baru"
              component={inputGroup}
              nouperCase
              label={lang("Password Baru")}
              placeholder={lang("Masukkan Password Baru")}
              textValue={
                passwordBaru ? (
                  <i className="fa-regular fa-eye-slash"></i>
                ) : (
                  <i className="fa fa-eye"></i>
                )
              }
              enableenter
              customeCss={passwordBaru ? "password-hide" : ""}
              type={"text"}
              btnAction={() => setPasswordBaru(!passwordBaru)}
            />
          </div>
          <div className="col-4 mt-4">
            <button className="btn btn-primary btn-block"> Simpan </button>
          </div>
        </div>
      </form>
    </PanelContent>
  );
};

// export default GantiPassword;
GantiPassword = reduxForm({
  form: "GantiPassword",
  enableReinitialize: true,
})(GantiPassword);
export default connect()(GantiPassword);
