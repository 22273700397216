import {
    ModalGlobal,
    Panel,
    PanelBody,
    PanelHeader,
    Tabel,
  } from "../../../components/helpers/library";
  import React from "react";
  import { Loading } from "react-fullscreen-loading";
  import { connect } from "react-redux";
  import { Link } from "react-router-dom";
  import {
    fetchKontakAdminKatalog,
    handleSubmitKontakAdminKatalog,
    setDeleteKontakAdminKatalog,
    setEditKontakAdminKatalog,
    setTambahKontakAdminKatalog,
  } from "./redux/kontakAdminKatalogAction";
  import FormKontakAdminKatalog from "./widgets/FormKontakAdminKatalog";
  
  class KontakAdminKatalog extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        columns: [
          {
            dataField: "kode_admin",
            text: "Kode Admin ",
            sort: true,
          },
          {
            dataField: "nama_admin",
            text: "Nama Admin",
          },
          {
            dataField: "no_hp",
            text: "Nomor Whatsapp",
          },
          {
            dataField: "action",
            text: "Action",
            csvExport: false,
            headerClasses: "text-center",
            formatter: (rowcontent, row) => {
              return (
                <div className="row text-center">
                  <div className="col-12">
                    <button
                      className="btn btn-primary mr-3"
                      onClick={() => {
                        this.props.dispatch(setEditKontakAdminKatalog(row));
                      }}
                    >
                      <i className="fa fa-edit"></i>
                    </button>
                    <button
                      className="btn btn-danger"
                      onClick={() => {
                        this.props.dispatch(setDeleteKontakAdminKatalog(row));
                      }}
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                  </div>
                </div>
              );
            },
          },
        ],
      };
    }
  
    componentDidMount() {
      this.props.dispatch(fetchKontakAdminKatalog());
    }
  
    render() {
      return (
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="#">Kontak Admin Katalog</Link>
            </li>
            <li className="breadcrumb-item active">Kontak Admin Katalog</li>
          </ol>
          <h1 className="page-header">Kontak Admin Katalog </h1>
          <Panel>
          {this.state.isLoading ? (
            <Loading
              loading
              background="rgba(0, 0, 0, 0.35)"
              loaderColor="rgba(94, 147, 117, 1)"
            />
          ) : null}
            <PanelHeader>Kontak Admin Katalog</PanelHeader>
            <br />
            <PanelBody>
              <Tabel
                keyField="kode_admin"
                tambahData={true}
                handleClick={() =>
                  this.props.dispatch(setTambahKontakAdminKatalog())
                }
                exportCsv={true}
                data={this.props.KontakAdminKatalog}
                columns={this.state.columns}
                empty={this.props.KontakAdminKatalog}
                textEmpty="Data Kontak Admin Kosong"
                fileNameCsv={`kategori-katalog`}
              />
            </PanelBody>
            <br />
          </Panel>
          <ModalGlobal
            size="P"
            title={
              this.state.isEdit
                ? "Edit Kontak Admin Katalog"
                : "Tambah Kontak Admin Katalog"
            }
            content={
              <FormKontakAdminKatalog
                isLoading={this.state.isLoading}
                isEdit={this.state.isEdit}
                onSubmit={(data) =>
                  this.props.dispatch(handleSubmitKontakAdminKatalog(data))
                }
              />
            }
          />
          
        </div>
      );
    }
  }
  
  export default connect((state) => {
    return {
      KontakAdminKatalog: state.stateKontakAdminKatalog.kontakAdminKatalog,
    };
  })(KontakAdminKatalog);
  