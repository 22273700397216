import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  getToday,
  getUserdata,
  getItem,
} from "../../../../../components/helpers/library";
const LaporanPenebusanDetail = async (data = "", tanggal) => {
  const doc = new jsPDF("l", "mm", [297, 210]);
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];
  // let footRows = [];
  let tableColumn = [];
  let finalY = 35;
  doc.setFontSize(15);
  //   doc.text("Laporan Penebusan Detail", 210, 15);
  let judulLaporan = doc.splitTextToSize("Laporan Penebusan Detail", 100);
  doc.text(judulLaporan, 220, 15);
  doc.text(getUserdata().nama_toko, 14, 15);
  doc.setFontSize(8);
  let jml_alamat = getUserdata().alamat_toko.length;
  if (jml_alamat > 20) {
    doc.text(getUserdata().alamat_toko.slice(0, 48), 14, 20);
  }
  if (jml_alamat > 50) {
    doc.text(getUserdata().alamat_toko.slice(48, 90), 14, 25);
  }
  doc.setFontSize(10);
  doc.setProperties({
    title: "Laporan Penebusan Detail",
  });
  doc.text(`Tanggal : ${tanggal.tgl_from} s/d ${tanggal.tgl_to}`, 220, 23);

  tableColumn = [
    [
      {
        content: `NO`,
      },
      {
        content: `NOTA`,
      },
      {
        content: `NAMA`,
      },
      {
        content: `ALAMAT`,
      },
      {
        content: `BARANG`,
      },
      {
        content: `BERAT`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `TGL GADAI`,
      },
      {
        content: `HARI`,
      },
      {
        content: `TEBUS`,
      },
      {
        content: `SALES`,
      },
      {
        content: `POKOK`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `R`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `CALC`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `R RIIL`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `BUNGA`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `TOTAL`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `DI BAYAR`,
        styles: {
          halign: "right",
        },
      },
    ],
  ];

  data.forEach((item, index) => {
    item.detail_barang.forEach((list, index2) => {
      // let bunga_pembualatan = customePembulatan(
      //   parseInt(item.bunga_rp || 1) * parseInt(item.lama_pinjam || 1),
      //   1000
      // );
      let rows = [
        index + 1,
        item.no_faktur_hutang,
        item.nama_customer,
        item.alamat_customer,
        list.nama_barang,
        {
          content: list.berat.toFixed(3),
          styles: {
            halign: "right",
          },
        },
        item.tgl_hutang,
        item.lama_pinjam || 0,
        item.tgl_lunas,
        item.kode_sales,
        {
          content: item.jumlah_hutang.toLocaleString("kr-ko"),
          styles: {
            halign: "right",
          },
        },
        {
          content: item.bunga.toFixed(2),
          styles: {
            halign: "right",
          },
        }, //R
        {
          content: Math.round(
            parseFloat(item.bunga_rp || 0) * parseFloat(item.lama_pinjam)
          ).toLocaleString("kr-ko"),
          styles: {
            halign: "right",
          },
        }, //Calc
        {
          content: (
            (parseInt(item.bunga_pinjam || 1) * 100 * 30) /
            parseInt(item.lama_pinjam || 1) /
            parseInt(item.jumlah_hutang || 1)
          ).toFixed(2),
          styles: {
            halign: "right",
          }, //eril
        },
        {
          // content: parseInt(bunga_pembualatan).toLocaleString("kr-ko"),
          //intruksi perubahan dari trelo #1892
          content: parseInt(item.bunga_pinjam).toLocaleString("kr-ko"),
          styles: {
            halign: "right",
          },
        },
        {
          content: (
            Math.round(
              parseFloat(item.bunga_rp || 0) * parseFloat(item.lama_pinjam)
            ) + parseInt(item.jumlah_hutang)
          ).toLocaleString("kr-ko"),
          styles: {
            halign: "right",
          },
        },
        {
          // content: parseInt(item.total_bayar).toLocaleString("kr-ko"),
          content: parseInt(item.total_bayar).toLocaleString("kr-ko"),
          styles: {
            halign: "right",
          },
        },
      ];

      tableRows.push(rows);
    });
  });

  let footer = [
    {
      content: data.length + " Faktur",
      colSpan: 6,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    },
    {
      content: `${data
        .map((list) =>
          list.detail_barang.reduce((a, b) => a + parseFloat(b.berat), 0)
        )
        .reduce((a, b) => a + parseInt(b), 0)
        .toFixed(3)}`,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    },
    {
      content: "",
      colSpan: 3,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    },
    {
      content: `${data
        .reduce((a, b) => a + parseFloat(b.jumlah_hutang), 0)
        .toLocaleString("Kr-Ko")}`,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    },
    {
      content: ``,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    },
    {
      content: `${data
        .reduce(
          (a, b) =>
            a +
            Math.round(parseFloat(b.bunga_rp || 0) * parseFloat(b.lama_pinjam)),
          0
        )
        .toLocaleString("kr-ko")}`,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    }, //eril

    {
      content: ``,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    },
    {
      // content: ` ${data
      //   .reduce(
      //     (a, b) =>
      //       a +
      //       customePembulatan(
      //         parseInt(b.bunga_rp || 1) * parseInt(b.lama_pinjam || 1),
      //         1000
      //       ),
      //     0
      //   )
      //   .toLocaleString("kr-ko")}`, intruksi perubahan dari trelo #1892

      content: ` ${data
        .reduce((a, b) => a + b.bunga_pinjam, 0)
        .toLocaleString("kr-ko")}`,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    },
    {
      content: `${data
        .reduce(
          (a, b) =>
            a +
            Math.round(
              parseFloat(b.bunga_rp || 0) * parseFloat(b.lama_pinjam)
            ) +
            parseInt(b.jumlah_hutang),
          0
        )
        .toLocaleString("kr-ko")}`,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    }, //Total
    {
      content: `${data
        .reduce((a, b) => a + parseInt(b.total_bayar), 0)
        .toLocaleString("kr-ko")}`,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    },
  ];
  tableRows.push(footer);
  let printed = [
    {
      colSpan: 6,
      content: `Printed By ${getItem("userdata").user_id} / ${getToday()}`,
      styles: {
        // lineWidth: 0.1,
        fontStyle: "italic",
        textColor: "#000",
        // fillColor: "#E8E5E5"
      },
    },
  ];
  tableRows.push(printed);
  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    // foot: footRows,
    startY: finalY,
    theme: "plain",
    rowPageBreak: "avoid",
    // pageBreak: "avoid",
    margin: { top: 10 },
    bodyStyles: {
      // lineWidth: 0.02,
      // lineColor: "#000",
      fontSize: 7,
    },
    headStyles: {
      fontSize: 8,
      // lineWidth: 0.02,
      // lineColor: "#000",
      textColor: "#000",
      fillColor: "#E8E5E5",
    },
  });
  finalY = doc.autoTableEndPosY() + 3;
  tableRows = [];

  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  var string = doc.output("datauristring");
  var x = window.open();
  x.document.open();
  x.document.write(
    "<html><head><title>Laporan Penebusan Detail</title></head><body style='margin:0 !important'><embed width='100%' height='100%'  src=" +
      string +
      "></embed></body></html>"
  );
};

export default LaporanPenebusanDetail;
