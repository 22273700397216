import React from 'react'
class CustomeInputEditFiled extends React.Component {
    static propTypes = {
    //   value: PropTypes.number,
    //   onUpdate: PropTypes.func.isRequired
    }
    static defaultProps = {
      value: 0
    }
    getValue() {
      // let new = this.range.value.replace("-","");

      return parseFloat(this.range.value.replace("-",""));
    }
  
    render() {
      const { value, onUpdate, ...rest } = this.props;
      return [
        <input
          { ...rest }
          ref={ node => this.range = node }
          type="number"
          className='form-control'
        />,
       
      ];
    }
  }
  
  export default CustomeInputEditFiled