import {
    GET_SPLASHSCREEN_KATALOG,
    SET_DATA_SPLASHSCREEN_KATALOG,
  } from "./splashscreenKatalogAction";
  
  const initialState = {
    splashscreenKatalog: [],
    ShowModal: null,
  };
  
  const stateSplashscreenKatalog = (state = initialState, actions) => {
    switch (actions.type) {
      case GET_SPLASHSCREEN_KATALOG:
        return {
          ...state,
          splashscreenKatalog: actions.payload
        };
      case SET_DATA_SPLASHSCREEN_KATALOG:
        return {
          ...state,
          ShowModal: actions.payload,
        };
      default:
        return state;
    }
  };
  export default stateSplashscreenKatalog;
  