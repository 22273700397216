import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import english from "./en.json";
import indonesia from "./id.json";
import italy from "./it.json";
import hk from "./hk.json";
import my from "./my.json";
import jp from "./jp.json";
import { getCookie } from "../components";
const storedLanguage = getCookie("language");
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false, // Matikan pesan debug saat dalam mode produksi
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    lng: storedLanguage || "id", // Bahasa awal (default)
    resources: {
      en: english,
      id: indonesia,
      it: italy,
      hk: hk,
      my: my,
      jp: jp,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
