import {
  Field,
  ReanderSelect,
  reduxForm,
  React,
  useDispatch,
  useEffect,
  useSelector,
  selectorMaster,
  findModule,
} from "../../../../../components/helpers/library";
import ValidasiLaporanPenjualanSales from "../../../../../Validasi/Penjualan/ValidasiLaporanPenjualanSales";
import { getDataFilter, gantiType, RenderJenis } from "../Redux";

let FilterLaporanKelompok = (props) => {
  const loading = useSelector(selectorMaster.loading);

  const datajenis = useSelector(selectorMaster.datajenis);

  const dispatch = useDispatch();
  const supplierBarang = findModule("SUPPLIER_BARANG_MODULE");

  useEffect(() => {
    dispatch(getDataFilter());
  }, [dispatch, supplierBarang]);
//   const datatmp = useSelector(selectorMaster.datatmp);

  return (
    <form
      onSubmit={props.handleSubmit}
      onKeyPress={(e) => {
        e.key === "Enter" && e.preventDefault();
      }}
      autoComplete="off"
    >
      <div className="row">
        <div className="col-3">
          <Field
            label="Jenis"
            name="kode_dept"
            options={RenderJenis(datajenis)}
            placeholder={"PILIH Kode Jenis"}
            component={ReanderSelect}
          />
        </div>
        <div className="col-lg-3">
          <Field
            name="type"
            component={ReanderSelect}
            options={[
              { value: "NAMA BARANG", name: "Nama Barang" },
              {
                value: "NAMA BARANG DAN SIZE",
                name: "Nama Barang Dan Size",
              },
            ]}
            onChange={(e) => dispatch(gantiType(e))}
            label="Berdasarkan"
            placeholder="Pilih Kriteria"
          />
        </div>

        <div className="col-lg-3 mb-4">
          <label>&nbsp;</label>
          <div className="text-right">
            <button
              type="submit"
              value="PDF"
              name="type_btn"
              className="btn btn-primary btn-block"
            >
              {loading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                  Mencari
                </>
              ) : (
                "Cari Laporan"
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

FilterLaporanKelompok = reduxForm({
  form: "FilterLaporanKelompok",
  enableReinitialize: true,
  validate: ValidasiLaporanPenjualanSales,
})(FilterLaporanKelompok);
export default FilterLaporanKelompok;
