import {
    GET_GROUP_DESCRIPTION_KATALOG,
    SET_DATA_GROUP_DESCRIPTION_KATALOG,
  } from "./groupDescriptionKatalogAction";
  
  const initialState = {
   groupDescription: [],
    ShowModal: null,
  };
  
  const stateGroupDescriptionKatalog = (state = initialState, actions) => {
    switch (actions.type) {
      case GET_GROUP_DESCRIPTION_KATALOG:
        return {
          ...state,
         groupDescription: actions.payload.data,
        };
      case SET_DATA_GROUP_DESCRIPTION_KATALOG:
        return {
          ...state,
          ShowModal: actions.payload,
        };
      default:
        return state;
    }
  };
  export default stateGroupDescriptionKatalog;
  