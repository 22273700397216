import {SET_LAPORAN_KIRIM_UANG_PUSAT} from "./laporan_kirim_uang_pusat_action";

const initialState = {
    listLaporanKirimUangPusat: []
}

const laporanKirimUangPusat = (state = initialState, action) => {
    switch (action.type) {
        case SET_LAPORAN_KIRIM_UANG_PUSAT:
            return {
                ...state,
                listLaporanKirimUangPusat: action.payload
            }
        default:
            return state
    }
}

export default laporanKirimUangPusat