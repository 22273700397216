import { hideModal, isLoading, showModal } from "../../../../actions/datamaster_action.jsx";
import { dataURLtoFile, makeID } from "../../../../components/helpers/function";
import {
  NotifSucces,
  ToastNotification,
} from "../../../../components/helpers/library";
import { NotifError } from "../../../../components/helpers/notification";
import {
  deleteData,
  deleteImageApp,
  getData,
  postData,
  postImageApp,
  putData,
} from "../../../../config/axios";
import Swal from "sweetalert2";

export const GET_ITEM_KATALOG = "GET_ITEM_KATALOG";
export const SET_DATA_ITEM_KATALOG = "SET_DATA_ITEM_KATALOG";
export const DELETE_ITEM_KATALOG = "DELETE_ITEM_KATALOG";
export const EDIT_ITEM_KATALOG = "EDIT_ITEM_KATALOG";
export const POST_ITEM_KATALOG = "POST_ITEM_KATALOG";

export const fetchItemKatalog = () => {
  return (dispatch) => {
    dispatch(isLoading(true));
    getData("app/item-katalog")
      .then((res) => {
        dispatch({
          type: GET_ITEM_KATALOG,
          payload: {
            data: res.data,
          },
        });
        dispatch(isLoading(false));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const setEditItemKatalog = (data) => {
  return async (dispatch, getState) => {
    dispatch(isLoading(true));
    let state = getState();
    const listBanner = state.stateBannerKatalog.bannerKatalog;
    const listInfo = state.stateInfoKatalog.infoKatalog;
    console.log(data.kode_info);
    let listBannerResult = data.kode_banner.map((e) => {
      return {
        value: e,
        label: listBanner.find((f) => f.kode_banner === e)?.title ?? "-",
      };
    });
    let listInfoResult = data.kode_info.map((e) => {
      return {
        value: e,
        label: listInfo.find((f) => f.kode_info === e)?.title ?? "-",
      };
    });
    data.kode_banner = listBannerResult;
    data.kode_info = listInfoResult;
    console.log(data);
    dispatch({
      type: SET_DATA_ITEM_KATALOG,
      payload: data,
    });
    dispatch(showModal());
    dispatch(isLoading(false));
  };
};

export const setDeleteItemKatalog = (data) => {
  return async (dispatch) => {
    Swal.fire({
      html:
        "Apakah Anda Yakin Ingin" +
        "Menghapus " +
        "<h1><b>Kode Item " +
        data.kode_barang +
        "</b> ?</h1>",
      icon: "warning",
      position: "top-center",
      cancelButtonText: "Tidak",
      showCancelButton: true,
      confirmButtonText: "OK",
      showConfirmButton: true,
    })
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(
            deleteItemKatalog(data.kode_barang, data?.gambar_name[0] ?? "-")
          );
        }
      })
      .catch((err) => {
        ToastNotification("error", err.response.data);
      });
  };
};

export const setTambahItemKatalog = (data) => {
  return async (dispatch) => {
    dispatch({
      type: SET_DATA_ITEM_KATALOG,
      payload: null,
    });
    dispatch(showModal());
  };
};

export const deleteItemKatalog = (kode_barang, gambar_name) => {
  return (dispatch) => {
    dispatch(isLoading(true));
    deleteData(`app/item-katalog/delete/${kode_barang}`)
      .then((res) => {
        NotifSucces("Data Berhasil Di Hapus")
          .then(() => deleteImageApp(`/ITEM/${gambar_name}`))
          .then(() => dispatch(fetchItemKatalog()))
          .then(() => dispatch(isLoading(false)));
      })
      .catch((err) => {
        console.log(err);
        dispatch(isLoading(false));
        NotifError("Gagal Menghapus Barang Katalog, Coba Beberapa Saat Lagi");
      });
  };
};

export const updateItemKatalog = (kode_item, data) => {
  return (dispatch) => {
    dispatch(isLoading(true));
    putData("app/item-katalog/edit/" + kode_item, data)
      .then(async (res) => {
        await NotifSucces("Data Berhasil Dirubah");
        dispatch(fetchItemKatalog());
        dispatch(hideModal());
        dispatch(isLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(isLoading(false));
        NotifError("Gagal Mengubah Barang Katalog, Coba Beberapa Saat Lagi");
      });
  };
};

export const sendItemKatalog = (data) => {
  return (dispatch) => {
    dispatch(isLoading(true));
    postData("app/item-katalog", data)
      .then(async () => {
        await NotifSucces("Data Berhasil Dirubah");
        dispatch(fetchItemKatalog());
        dispatch(hideModal());
        dispatch(isLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(isLoading(false));
        NotifError("Gagal Mengirim Barang Katalog, Coba Beberapa Saat Lagi");
      });
  };
};

export const handleSubmitItemKatalog = (data) => {
  return async (dispatch, getState) => {
    dispatch(isLoading(true));
    const state = getState();
    const current = state.stateItemKatalog.ShowModal;
    let listGambarUrl = [];
    let listGambarName = [];
    if (current) {
      if (data.gambar_path !== current.gambar_path) {
        await Promise.all(
          data.gambar_path.map(async (e, index) => {
            let url = await postImageApp(
              dataURLtoFile(e),
              `ITEM/${data.gambar_name[index]}`
            );
            listGambarUrl.push(url);
          })
        );
        data.gambar_path = listGambarUrl;
      }
      data.kode_banner = data.kode_banner?.map((data) => data.value) ?? [];
      data.kode_info = data.kode_info?.map((data) => data.value) ?? [];
      data.hashtag_kadar =
        data.hashtag_kadar !== undefined
          ? data.hashtag_kadar.replace(/#/g, "").trim().split(",")
          : [];
      data.hashtag_jenis =
        data.hashtag_jenis !== undefined
          ? data.hashtag_jenis.replace(/#/g, "").trim().split(",")
          : [];
      data.hashtag_lm =
        data.hashtag_lm !== undefined
          ? data.hashtag_lm.replace(/#/g, "").trim().split(",")
          : [];
      // data.harga_jual_str = data.harga_jual;
      // data.harga_jual = data.harga_jual;
      delete data.harga_jual_str
      var kodeBarang = data.kode_barang;
      delete data.kode_barang;
      dispatch(updateItemKatalog(kodeBarang, data));
      dispatch(isLoading(false));
    } else {
      if (data.gambar_path === undefined || data?.gambar_path?.length === 0) {
        NotifError("Gambar Wajib Dimasukan");
        dispatch(isLoading(false));
        return false;
      }
      await Promise.all(
        data.gambar_path.map(async (e, index) => {
          let gambar_name = makeID();
          let url = await postImageApp(dataURLtoFile(e), `ITEM/${gambar_name}`);
          listGambarUrl.push(url);
          listGambarName.push(gambar_name);
        })
      );
      data.kode_banner = data?.kode_banner?.map((data) => data.value) ?? [];
      data.kode_info = data?.kode_info?.map((data) => data.value) ?? [];
      data.gambar_path = listGambarUrl;
      data.gambar_name = listGambarName;
      data.hashtag_kadar =
        data.hashtag_kadar !== undefined
          ? data.hashtag_kadar.replace(/#/g, "").trim().split(",")
          : [];
      data.hashtag_jenis =
        data.hashtag_jenis !== undefined
          ? data.hashtag_jenis.replace(/#/g, "").trim().split(",")
          : [];
      data.hashtag_lm =
        data.hashtag_lm !== undefined
          ? data.hashtag_lm.replace(/#/g, "").trim().split(",")
          : [];
      // data.harga_jual_str = data.harga_jual;
      // data.harga_jual = data.harga_jual;
      delete data.harga_jual_str
      dispatch(isLoading(true));
      dispatch(sendItemKatalog(data));
    }
  };
};
