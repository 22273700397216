// import { hideModal, showModal } from "actions/datamaster_action";
import { hideModal, isLoading, showModal } from "../../../../actions/datamaster_action.jsx";
import { NotifSucces, ToastNotification } from "../../../../components/helpers/library";
import { NotifError } from "../../../../components/helpers/notification";
import { deleteData, getData, postData, putData } from "../../../../config/axios";
// import { setLoading, stopLoading } from "pages/Penjualan/TransaksiPenjualan/penjualan_action";
import Swal from "sweetalert2";

export const GET_HARGA_KATALOG = "GET_HARGA_KATALOG";
export const SET_DATA_HARGA_KATALOG = "SET_DATA_HARGA_KATALOG";
export const DELETE_HARGA_KATALOG = "DELETE_HARGA_KATALOG";
export const EDIT_HARGA_KATALOG = "EDIT_HARGA_KATALOG";
export const POST_HARGA_KATALOG = "POST_HARGA_KATALOG";

export const fetchHargaKatalog = () => {
  return (dispatch) => {
    dispatch(isLoading(true))
    getData("app/harga")
      .then((res) => {
        dispatch({
          type: GET_HARGA_KATALOG,
          payload: {
            data: res.data,
          },
        });
        dispatch(isLoading(false))
      })
      .catch((err) => {
        console.log(err);
        dispatch(isLoading(false))
      });
  };
};

export const setEditHargaKatalog = (data) => {
  return async (dispatch) => {
    dispatch({
      type: SET_DATA_HARGA_KATALOG,
      payload: data,
    });
    dispatch(showModal());
  };
};

export const setDeleteHargaKatalog = (data) => {
  return async (dispatch) => {
    Swal.fire({
      html:
        "Apakah Anda Yakin Ingin" +
        "Menghapus " +
        "<h1><b>Kode Hargan " +
        data.kode_harga_app +
        "</b> ?</h1>",
      icon: "warning",
      position: "top-center",
      cancelButtonText: "Tidak",
      showCancelButton: true,
      confirmButtonText: "OK",
      showConfirmButton: true,
    })
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(deleteHargaKatalog(data.kode_harga_app));
        }
      })
      .catch((err) => {
        ToastNotification("error", err.response.data);
      });
  };
};

export const setTambahHargaKatalog = (data) => {
  return async (dispatch) => {
    dispatch({
      type: SET_DATA_HARGA_KATALOG,
      payload: null,
    });
    dispatch(showModal());
  };
};

export const deleteHargaKatalog = (kode_harga) => {
  return (dispatch) => {
    dispatch(isLoading(true))
    deleteData("app/harga/" +kode_harga)
      .then((res) => {
        NotifSucces("Data Berhasil Di Hapus").then(() =>
          dispatch(fetchHargaKatalog())
        );
        dispatch(isLoading(true))
      })
      .catch((err) => {
        console.log(err);
        dispatch(isLoading(false))
        NotifError(
          "Gagal Menghapus Harga Katalog, Coba Beberapa Saat Lagi"
        );
      });
  };
};

export const updateHargaKatalog = (kode_harga, data) => {
  return (dispatch) => {
    dispatch(isLoading(true))
    putData("app/harga/" + kode_harga, data)
      .then(async (res) => {
        await NotifSucces("Data Berhasil Dirubah");
        dispatch(fetchHargaKatalog());
        dispatch(hideModal());
        dispatch(isLoading(false))
      })
      .catch((err) => {
        console.log(err);
        dispatch(isLoading(false))
        NotifError(
          "Gagal Merubah Harga Katalog, Coba Beberapa Saat Lagi"
        );
      });
  };
};

export const sendHargaKatalog = (data) => {
  return (dispatch) => {
    dispatch(isLoading(true))
    postData("app/harga", data)
      .then(async () => {
        await NotifSucces("Data Berhasil Ditambahkan");
        dispatch(fetchHargaKatalog());
        dispatch(hideModal());
        dispatch(isLoading(false))
      })
      .catch((err) => {
        console.log(err);
        dispatch(isLoading(false))
        NotifError(
          "Gagal Menambahkan Harga Katalog, Coba Beberapa Saat Lagi"
        );
      });
  };
};

export const handleSubmitHargaKatalog = (data) => {
  return async (dispatch, getState) => {
    const payload = {...data}
    const state = getState();
    const current = state.stateHargaKatalog.ShowModal;
    if(data.detail_group === undefined || data.detail_group === null){
      NotifError("Mohon Pilih List Group Minimal 1");
      return false;
    }
    if (current) {
      let listGroup = [];
      data.detail_group.forEach(element => {
        listGroup.push(element.value);
      });
      payload.detail_group = listGroup;
      dispatch(updateHargaKatalog( current.kode_harga_app, payload));
      dispatch(isLoading(false))
    } else {
      let listGroup = [];
      data.detail_group.forEach(element => {
        listGroup.push(element.value);
      });
      payload.detail_group = listGroup;
      dispatch(sendHargaKatalog(payload));
      dispatch(isLoading(false))
    }
  };
};
