import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  getToday,
  formatGram,
  getUserdata,
  getItem,
  isPos,
} from "../../../../../components/helpers/function";
// Date Fns is used to format the dates we receive
const CetakLaporanAnalisisHargaBarang = (data = "", type_laporan = "P") => {
  // initialize jsPDF
  const doc = new jsPDF(type_laporan === "p", "mm", [297, 210]);
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];
  let finalY = 30;
  let tableColumn = [];

  doc.setFontSize(15);
  // doc.text("LAPORAN BARANG TOKO", 215, 15);
  let judulLaporan = doc.splitTextToSize("LAPORAN ANALISA HARGA BARANG ", 130);
  doc.text(judulLaporan, 100, 15);
  doc.text(getUserdata().nama_toko, 14, 15);
  doc.setFontSize(8);
  let jml_alamat = getUserdata().alamat_toko.length;
  if (jml_alamat > 20) {
    doc.text(getUserdata().alamat_toko.slice(0, 48), 14, 20);
  }
  if (jml_alamat > 50) {
    doc.text(getUserdata().alamat_toko.slice(48, 90), 14, 25);
  }

  doc.setFontSize(10);
  doc.setProperties({
    title: "LAPORAN ANALISA HARGA BARANG",
  });

  tableColumn = [
    [
      {
        content: `KODE`,
      },
      {
        content: `GROUP`,
      },
      {
        content: `JENIS`,
      },
      {
        content: `INTERN`,
      },
      {
        content: `NAMA BARANG`,
      },
      {
        content: `BERAT`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `HARGA JUAL`,
        styles: {
          halign: "right",
        },
      },
    ],
  ];

  let total_berat = 0;
  let total_harga_juala = 0;

  console.log(data.length, "JUMLAH DATA");

  data.forEach((item, index) => {
    let rows = "";

    rows = [
      // item._id.kode_toko, //Baki
      {
        content: item.kode_barcode,
        styles: {
          halign: "left",
        },
      },
      {
        content: item.kode_group,
        styles: {
          halign: "right",
        },
      },
      {
        content: item.kode_dept,
        styles: {
          halign: "right",
        },
      },
      {
        content: item.kode_intern,
        styles: {
          halign: "right",
        },
      },
      {
        content: item.nama_barang,
        styles: {
          halign: "right",
        },
      },
      {
        content: formatGram(item.berat, 3),
        styles: {
          halign: "right",
        },
      },
      {
        content: item?.harga_jual?.toLocaleString("Kr-ko"),
        styles: {
          halign: "right",
        },
      },
    ];

    tableRows.push(rows);

    total_berat = total_berat + parseInt(item.berat || 0);
    total_harga_juala = total_harga_juala + item.harga_jual;
  });
  // no = 1;
  let footer = "";
  footer = [
    {
      content: `Total`,
      styles: {
        textColor: "#000",
        fillColor: "#E8E5E5",
        halign: "right",
      },
    },
    {
      content: data.length,
      styles: {
        textColor: "#000",
        fillColor: "#E8E5E5",
        halign: "right",
      },
    },

    {
      content: "",
      styles: {
        textColor: "#000",
        fillColor: "#E8E5E5",
        halign: "right",
      },
    },
    {
      content: "",
      styles: {
        textColor: "#000",
        fillColor: "#E8E5E5",
        halign: "right",
      },
    },

    {
      content: "",
      styles: {
        textColor: "#000",
        fillColor: "#E8E5E5",
        halign: "right",
      },
    },
    {
      content: `${formatGram(total_berat, 3)}`,
      styles: {
        textColor: "#000",
        fillColor: "#E8E5E5",
        halign: "right",
      },
    },
    {
      content: `${total_harga_juala.toLocaleString("kr-ko")}`,
      styles: {
        textColor: "#000",
        fillColor: "#E8E5E5",
        halign: "right",
      },
    },
  ];

  tableRows.push(footer);

  let printed = [
    {
      content: `Printed By ${getItem("userdata").user_id} / ${getToday()}`,
      styles: {
        // lineWidth: 0.1,
        fontStyle: "italic",
        textColor: "#000",
        // fillColor: "#E8E5E5"
      },
    },
  ];
  tableRows.push(printed);

  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    // foot: footRows,
    startY: finalY,
    theme: "plain",
    rowPageBreak: "avoid",
    // pageBreak: "avoid",
    margin: { top: 15 },
    footStyles: {
      // lineWidth: 0.02,
      // lineColor: "#000",
      fontSize: 8,
    },
    bodyStyles: {
      // lineWidth: 0.02,
      lineColor: "#000",
      fontSize: 8,
    },
    headStyles: {
      fontSize: 8,
      borderTop: "#000",
      // lineColor: [0,0,0,0],
      // lineWidth: 0.1,
      // lineColor: "#000",
      textColor: "#000",
      fillColor: "#E8E5E5",
    },

    tableLineColor: [255, 255, 255],
  });
  finalY = doc.autoTableEndPosY() + 3;
  tableRows = [];
  // const date = Date().split(" ");
  // we use a date string to generate our filename.
  // const dateStr = date[2] + date[3] + date[4];
  // ticket title. and margin-top + margin-left

  // doc.text(
  //   `User                        : ${getItem("userdata").user_id
  //   }`,
  //   14,
  //   finalY + 10
  // );
  // doc.text(`Cetak                      : ${getToday()}`, 14, finalY + 16);
  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  // doc.autoPrint();
  // doc.save(`Laporan BARANG SUMARY.pdf`);
  var string = doc.output("datauristring");
  if (isPos()) {
    // eslint-disable-next-line no-undef
    PrintPenjualan.postMessage(string);
    return false;
  }
  // var embed = "<embed width='100%' height='100%' src='" + string + "'/>";
  var x = window.open();
  x.document.open();
  x.document.write(
    "<html><head><title>LAPORAN BARANG TOKO</title></head><body style='margin:0 !important'><embed width='100%' height='100%'  src=" +
      string +
      "></embed></body></html>"
  );
  // x.document.write(embed);
  // setInterval(() => {
  //   x.close();
  // }, 1000);
};

export default CetakLaporanAnalisisHargaBarang;
