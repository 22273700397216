import {
  Field,
  InputDate,
  React,
  useState,
  reduxForm,
  Button,
  useEffect,
  ReanderSelect,
  change,
  useDispatch,
  ReanderField,
  getTglSystem,
} from "../../../../../components/helpers/library";

let FormLaporanPenebusan = (props) => {
  const [tgl_awal, setTglAwal] = useState(new Date());
  const [tgl_akhir, setTglAkhir] = useState(new Date());
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(change("FormLaporanPenebusan", "tgl_awal", getTglSystem()));
    dispatch(change("FormLaporanPenebusan", "tgl_akhir", getTglSystem()));
    dispatch(change("FormLaporanPenebusan", "berdasarkan", "tgl_hutang"));
  }, [dispatch]);
  return (
    <form onSubmit={props.handleSubmit} autoComplete="off">
      <div className="row">
        <div className="col-lg-3">
          <Field
            name="tgl_awal"
            component={InputDate}
            label="Tanggal Dari"
            type="text"
            selected={tgl_awal}
            onChange={(date) => setTglAwal(new Date(date))}
            placeholder="Masukkan Tanggal Dari"
          />
        </div>

        <div className="col-lg-3">
          <Field
            name="tgl_akhir"
            component={InputDate}
            type="text"
            selected={tgl_akhir}
            onChange={(date) => setTglAkhir(new Date(date))}
            label="Tanggal Akhir"
            placeholder="Masukkan Tanggal Akhir"
          />
        </div>

        <div className="col-lg-3">
          <Field
            name="no_pinjam"
            component={ReanderField}
            type="text"
            label="No Pinjam"
            placeholder="Masukkan No Pinjam"
          />
        </div>

        <div className="col-lg-3">
          <Field
            name="berdasarkan"
            component={ReanderSelect}
            type="text"
            options={[
              {
                value: "tgl_hutang",
                name: "TANGGAL MASUK",
              },
              {
                value: "tgl_lunas",
                name: "TANGGAL LUNAS",
              },
              {
                value: "no_faktur_hutang",
                name: "FAKTUR PINJAM",
              },
            ]}
            label="Berdasarkan"
            placeholder="Masukkan Berdasarkan"
          />
        </div>
        {/* <div className="col-lg-3">
          <Field
            name="type"
            component={ReanderSelect}
            type="text"
            options={[
              {
                value: "REKAP",
                name: "REKAP"
              },
              {
                value: "DETAIL",
                name: "DETAIL"
              },
            ]}
            label="Type Pinjaman"
            placeholder="Masukkan Type Pinjaman"
          />
        </div> */}

        <div className="col-lg-3 mt-4">
          <Button
            title="Cari Data"
            color="primary"
            loading
            textLoading="Cari Data"
            block
          />
        </div>
      </div>
    </form>
  );
};

FormLaporanPenebusan = reduxForm({
  form: "FormLaporanPenebusan",
  enableReinitialize: true,
})(FormLaporanPenebusan);
export default FormLaporanPenebusan;
