import i18n from "i18next";
import { capitalize } from "./function";

export function lang(text, uppercase) {
  let newtext = uppercase ? capitalize(text.toLowerCase()) : text;
  let result = i18n.t(newtext);
  return uppercase ? result.toUpperCase() : result;
}

export default lang;
