import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  formatGram,
  getToday,
  getUserdata,
  getItem,
  isPos,
} from "../../../../../components/helpers/library";

const ExportLaporanKelompokBarangSize = (data = "") => {
  const doc = new jsPDF("p", "mm", [297, 210]);
  let tableRows = [];
  let tableColumn = [];
  let finalY = 35;

  doc.setFontSize(12);
  let judulLaporan = doc.splitTextToSize(
    "LAPORAN KElOMPOK NAMA BARANG DAN SIZE",
    100
  );
  doc.text(judulLaporan, 100, 15);
  doc.text(getUserdata().nama_toko, 14, 15);
  doc.setFontSize(8);
  let jml_alamat = getUserdata().alamat_toko.length;
  if (jml_alamat > 20) {
    doc.text(getUserdata().alamat_toko.slice(0, 48), 14, 20);
  }
  if (jml_alamat > 50) {
    doc.text(getUserdata().alamat_toko.slice(48, 90), 14, 25);
  }
  doc.setFontSize(10);
  doc.setProperties({
    title: "LAPORAN KElOMPOK NAMA BARANG DAN SIZE",
  });

  tableColumn = [
    [
      {
        content: `NAMA BARANG`,
      },
      {
        content: `SIZE`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `BERAT`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `JUMLAH`,
        styles: {
          halign: "right",
        },
      },
    ],
  ];

  tableColumn[0].join();

  data.forEach((rows, index) => {
    console.log(rows.nama_barang, "DATA_ROWS");
    let kode_sales = [
      {
        content: `NAMA BARANG : ${rows.nama_barang}`,
      },
    ];
    tableRows.push(kode_sales);
    rows.detail.forEach((item, index) => {
      let rows = [
        "",
        {
          content: item.size,
          styles: {
            halign: "right",
          },
        },
        {
          content: formatGram(parseFloat(item.berat), 3),
          styles: {
            halign: "right",
          },
        },
        {
          content: item.jumlah,
          styles: {
            halign: "right",
          },
        },
      ];
      tableRows.push(rows);
    });
    let total = [
      {
        content: `Sub Total : `,
        colSpan: 2,
        styles: {
          halign: "center",
          fillColor: "#E8E5E5",
          textColor: "#000",
        },
      },
      {
        content: `${rows.detail
          .reduce((a, b) => a + parseFloat(b.berat), 0)
          .toFixed(3)}`,
        styles: {
          halign: "right",
          fillColor: "#E8E5E5",
          textColor: "#000",
        },
      },
      {
        content: `${rows.detail.reduce((a, b) => a + parseFloat(b.jumlah), 0)}`,
        styles: {
          halign: "right",
          fillColor: "#E8E5E5",
          textColor: "#000",
        },
      },
     
    ];
    tableRows.push(total);
  });



  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    startY: finalY,
    margin: { top: 8 },
    bodyStyles: {
      fontSize: 7,
    },
    headStyles: {
      fontSize: 7,
      fillColor: "#E8E5E5",
      textColor: "#000",
    },
    tableLineColor: [255, 255, 255],
  });
  tableRows = [];
  tableColumn = [];
  finalY = doc.autoTableEndPosY() + 20;

  let printed = [
    {
      content: `Printed By ${getItem("userdata").user_id} / ${getToday()}`,
      colSpan: 12,
      styles: {
        fontStyle: "italic",
        textColor: "#000",
      },
    },
  ];
  tableRows.push(printed);

  finalY = doc.autoTableEndPosY() + 3;
  tableRows = [];
  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  var string = doc.output("datauristring");
  if (isPos()) {
    // eslint-disable-next-line no-undef
    PrintPenjualan.postMessage(string);
    return false;
  }
  var x = window.open();
  x.document.open();
  x.document.write(
    "<html><head><title>LAPORAN PENJUALAN SALES</title></head><body style='margin:0 !important'><embed width='100%' height='100%'  src=" +
      string +
      "></embed></body></html>"
  );
};

export default ExportLaporanKelompokBarangSize;
