import { isLoading, simpanDataTmp, getDataJenis } from "../../../../../actions";
import {
  findModule,
  change,
  setItem,
  ToastNotification,
  getDataParams,
  removeItem,
} from "../../../../../components/helpers/library";
import ExportLaporanKelompokBarang from "../Report/ExportLaporanKelompoBarang";
import ExportLaporanKelompokBarangSize from "../Report/ExportLaporanKelompoBarangSize";
const moduleValidasiInputBy = findModule("VALIDASI_INPUT_BY_KEUANGAN_MODULE");

const RenderJenis = (GetJenis) => {
  let dataJenis = [
    {
      value: "ALL",
      name: "SEMUA",
    },
  ];
  GetJenis.forEach((list) => {
    let row = {
      name: list.kode_dept,
      value: list.kode_dept,
    };

    dataJenis.push(row);
  });

  return dataJenis;
};

const gantiType = (data) => {
  console.log(data, "DATA_CHANGE");
  return async (dispatch, getState) => {
    let hasil = {
      type: data,
      databarang: [],
    };

    dispatch(simpanDataTmp(hasil));
    setItem("laporanPenjualanSales", []);
  };
};

const cetakLaoranPdf = (data) => {
    
  return async (dispatch, getState) => {
    const state = getState();
    console.log(state.datamaster.getDataTmp.databarang, "DATATATAT")
    if (state.datamaster.getDataTmp.type === "NAMA BARANG DAN SIZE") {
      ExportLaporanKelompokBarangSize(state.datamaster.getDataTmp.databarang);
    } else {
      ExportLaporanKelompokBarang(state.datamaster.getDataTmp.databarang);
    }
  };
};

const getDataFilter = (data) => {
  return async (dispatch, getState) => {
    dispatch(change("FilterLaporanKelompok", "kode_dept", "ALL"));
    dispatch(getDataJenis());
  };
};

const getReportKelompokBarang = (data_post) => {
  return async (dispatch, getState) => {
    try {
      let params = {
        kode_dept: data_post.kode_dept,
        group_by: data_post.type,
      };
      let result = await getDataParams("reportbarang/kelompok-barang", params);
      if (result) {
        setTimeout(() => {
          if (result.data.length > 0) {
            // laporandata = result.data;
            ToastNotification("success", "Laporan Kelompok barang Tersedia");
            setItem("laporanPenjualanSales", []);
            let brg = [];
            console.log(result, "DATA_RESULT");
            console.log(result.data, "DATA_RESULT_DATA");
            if (data_post.type === "NAMA BARANG DAN SIZE") {
              result.data.forEach((el) => {
                el.detail.forEach((e) => {
                  let hasil = {
                    berat: e.berat,
                    jumlah: e.jumlah,
                    size: e.size,
                    nama_barang: el.nama_barang,
                  };
                  brg.push(hasil);
                });
              });
            } else {
              result.data.forEach((el) => {
                let hasil = {
                  berat: el.berat,
                  jumlah: el.jumlah,
                  nama_barang: el.nama_barang,
                };
                brg.push(hasil);
              });
            }
            setItem("laporanPenjualanSales", brg);
            setItem("laporanPenjualanSalesExcelPerSupplier", result.data);
            let hasil2 = {
              type: data_post.type,
              databarang: result.data,
            };
            dispatch(simpanDataTmp(hasil2));
            dispatch(isLoading(false));
          } else {
            removeItem("laporanPenjualanSales");
            dispatch(simpanDataTmp([]));
            ToastNotification("info", "Laporan Penjualan Tidak Tersedia");
            dispatch(isLoading(false));
          }
        }, 500);
      }
    } catch (error) {
      removeItem("laporanPenjualanSales");
      dispatch(simpanDataTmp([]));

      ToastNotification(
        "info",
        error?.response?.data || "Laporan Penjualan Tidak Tersedia"
      );
      dispatch(isLoading(false));
    }
  };
};

export {
  getDataFilter,
  moduleValidasiInputBy,
  getReportKelompokBarang,
  gantiType,
  cetakLaoranPdf,
  RenderJenis,
};
