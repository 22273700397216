import { DATA_AKUN, PARAMATER_AKUN } from "../actions";

const initialState = {
  dataparameter: [],
  noakun: [],
};

const parameter = (state = initialState, actions) => {
  switch (actions.type) {
    case PARAMATER_AKUN:
      return {
        ...state,
        dataparameter: actions.payload.data,
      };
    case DATA_AKUN:
      return {
        ...state,
        noakun: actions.payload.data,
      };

    default:
      return state;
  }
};

export default parameter;
