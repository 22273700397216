import React from "react";
import {
  PanelContent,
  selectorUtility,
  useSelector,
} from "../../../components/helpers/library";
import { TabelDetailAkun, TabelParameter } from "./tabel";

const ParameterAkun = () => {
  const isEdit = useSelector(selectorUtility.isEdit);
  return (
    <PanelContent menu="Akuntansi" submenu="Parameter Akun">
      {isEdit ? <TabelDetailAkun /> : <TabelParameter />}
    </PanelContent>
  );
};

export default ParameterAkun;
