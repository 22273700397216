import { getNoAkun } from "../../../../actions";
import { ReanderSelect } from "../../../../components/helpers/field";
import {
  connect,
  getItem,
  useDispatch,
  useSelector,
} from "../../../../components/helpers/library";
import React, { useEffect } from "react";
import { Field, change, reduxForm } from "redux-form";
import { selectorParameter } from "../../../../selector/selectorParameter";

let FormAkun = (props) => {
  let { value, onUpdate } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNoAkun());
    dispatch(change("FormAkun", "nomor_akun", value));
    console.log(value);
  }, [dispatch, value]);

  const noakun = useSelector(selectorParameter.noakun);
  return (
    <div className="row">
      <div className="col-12">
        <Field
          name="nomor_akun"
          component={ReanderSelect}
          options={noakun.map((list) => {
            return {
              value: list.nomor_akun,
              name: list.nomor_akun + " - " + list.nama_akun,
            };
          })}
          label="Nomor Akun"
          placeholder="Masukkan Nomor Akun"
          onChange={(e) => onUpdate(e)}
        />
      </div>
    </div>
  );
};

const maptostate = (state) => {
  return {
    initialValues: {
      jenis_akun: getItem("data_akun").jenis_akun,
      nomor_akun: getItem("data_akun").nomor_akun,
    },
  };
};

FormAkun = reduxForm({
  form: "FormAkun",
  enableReinitialize: true,
})(FormAkun);
export default connect(maptostate, null)(FormAkun);
