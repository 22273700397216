import React, { Component } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import {
  getItem,
  getUserdata,
} from "../../../../../components/helpers/function";
class LaporanBatalTitipExel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let dp_rp = 0;

    this.props.data.forEach((item, index) => {
      dp_rp = dp_rp + item.dp_rp;
    });
    return (
      <>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="btn btn-success btn-block"
          table="table-to-xls"
          filename="Laporan Batal Titip"
          sheet="Laporan Batal Titip"
          buttonText="Export Excel"
        />
        <table
          rowkey="kode_member"
          id="table-to-xls"
          style={{ display: "none" }}
        >
          <thead>
            <tr>
              <th colSpan="6"> Laporan Batal Titip </th>
            </tr>
            <tr>
              <th colSpan="6"> Periode </th>
            </tr>
            <tr>
              <th colSpan="6">
                {" "}
                {getItem("tgl_laporan").length === 0
                  ? null
                  : getItem("tgl_laporan").tgl_from +
                    "s/d" +
                    getItem("tgl_laporan").tgl_to}{" "}
              </th>
            </tr>
            <tr>
              <th colSpan="6"> TOKO : {getUserdata().nama_toko} </th>
            </tr>

            <tr>
              <th colSpan="6"> ALAMAT : {getUserdata().alamat_toko} </th>
            </tr>
            <tr>
              <th colSpan="6"></th>
            </tr>
            <tr>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                NAMA BARANG
              </th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                BERAT
              </th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                HARGGA JUAL
              </th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                ONGKOS
              </th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                SUBTOTAL
              </th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                DP RP
              </th>
            </tr>
          </thead>
          <tbody>
            {this.props.data.map((row, index) => (
              <>
                <tr>
                  <td style={{ textAlign: "right" }}> {row.nama_barang}</td>
                  <td style={{ textAlign: "right" }}>
                    {" "}
                    {row.berat.toFixed(3)}
                  </td>
                  <td style={{ textAlign: "right" }}> {row.harga_jual}</td>
                  <td style={{ textAlign: "right" }}>
                    {" "}
                    {row.ongkos.toLocaleString("kr-KO")}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {" "}
                    {row.harga_total.toLocaleString("kr-KO")}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {" "}
                    {row?.dp_rp?.toLocaleString("kr-KO")}
                  </td>
                </tr>
              </>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th
                style={{ backgroundColor: "#E8E5E5", color: "#000" }}
                colSpan="1"
              >
                {" "}
                Grand Total{" "}
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                &nbsp;
                {this.props.data
                  .reduce((a, b) => a + parseFloat(b.berat), 0)
                  .toFixed(3)}
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                &nbsp;
                {this.props.data
                  .reduce((a, b) => a + b.harga_jual, 0)
                  .toLocaleString("kr-KO")}
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                &nbsp;
                {this.props.data
                  .reduce((a, b) => a + b.ongkos, 0)
                  .toLocaleString("kr-KO")}
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                &nbsp;
                {this.props.data
                  .reduce((a, b) => a + b.harga_total, 0)
                  .toLocaleString("kr-KO")}
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                &nbsp;{dp_rp.toLocaleString("kr-KO")}
              </th>
            </tr>
          </tfoot>
        </table>
      </>
    );
  }
}
export default LaporanBatalTitipExel;
