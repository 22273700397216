import {
    GET_KONTAK_ADMIN_KATALOG,
    SET_DATA_KONTAK_ADMIN_KATALOG,
  } from "./kontakAdminKatalogAction";
  
  const initialState = {
    kontakAdminKatalog: [],
    ShowModal: null,
  };
  
  const stateKontakAdminKatalog = (state = initialState, actions) => {
    switch (actions.type) {
      case GET_KONTAK_ADMIN_KATALOG:
        return {
          ...state,
          kontakAdminKatalog: actions.payload.data,
        };
      case SET_DATA_KONTAK_ADMIN_KATALOG:
        return {
          ...state,
          ShowModal: actions.payload,
        };
      default:
        return state;
    }
  };
  export default stateKontakAdminKatalog;
  