import { findModule, validateDateRange } from "../components/helpers/function";

const ValidasiTanggalLaporan = (value, event) => {
  const errors = {};
  // console.log(event.charCode==13);
  let moduleProteksiqBulan = findModule("REPORT_SATU_BULAN_PROTECTION_MODULE");
  if (!value.tgl_awal) {
    errors.tgl_awal = "Tanggal Awal Tidak Boleh Kosong";
  }
  if (!value.tgl_akhir) {
    errors.tgl_akhir = "Tanggal Akhir Tidak Boleh Kosong";
  }

  // console.log(value.tgl_awal);
  if (moduleProteksiqBulan) {
    if (value.tgl_awal && value.tgl_akhir) {
      const isDateRangeValid = validateDateRange(
        value.tgl_awal,
        value.tgl_akhir
      );
      if (!isDateRangeValid.status) {
        errors.tgl_awal = isDateRangeValid.pesan;
        errors.tgl_akhir = isDateRangeValid.pesan;
      }
    }
  }

  if (!value.filter) {
    errors.filter = "Filter Tidak Boleh Kosong";
  }
  if (!value.sort_by) {
    errors.sort_by = "Sort By Tidak Boleh Kosong";
  }
  return errors;
};

export default ValidasiTanggalLaporan;
