const ListTokoMacan = [
  {
    kode_toko: "MC1",
    nama_toko: "Pusat",
  },
  {
    kode_toko: "MC2",
    nama_toko: "CABANG 1",
  },
  {
    kode_toko: "MC3",
    nama_toko: "CABANG 2",
  },

  {
    kode_toko: "MCO",
    nama_toko: "ONLINE",
  },
];

const ListTokoUtami = [
  {
    kode_toko: "UT1",
    nama_toko: "UT1",
  },
  {
    kode_toko: "UT2",
    nama_toko: "UT2",
  },
  {
    kode_toko: "UT3",
    nama_toko: "UT3",
  },
  {
    kode_toko: "UT4",
    nama_toko: "UT4",
  },
  {
    kode_toko: "UT5",
    nama_toko: "UT5",
  },
  {
    kode_toko: "UT6",
    nama_toko: "UT6",
  },
  {
    kode_toko: "UT7",
    nama_toko: "UT7",
  },
];
const ListKodeTokoDamay = [
  {
    kode_toko: "DC1",
    nama_toko: "DAMAI 1",
  },
  {
    kode_toko: "DC2",
    nama_toko: "DAMAI 2",
  },
  {
    kode_toko: "DC3",
    nama_toko: "DAMAI 3",
  },
  {
    kode_toko: "DCI",
    nama_toko: "DAMAI 1",
  },
  {
    kode_toko: "SMC3",
    nama_toko: "DAMAI 2",
  },
];
const LisKodeTokoArjuna = [
  {
    kode_toko: "RJN",
    nama_toko: "ARJUNA",
  },
  {
    kode_toko: "RJ2",
    nama_toko: "ARJUNA",
  },
];
const LisKodeTokoDewiGold = [
  {
    kode_toko: "DG1",
    nama_toko: "DEWI GOLD 1",
  },
  {
    kode_toko: "DG2",
    nama_toko: "DEWI GOLD 2",
  },
  {
    kode_toko: "DG3",
    nama_toko: "DEWI GOLD 3",
  },
  // {
  //   kode_toko: "VDC",
  //   nama_toko: "DEWI GOLD 3",
  // },
];
const LisKodeTokoDuaNaga = [
  {
    kode_toko: "2NG",
    nama_toko: "DUA NAGA",
  },
];
const ListKodeTokoPantes = [
  {
    kode_toko: "PPS",
    nama_toko: "Pusat",
  },
  {
    kode_toko: "PPR",
    nama_toko: "Perum",
  },
  {
    kode_toko: "PKG",
    nama_toko: "Kanggraksan",
  },

  {
    kode_toko: "VDC",
    nama_toko: "VDC",
  },
  {
    kode_toko: "CSB",
    nama_toko: "CSB",
  },
  {
    kode_toko: "GCM",
    nama_toko: "GCM",
  },
  {
    kode_toko: "GMC",
    nama_toko: "GMC",
  },
  {
    kode_toko: "PGC",
    nama_toko: "PGC",
  },
  {
    kode_toko: "KNG",
    nama_toko: "KNG",
  },
  {
    kode_toko: "KUN",
    nama_toko: "KUN",
  },
  {
    kode_toko: "SDL",
    nama_toko: "SDL",
  },
  {
    kode_toko: "KGR",
    nama_toko: "KGR",
  },
  {
    kode_toko: "PRM",
    nama_toko: "PRM",
  },
  {
    kode_toko: "KTG",
    nama_toko: "KTG",
  },
  {
    kode_toko: "JTB",
    nama_toko: "JTB",
  },
  {
    kode_toko: "GTS",
    nama_toko: "GTS",
  },
  {
    kode_toko: "PQC",
    nama_toko: "PQC",
  },
];

const ListKodeTokoBerkah = [
  {
    kode_toko: "CHD",
    nama_toko: "CHD",
  },
  {
    kode_toko: "PSW",
    nama_toko: "PSW",
  },
  {
    kode_toko: "PWT",
    nama_toko: "PWT",
  },
  {
    kode_toko: "NQC",
    nama_toko: "NQC",
  },
  {
    kode_toko: "VDC",
    nama_toko: "VDC",
  },
];

const ListKodeTokoSambas = [
  {
    kode_toko: "SPP",
    nama_toko: "SPP",
  },
  {
    kode_toko: "SBP",
    nama_toko: "SBP",
  },
  {
    kode_toko: "SPW",
    nama_toko: "SPW",
  },
  {
    kode_toko: "SWT",
    nama_toko: "SWT",
  },
];

const ListKodeTokoUtami = [
  {
    kode_toko: "UT1",
    nama_toko: "UT1",
  },
  {
    kode_toko: "UT2",
    nama_toko: "UT2",
  },
  {
    kode_toko: "UT3",
    nama_toko: "UT3",
  },
  {
    kode_toko: "UT4",
    nama_toko: "UT4",
  },
  {
    kode_toko: "UT5",
    nama_toko: "UT5",
  },
];

const ListSambasEyang = [
  {
    kode_toko: "KRM",
    nama_toko: "KRM",
  },
  {
    kode_toko: "SBL",
    nama_toko: "SBL",
  },
  {
    kode_toko: "SB2",
    nama_toko: "SB2",
  },
  {
    kode_toko: "SKS",
    nama_toko: "SKS",
  },
  {
    kode_toko: "SML",
    nama_toko: "SML",
  },
  {
    kode_toko: "SBK",
    nama_toko: "SBK",
  },
  {
    kode_toko: "SS2",
    nama_toko: "SS2",
  },
];

const ListTokoBares = [
  {
    kode_toko: "BR1",
    nama_toko: "BR1",
  },
  {
    kode_toko: "BR2",
    nama_toko: "BR2",
  },
  {
    kode_toko: "BR3",
    nama_toko: "BR3",
  },
  {
    kode_toko: "BR4",
    nama_toko: "BR4",
  },
  {
    kode_toko: "BR5",
    nama_toko: "BR5",
  },
  {
    kode_toko: "BR6",
    nama_toko: "BR6",
  },
  {
    kode_toko: "BR7",
    nama_toko: "BR7",
  },
  {
    kode_toko: "BR8",
    nama_toko: "BR8",
  },
  {
    kode_toko: "BR9",
    nama_toko: "BR9",
  },
  {
    kode_toko: "BRP",
    nama_toko: "BRP",
  },
  {
    kode_toko: "B10",
    nama_toko: "B10",
  },
  {
    kode_toko: "NQC",
    nama_toko: "NQC",
  },
];

const ListTokoLaris = [
  {
    kode_toko: "LRS",
    nama_toko: "LRS",
  },
  {
    kode_toko: "LRP",
    nama_toko: "LRP",
  },
  {
    kode_toko: "PCI",
    nama_toko: "PCI",
  },
];

const ListTokoMelatiGold = [
  {
    kode_toko: "MLT",
    nama_toko: "MLT",
  },
  {
    kode_toko: "NQC",
    nama_toko: "NQC",
  },
];

const ListTokoSubur = [
  {
    kode_toko: "SR1",
    nama_toko: "TOKO EMAS SUBUR1",
  },
  {
    kode_toko: "SR2",
    nama_toko: "TOKO EMAS SUBUR2",
  },
  {
    kode_toko: "SR3",
    nama_toko: "TOKO EMAS SUBUR3",
  },
  {
    kode_toko: "SR4",
    nama_toko: "TOKO EMAS SUBUR4",
  },
  {
    kode_toko: "SRP",
    nama_toko: "TOKO EMAS SUBUR2",
  },
  // {
  //   kode_toko: "QC9",
  //   nama_toko: "TOKO EMAS SUBUR2",
  // },
];
const ListTokoKohinoor = [
  {
    kode_toko: "NFJ",
    nama_toko: "GALLERY KOHINOOR",
  },
];

const ListTokoGajah = [
  {
    kode_toko: "GJH",
    nama_toko: "GAJAH KEDUNGWUNI",
  },
];

const ListTokoOmega = [
  {
    kode_toko: "OMG",
    nama_toko: "TOKO EMAS OMEGA",
  },
  {
    kode_toko: "STR",
    nama_toko: "TOKO EMAS STR",
  },
  {
    kode_toko: "YRM",
    nama_toko: "TOKO EMAS YRM",
  },
  {
    kode_toko: "ZFO",
    nama_toko: "TOKO EMAS ZFO",
  },
  {
    kode_toko: "TMO",
    nama_toko: "TOKO EMAS TMO",
  },
];
const ListTokoSSJember = [
  {
    kode_toko: "SSJ",
    nama_toko: "TOKO EMAS SS Jember",
  },
];

const ListTokoSembada = [
  {
    kode_toko: "SB1",
    nama_toko: "SEMBADA 1",
  },
  {
    kode_toko: "SB2",
    nama_toko: "SEMBADA 2",
  },
  {
    kode_toko: "SB3",
    nama_toko: "SEMBADA 3",
  },
  {
    kode_toko: "SB4",
    nama_toko: "SEMBADA 4",
  },
  {
    kode_toko: "SB5",
    nama_toko: "SEMBADA 5",
  },
  {
    kode_toko: "SB6",
    nama_toko: "SEMBADA 6",
  },
];
const ListTokoBintangIndah = [
  {
    kode_toko: "BTH",
    nama_toko: "BINTANG INDAH",
  },
];

const ListTokoBungaTanjung = [
  {
    kode_toko: "BTG",
    nama_toko: "BUNGA TANJUNG 1",
  },
  {
    kode_toko: "BT2",
    nama_toko: "BUNGA TANJUNG 2",
  },
];

export {
  ListTokoBintangIndah,
  ListSambasEyang,
  ListKodeTokoUtami,
  ListKodeTokoBerkah,
  ListKodeTokoSambas,
  ListKodeTokoPantes,
  ListTokoMacan,
  ListTokoBares,
  LisKodeTokoArjuna,
  LisKodeTokoDuaNaga,
  ListTokoLaris,
  ListTokoMelatiGold,
  ListTokoSubur,
  ListTokoKohinoor,
  ListTokoGajah,
  LisKodeTokoDewiGold,
  ListKodeTokoDamay,
  ListTokoOmega,
  ListTokoSSJember,
  ListTokoSembada,
  ListTokoUtami,
  ListTokoBungaTanjung,
};
