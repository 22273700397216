import React from "react";
import { Link } from "react-router-dom";
import { reset } from "redux-form";
import BackButtonPos from "../pos_property/BackButton";

const PanelStat = React.createContext();

class Panel extends React.Component {
  constructor(props) {
    super(props);

    this.toggleExpand = () => {
      this.setState((state) => ({
        expand: !this.state.expand,
      }));
    };

    this.toggleRemove = () => {
      this.setState((state) => ({
        remove: !this.state.remove,
      }));
    };

    this.toggleCollapse = () => {
      this.setState((state) => ({
        collapse: !this.state.collapse,
      }));
    };

    this.toggleReload = () => {
      if (this.state.reload !== true) {
        this.setState((state) => ({
          reload: true,
        }));
        setTimeout(() => {
          this.setState((state) => ({
            reload: false,
          }));
          this.props.refresh.props.dispatch(reset(this.props.refresh.namaForm));
        }, 2000);
      }
    };

    this.state = {
      expand: false,
      collapse: false,
      reload: false,
      remove: false,
      toggleExpand: this.toggleExpand,
      toggleReload: this.toggleReload,
      toggleRemove: this.toggleRemove,
      toggleCollapse: this.toggleCollapse,
    };
  }
  render() {
    return (
      <PanelStat.Provider value={this.state}>
        {!this.state.remove && (
          <div
            className={
              "panel panel-" +
              (this.props.theme ? this.props.theme : "inverse") +
              " " +
              (this.state.expand ? "panel-expand " : " ") +
              (this.state.reload ? "panel-loading " : " ") +
              (this.props.className ? this.props.className : "")
            }
          >
            {this.props.children}
          </div>
        )}
      </PanelStat.Provider>
    );
  }
}

class PanelHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="panel-heading">
        <h4 className="panel-title">{this.props.children}</h4>
        {!this.props.noButton && (
          <PanelStat.Consumer>
            {({ toggleExpand, reload, toggleCollapse,collapse, toggleReload }) => (
              <div className="panel-heading-btn">
                {/* <button className="btn btn-xs btn-icon btn-circle btn-default" onClick={toggleExpand}><i className="fa fa-expand"></i></button>&nbsp;&nbsp; */}
                {this.props.refresh ? (
                  <>
                    <button
                      className="btn btn-xs btn-icon btn-circle btn-success"
                      onClick={toggleReload}
                    >
                      <i className={`fa fa-redo ${reload && 'fa-spin'}`}></i>
                    </button>
                    &nbsp;&nbsp;
                  </>
                ) : (
                  ""
                )}
                {this.props.hidden ? (
                  <button
                    className="btn btn-xs btn-icon btn-circle btn-warning"
                    onClick={toggleCollapse}
                  >
                   {collapse ? <i className="fa fa-minus"></i> : <i className="fa fa-angle-down fa-lg"></i> }
                  </button>
                ) : (
                  ""
                )}
               
              </div>
            )}
          </PanelStat.Consumer>
        )}
      </div>
    );
  }
}

class PanelBody extends React.Component {
  render() {
    return (
      <PanelStat.Consumer>
        {({ collapse, reload }) => (
          <div
            className={
              "panel-body " +
              (collapse ? "d-block " : this.props.hidden ? " d-none " : " ") +
              this.props.className
            }
          >
            {this.props.children}

            {reload && (
              <div className="panel-loader">
                <span className="spinner-small"></span>
              </div>
            )}
          </div>
        )}
      </PanelStat.Consumer>
    );
  }
}

class PanelFooter extends React.Component {
  render() {
    return (
      <div className={"panel-footer " + this.props.className}>
        {this.props.children}
      </div>
    );
  }
}
class PanelContent extends React.Component {
  render() {
    return (
      <div>
        {this.props.hideBackButton === true ? null : (
          <BackButtonPos link={this.props.link}>
            <ol className="breadcrumb float-xl-right">
              {this.props.submenu !== undefined ? (
                <>
                  <li className="breadcrumb-item">
                    <Link to="#">{this.props.submenu}</Link>
                  </li>
                  <li className="breadcrumb-item active">{this.props.menu || this.props.headerMenu}</li>
                </>
              ) : null}
            </ol>
            <h1 className="page-header">{this.props.menu || this.props.headerMenu} </h1>
          </BackButtonPos>
        )}
        <Panel refresh={this.props.refresh}>
          <PanelHeader
            refresh={this.props.refresh}
            namaForm={this.props.namaForm}
            hidden={this.props.hideForm}
          >
            {this.props.menu   || this.props.title}
          </PanelHeader>
          <PanelBody hidden={this.props.hideForm}>
            {this.props.children}
          </PanelBody>
        </Panel>
      </div>
    );
  }
}

export { Panel, PanelHeader, PanelBody, PanelFooter, PanelContent };
