import { selectorMaster, useSelector, React } from "../library";

const Button = ({
  loading,
  type,
  block,
  color,
  textLoading,
  title,
  disabled,
  onClick
}) => {
  const isLoading = useSelector(selectorMaster.loading);

  return (
    <button
      disabled={
        loading || disabled ? (isLoading || disabled ? "disabled" : "") : ""
      }
      type={type}
      className={`btn btn-${color} ${block ? "btn-block" : ""}`}
      onClick={onClick}
    >
      {loading ? (
        isLoading ? (
          <>
            <i className="fas fa-spinner fa-spin"></i> &nbsp; {textLoading}
          </>
        ) : (
          title
        )
      ) : (
        title
      )}
    </button>
  );
};

export default Button;
