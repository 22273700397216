import {
    formatGram,
    ModalGlobal,
    Panel,
    PanelBody,
    PanelHeader,
    Tabel,
  } from "../../../components/helpers/library";
  import React from "react";
  import { Loading } from "react-fullscreen-loading";
  import { connect } from "react-redux";
  import { Link } from "react-router-dom";
  import { fetchBannerKatalog } from "../BannerKatalog/redux/bannerKatalogAction";
  import { fetchInfoKatalog } from "../InfoKatalog/redux/infoKatalogAction";
  import { fetchKategoriKatalog } from "../KategoriKatalog/redux/kategoriKatalogAction";
  import {
    fetchItemKatalog,
    handleSubmitItemKatalog,
    setDeleteItemKatalog,
    setEditItemKatalog,
    setTambahItemKatalog,
  } from "./redux/itemKatalogAction";
  import FormItemKatalog from "./widgets/FormItemKatalog";
  
  class ItemKatalog extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        columns: [
          {
            dataField: "kode_barang",
            text: "Kode Item ",
            sort: true,
          },
          {
            dataField: "nama_barang",
            text: "Nama Item",
          },
  
          {
            dataField: "berat",
            text: "Berat",
            formatter: (data) => formatGram(data),
          },
          {
            dataField: "harga_jual",
            text: "Harga Jual",
            formatter: (data) => data?.toLocaleString("kr-KO"),
          },
          {
            dataField: "gambar_path",
            text: "Gambar",
            formatter: (data) => {
              return (
                <ul className="list-group">
                  {data?.map((e) => {
                    return (
                      <li className="list-group-item">
                        <img src={`${e}`} alt="IMAGES" class="img-thumbnail" />
                      </li>
                    );
                  })}
                </ul>
              );
            },
          },
          {
            dataField: "deskripsi_banner",
            text: "List Banner",
            formatter: (data) => {
              return (
                <ul className="list-group">
                  {data?.map((data) => {
                    return <li className="list-group-item">{data}</li>;
                  })}
                </ul>
              );
            },
          },
          {
            dataField: "deskripsi_info",
            text: "List Info",
            formatter: (data) => {
              return (
                <ul className="list-group">
                  {data?.map((data) => {
                    return <li className="list-group-item">{data}</li>;
                  })}
                </ul>
              );
            },
          },
          {
            dataField: "action",
            text: "Action",
            csvExport: false,
            headerClasses: "text-center",
            formatter: (rowcontent, row) => {
              return (
                <div className="row text-center">
                  <div className="col-12">
                    <button
                      className="btn btn-primary mr-3"
                      onClick={() => {
                        this.props.dispatch(setEditItemKatalog(row));
                      }}
                    >
                      <i className="fa fa-edit"></i>
                    </button>
                    <button
                      className="btn btn-danger"
                      onClick={() => {
                        this.props.dispatch(setDeleteItemKatalog(row));
                      }}
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                  </div>
                </div>
              );
            },
          },
        ],
      };
    }
  
    componentDidMount() {
      this.props.dispatch(fetchItemKatalog());
      this.props.dispatch(fetchKategoriKatalog());
      this.props.dispatch(fetchBannerKatalog());
      this.props.dispatch(fetchInfoKatalog());
    }
  
    render() {
      return (
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="#">Item Katalog</Link>
            </li>
            <li className="breadcrumb-item active">Item Katalog</li>
          </ol>
          <h1 className="page-header">Item Katalog </h1>
          <Panel>
            <PanelHeader>Item Katalog</PanelHeader>
            <br />
            <PanelBody>
              <Tabel
                keyField="kode_barang"
                tambahData={true}
                handleClick={() => this.props.dispatch(setTambahItemKatalog())}
                exportCsv={true}
                data={this.props.ItemKatalog}
                columns={this.state.columns}
                empty={this.props.ItemKatalog}
                textEmpty="Data Item Kosong"
                fileNameCsv={`kategori-katalog`}
              />
            </PanelBody>
            <br />
          </Panel>
          <ModalGlobal
            size="P"
            title={
              this.state.isEdit ? "Edit Item Katalog" : "Tambah Item Katalog"
            }
            content={
              <FormItemKatalog
                isEdit={this.state.isEdit}
                onSubmit={(data) =>
                  this.props.dispatch(handleSubmitItemKatalog(data))
                }
              />
            }
          />
          {this.state.isLoading ? (
            <Loading
              loading
              background="rgba(0, 0, 0, 0.35)"
              loaderColor="rgba(94, 147, 117, 1)"
            />
          ) : null}
        </div>
      );
    }
  }
  
  export default connect((state) => {
    return {
      ItemKatalog: state.stateItemKatalog.itemKatalog,
    };
  })(ItemKatalog);
  