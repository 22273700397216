import { isEdit, isLoading, showModal } from "../../../../actions";
import {
  ToastNotification,
  putData,
  getItem,
  setItem,
} from "../../../../components";

export const parameterAkun = () => {
  const update = (newData) => {
    return async (dispatch, getStae) => {};
  };

  const showForm = (data, type) => {
    return async (dispatch, getStae) => {
      if (type === "DETAILAKUN") {
        dispatch(showModal());
        setItem("data_akun", data);
      } else {
        setItem("tmp_detail", data);
        dispatch(isEdit(true));
      }
    };
  };

  const simpanPerubahanNoAkun = () => {
    return async (dispatch, getStae) => {
      // console.log(getItem("tmp_detail"));
      try {
        dispatch(isLoading(true));
        await putData(
          "para-akun-transaksi/" +
            getItem("tmp_detail").trx +
            "/" +
            getItem("tmp_detail").type,
          {
            detail_akun: getItem("tmp_detail").detail_akun.map((list) => {
              return {
                kode_akun: list.kode_akun,
                jenis_akun: list.jenis_akun,
                nomor_akun: list.nomor_akun,
              };
            }),
          }
        );
        ToastNotification("success", "Data Berhasil Diedit");

        dispatch(isEdit(false));
        dispatch(isLoading(false));
      } catch (error) {
        ToastNotification(
          "success",
          error?.response?.data || "Data Gagal Diedit"
        );
        dispatch(isLoading(false));
      }
    };
  };
  return {
    update,
    showForm,
    simpanPerubahanNoAkun,
  };
};
