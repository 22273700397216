import {
    ModalGlobal,
    Panel,
    PanelBody,
    PanelHeader,
    Tabel,
  } from "../../../components/helpers/library";
  import React from "react";
  import { Loading } from "react-fullscreen-loading";
  import { connect } from "react-redux";
  import { Link } from "react-router-dom";
  import {
    fetchBannerKatalog,
    handleSubmitBannerKatalog,
    setDeleteBannerKatalog,
    setEditBannerKatalog,
    setTambahBannerKatalog,
  } from "./redux/bannerKatalogAction";
  import FormBannerKatalog from "./widgets/FormBannerKatalog";
  
  class BannerKatalog extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        columns: [
          {
            dataField: "kode_banner",
            text: "Kode Banner ",
            sort: true,
          },
          {
            dataField: "title",
            text: "Title",
          },
          {
            dataField: "deskripsi",
            text: "Deskripsi",
          },
          {
            dataField: "gambar_path",
            text: "Gambar",
            formatter: (data) => {
              return (
                <img src={`${data}`} alt="IMAGES" className="img-thumbnail" />
              );
            },
          },
          {
            dataField: "action",
            text: "Action",
            csvExport: false,
            headerClasses: "text-center",
            formatter: (rowcontent, row) => {
              return (
                <div className="row text-center">
                  <div className="col-12">
                    <button
                      className="btn btn-primary mr-3"
                      onClick={() => {
                        this.props.dispatch(setEditBannerKatalog(row));
                      }}
                    >
                      <i className="fa fa-edit"></i>
                    </button>
                    <button
                      className="btn btn-danger"
                      onClick={() => {
                        this.props.dispatch(setDeleteBannerKatalog(row));
                      }}
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                  </div>
                </div>
              );
            },
          },
        ],
      };
    }
  
    componentDidMount() {
      this.props.dispatch(fetchBannerKatalog());
    }
  
    render() {
      return (
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="#">Banner Katalog</Link>
            </li>
            <li className="breadcrumb-item active">Banner Katalog</li>
          </ol>
          <h1 className="page-header">Banner Katalog </h1>
          <Panel>
            <PanelHeader>Banner Katalog</PanelHeader>
            <br />
            <PanelBody>
              <Tabel
                keyField="kode_banner"
                tambahData={true}
                handleClick={() => this.props.dispatch(setTambahBannerKatalog())}
                exportCsv={true}
                data={this.props.BannerKatalog}
                columns={this.state.columns}
                empty={this.props.BannerKatalog}
                textEmpty="Data Banner Kosong"
                fileNameCsv={`kategori-katalog`}
              />
            </PanelBody>
            <br />
          </Panel>
          <ModalGlobal
            size="P"
            title={
              this.state.isEdit ? "Edit Banner Katalog" : "Tambah Banner Katalog"
            }
            content={
              <FormBannerKatalog
                isEdit={this.state.isEdit}
                onSubmit={(data) =>
                  this.props.dispatch(handleSubmitBannerKatalog(data))
                }
              />
            }
          />
          {this.state.isLoading ? (
            <Loading
              loading
              background="rgba(0, 0, 0, 0.35)"
              loaderColor="rgba(94, 147, 117, 1)"
            />
          ) : null}
        </div>
      );
    }
  }
  
  export default connect((state) => {
    return {
      BannerKatalog: state.stateBannerKatalog.bannerKatalog,
    };
  })(BannerKatalog);
  