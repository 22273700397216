import {
    ModalGlobal,
    Panel,
    PanelBody,
    PanelHeader,
    Tabel,
  } from "../../../components/helpers/library";
  import React from "react";
  import { Loading } from "react-fullscreen-loading";
  import { connect } from "react-redux";
  import { Link } from "react-router-dom";
  import {
    fetchKategoriKatalog,
    handleSubmitKategoriKatalog,
    setDeleteKategoriKatalog,
    setEditKategoriKatalog,
    setTambahKategoriKatalog,
  } from "./redux/kategoriKatalogAction";
  import FormKategoriKatalog from "./widgets/FormKategoriKatalog";
  
  class KategoriKatalog extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
  
        columns: [
          {
            dataField: "kode_kategori",
            text: "Kode Kategori ",
            sort: true,
          },
          {
            dataField: "nama_kategori",
            text: "Nama Kategori",
          },
          {
            dataField: "gambar_path",
            text: "Gambar",
            formatter: (data) => {
              return <img src={`${data}`} alt="IMAGES" class="img-thumbnail" />;
            },
          },
          {
            dataField: "warna",
            text: "Warna",
            formatter: (data) => {
              return (
                <div
                  style={{
                    backgroundColor: `${data}`,
                    width: "30px",
                    height: "30px",
                  }}
                  title="Gold"
                ></div>
              );
            },
          },
          {
            dataField: "action",
            text: "Action",
            csvExport: false,
            headerClasses: "text-center",
            formatter: (rowcontent, row) => {
              return (
                <div className="row text-center">
                  <div className="col-12">
                    <button
                      className="btn btn-primary mr-3"
                      onClick={() => {
                        this.props.dispatch(setEditKategoriKatalog(row));
                      }}
                    >
                      <i className="fa fa-edit"></i>
                    </button>
                    <button
                      className="btn btn-danger"
                      onClick={() => {
                        this.props.dispatch(setDeleteKategoriKatalog(row));
                      }}
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                  </div>
                </div>
              );
            },
          },
        ],
      };
    }
  
    componentDidMount() {
      this.props.dispatch(fetchKategoriKatalog());
    }
  
    render() {
      return (
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="#">Kategori Katalog</Link>
            </li>
            <li className="breadcrumb-item active">Kategori Katalog</li>
          </ol>
          <h1 className="page-header">Kategori Katalog </h1>
          <Panel>
            <PanelHeader>Kategori Katalog</PanelHeader>
            <br />
            <PanelBody>
              <Tabel
                keyField="kode_kategori"
                tambahData={true}
                handleClick={() =>
                  this.props.dispatch(setTambahKategoriKatalog())
                }
                exportCsv={true}
                data={this.props.kategoriKatalog}
                columns={this.state.columns}
                empty={this.props.kategoriKatalog}
                textEmpty="Data Kategori Kosong"
                fileNameCsv={`kategori-katalog`}
              />
            </PanelBody>
            <br />
          </Panel>
          <ModalGlobal
            size="P"
            title={
              this.state.isEdit
                ? "Edit Kategori Katalog"
                : "Tambah Kategori Katalog"
            }
            content={
              <FormKategoriKatalog
                isEdit={this.state.isEdit}
                onSubmit={(data) =>
                  this.props.dispatch(handleSubmitKategoriKatalog(data))
                }
              />
            }
          />
          {this.state.isLoading ? (
            <Loading
              loading
              background="rgba(0, 0, 0, 0.35)"
              loaderColor="rgba(94, 147, 117, 1)"
            />
          ) : null}
        </div>
      );
    }
  }
  
  export default connect((state) => {
  
    return {
      kategoriKatalog: state.stateKategoriKatalog.kategoriKatalog,
    };
  })(KategoriKatalog);
  