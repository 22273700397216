import Swal from "sweetalert2";
import { postData } from "../../config/axios";
import lang from "./lang";

export function NotifSucces(text) {
  return new Promise((resolve, reject) => {
    Swal.fire({
      // title: 'Good Job',
      text: lang(text),
      icon: "success",
      position: "top-center",
      timer: 2000,
      width: "300px",
      height: "100px",
      showConfirmButton: false,
    })
      .then(resolve("berhasil"))
      .catch(reject("error"));
  });
}
export function NotificationConfirm(status, text) {
  return new Promise((resolve, reject) => {
    Swal.fire({
      html: lang(text),
      icon: status,
      position: "top-center",
      cancelButtonText: `${lang("Tidak")}`,
      showCancelButton: true,
      confirmButtonText: lang("Ya"),
      showConfirmButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        resolve("berhasil");
      }
    });
  });
}
export function NotifError(text) {
  return new Promise((resolve, reject) => {
    Swal.fire({
      title: "Oopss!!",
      text: text,
      icon: "error",
      position: "top-center",
      timer: 3000,
      showConfirmButton: false,
    })
      .then(resolve("berhasil"))
      .catch(reject("error"));
  });
}
export function NotifWarning(text) {
  return new Promise((resolve, reject) => {
    Swal.fire({
      title: "Warning !!!",
      text: text,
      icon: "warning",
      position: "top-right",
      timer: 2000,
      showConfirmButton: false,
    })
      .then(resolve("berhasil"))
      .catch(reject("error"));
  });
}
export function NotifReactif(err, endpoint, data) {
  return new Promise((resolve, reject) => {
    Swal.fire({
      title: "Warning !!!",
      text: err.response.data,
      icon: "info",
      position: "top-center",
      cancelButtonText: lang("Batal"),
      showCancelButton: true,
      confirmButtonText: lang("Aktifkan"),
      showConfirmButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        postData(endpoint, data)
          .then(() => resolve("Berhasil"))
          .then(() => NotifSucces("Berhasil"))
          .catch();
      }
    });
  });
}

export function NotifInfo(text) {
  return new Promise((resolve, reject) => {
    Swal.fire({
      title: "Informasi !!!",
      text: text,
      icon: "info",
      position: "top-right",
      timer: 2000,
      showConfirmButton: false,
    })
      .then(resolve("berhasil"))
      .catch(reject("error"));
  });
}

export function NotifCustome(text) {
  return new Promise((resolve, reject) => {
    Swal.fire({
      title: "Informasi !!!",
      text: text,
      icon: "info",
      position: "center",
      showConfirmButton: false,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        resolve("berhasil");
      }
    });
  });
}
export function NotifTagId(text) {
  return new Promise((resolve, reject) => {
    Swal.fire({
      title: "Informasi !!!",
      text: text,
      icon: "info",
      position: "center",
      showConfirmButton: true,
      confirmButtonText: "Tutup Tag Id",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        resolve("berhasil");
      }
    });
  });
}

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});
export function ToastNotification(status, text) {
  return new Promise((resolve, reject) => {
    Toast.fire({
      icon: status,
      title: lang(text),
    })
      .then(resolve("berhasil"))
      .catch(reject("error"));
  });
}

export const NotifPlaform = async () =>
  await Swal.fire({
    title: "Pilih Nota Platform",
    input: "select",
    inputOptions: {
      false: "Desktop",
      true: "Mobile",
    },
    // inputPlaceholder: 'Pilih Platform',
    showCancelButton: true,
    inputValidator: (value) => {
      return new Promise((resolve) => {
        if (value) {
          resolve();
        } else {
          resolve("You need to select oranges :)");
        }
      });
    },
  });
export const NotifTYpe = async () =>
  await Swal.fire({
    title: "Pilih Nota Platform",
    input: "select",
    inputOptions: {
      FRONT: "DEPAN",
      BACK: "BELAKANG",
    },
    // inputPlaceholder: 'Pilih Platform',
    showCancelButton: true,
    inputValidator: (value) => {
      return new Promise((resolve) => {
        if (value) {
          resolve();
        } else {
          resolve("You need to select oranges :)");
        }
      });
    },
  });

export function NotifMember(data) {
  let input = "";
  let jenisKasus = "";

  console.log(data);
  return new Promise((resolve, reject) => {
    for (let i = 0; i < data.length; i++)
      input +=
        "<tr>" +
        data[i]["transaksi"] +
        " " +
        data[i]["no_nota"] +
        " " +
        data[i]["keterangan"] +
        "     ," +
        " ";
    jenisKasus = data
      .filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.jenis === value.jenis)
      )
      .map((x) => x.jenis);
    Swal.fire({
      title: jenisKasus,
      icon: "info",
      position: "top-center",
      confirmButtonText: "OK",
      showConfirmButton: true,
      html:
        `<br>
                           
                            <tbody>
                                
                                <tr>
                                                <td>` +
        input +
        `</td>
                                              
                                            </tr>
                            </tbody>
                            </table>`,
    });
  });
}
