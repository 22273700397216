// import { hideModal, showModal } from "actions/datamaster_action";
import {
  showModal,
  hideModal,
  isLoading,
} from "../../../../actions/datamaster_action.jsx";
// import { dataURLtoFile, makeID } from "components/helpers/function";
import { dataURLtoFile, makeID } from "../../../../components/helpers/function";
import { NotifSucces, ToastNotification } from "../../../../components/helpers/library";
import { NotifError } from "../../../../components/helpers/notification";
import {
  deleteData,
  deleteImageApp,
  getData,
  postData,
  postImageApp,
  putData,
} from "../../../../config/axios";

import Swal from "sweetalert2";

export const GET_KATEGORI_KATALOG = "GET_KATEGORI_KATALOG";
export const SET_DATA_KATEGORI_KATALOG = "SET_DATA_KATEGORI_KATALOG";
export const DELETE_KATEGORI_KATALOG = "DELETE_KATEGORI_KATALOG";
export const EDIT_KATEGORI_KATALOG = "EDIT_KATEGORI_KATALOG";
export const POST_KATEGORI_KATALOG = "POST_KATEGORI_KATALOG";

export const fetchKategoriKatalog = () => {
  return (dispatch) => {
    dispatch(isLoading(true));
    getData("app/kategori-katalog")
      .then((res) => {
        dispatch({
          type: GET_KATEGORI_KATALOG,
          payload: {
            data: res.data,
          },
        });
        dispatch(isLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(isLoading(false));
      });
  };
};

export const setEditKategoriKatalog = (data) => {
  return async (dispatch) => {
    dispatch({
      type: SET_DATA_KATEGORI_KATALOG,
      payload: data,
    });
    dispatch(showModal());
  };
};

export const setDeleteKategoriKatalog = (data) => {
  return async (dispatch) => {
    Swal.fire({
      html:
        "Apakah Anda Yakin Ingin" +
        "Menghapus " +
        "<h1><b>Kode Kategori " +
        data.kode_kategori +
        "</b> ?</h1>",
      icon: "warning",
      position: "top-center",
      cancelButtonText: "Tidak",
      showCancelButton: true,
      confirmButtonText: "OK",
      showConfirmButton: true,
    })
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(deleteKategoriKatalog(data.kode_kategori, data.gambar_name));
        }
      })
      .catch((err) => {
        ToastNotification("error", err.response.data);
      });
  };
};

export const setTambahKategoriKatalog = (data) => {
  return async (dispatch) => {
    dispatch({
      type: SET_DATA_KATEGORI_KATALOG,
      payload: null,
    });
    dispatch(showModal());
  };
};

export const deleteKategoriKatalog = (kode_kategori, gambar_name) => {
  return (dispatch) => {
    dispatch(isLoading(true));
    deleteData("app/kategori-katalog/delete/" + kode_kategori)
      .then((res) => {
        NotifSucces("Data Berhasil Di Hapus")
          .then(async () => {
            await deleteImageApp(`KATEGORI_ICON/${gambar_name}`);
          })
          .then(() => dispatch(fetchKategoriKatalog()))
          .then(() => dispatch(isLoading(false)));
      })
      .catch((err) => {
        console.log(err);
        dispatch(isLoading(false));
        NotifError("Gagal Menghapus Kategori Katalog, Coba Beberapa Saat Lagi");
      });
  };
};

export const updateKategoriKatalog = (kode_kategori, data) => {
  return (dispatch) => {
    dispatch(isLoading(true));
    putData("app/kategori-katalog/edit/" + kode_kategori, data)
      .then(async (res) => {
        await NotifSucces("Data Berhasil Dirubah");
        dispatch(fetchKategoriKatalog());
        dispatch(hideModal());
        dispatch(isLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(isLoading(false));
        NotifError("Gagal Merubah Kategori Katalog, Coba Beberapa Saat Lagi");
      });
  };
};

export const sendKategoriKatalog = (data) => {
  return (dispatch) => {
    dispatch(isLoading(true));
    postData("app/kategori-katalog", data)
      .then(async () => {
        await NotifSucces("Data Berhasil Ditambahkan");
        dispatch(fetchKategoriKatalog());
        dispatch(hideModal());
        dispatch(isLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(isLoading(false));
        NotifError(
          "Gagal Menambahkan Kategori Katalog, Coba Beberapa Saat Lagi"
        );
      });
  };
};

export const handleSubmitKategoriKatalog = (data) => {
  return async (dispatch, getState) => {
    dispatch(isLoading(true));
    const state = getState();
    const current = state.stateKategoriKatalog.ShowModal;
    if (data.gambar_path === undefined || data.gambar_path === null) {
      dispatch(isLoading(false));
      NotifError("Gambar wajib dimasukan, Mohon masukan gambar dan coba lagi");
      return false;
    }
    if (data.warna === undefined || data.warna === null) {
      dispatch(isLoading(false));
      NotifError("Warna wajib dipilih, Mohon pilih warna dan coba lagi");
      return false;
    }
    const gambar_name = makeID();
    if (current) {
      if (data.gambar_path !== current.gambar_path) {
        var imgUrl = await postImageApp(
          dataURLtoFile(data.gambar_path),
          `KATEGORI_ICON/${data.gambar_name}`
        );
        data.gambar_path = imgUrl;
      }
      let kode_kategori = data.kode_kategori;
      delete data.kode_kategori;
      dispatch(updateKategoriKatalog(kode_kategori, data));
      dispatch(isLoading(false));
    } else {
      var imgUrl2 = await postImageApp(
        dataURLtoFile(data.gambar_path),
        `KATEGORI_ICON/${gambar_name}`
      );
      data.gambar_path = imgUrl2;
      data.gambar_name = gambar_name;
      dispatch(sendKategoriKatalog(data));
      dispatch(isLoading(false));
    }
  };
};
