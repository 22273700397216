import {
  Button,
  Field,
  ModalGlobal,
  ReanderField,
  Tabel,
  connect,
  getItem,
  reduxForm,
  setItem,
  useDispatch,
} from "../../../../components/helpers/library";
import React from "react";
import { useEffect } from "react";
import { getParameterAkun, isEdit, isLoading } from "../../../../actions";
import FormAkun from "../form";
import cellEditFactory from "react-bootstrap-table2-editor";
import { parameterAkun } from "../redux";

let TabelDetailAkun = (props) => {
  const dispatch = useDispatch();

  const action = parameterAkun();

  const columns = [
    {
      dataField: "jenis_akun",
      text: "Jenis Akun",
    },
    {
      dataField: "nomor_akun",
      text: "Nomor AKun",
      formatter: (cell) => {
        return <span data-tooltip="Klik untuk edit nomor akun"> {cell}</span>;
      },
      editorRenderer: (editorProps, value) => (
        <FormAkun {...editorProps} value={value} />
      ),
    },
  ];

  useEffect(() => {
    dispatch(getParameterAkun());
  }, [dispatch]);

  return (
    <div className="row">
      <div className="col-6">
        <Field
          name="trx"
          component={ReanderField}
          type="text"
          readOnly
          label="Nama Jurnal"
          placeholder="Masukkan Nama Jurnal"
        />
      </div>
      <div className="col-6">
        <Field
          name="type"
          component={ReanderField}
          type="text"
          readOnly
          label="Type Jurnal"
          placeholder="Masukkan Type Jurnal"
        />
      </div>
      <div className="col-12 ">
        <Tabel
          keyField="jenis_akun"
          data={getItem("tmp_detail")?.detail_akun || []}
          columns={columns}
          cellEdit={cellEditFactory({
            mode: "click",
            blurToSave: true,
            afterSaveCell: (oldValue, newValue, row, column) => {
              let data = {
                _id: row._id,
                kode_akun: row.kode_akun,
                jenis_akun: row.jenis_akun,
                nomor_akun: newValue,
              };
              dispatch(isLoading(true));

              let trx = getItem("tmp_detail")?.trx;
              let type = getItem("tmp_detail")?.type;

              let cek = getItem("tmp_detail")?.detail_akun;
              let hasil_cek = cek.findIndex((hasil) => hasil._id === data._id);

              if (hasil_cek > -1) {
                cek[hasil_cek] = data;
                setTimeout(() => {
                  setItem("tmp_detail", {
                    trx: trx,
                    type: type,
                    detail_akun: cek,
                  });
                  dispatch(isLoading(false));
                }, 300);
              }
            },
          })}
        />
      </div>
      <div className="col-6 "></div>
      <div className="col-6 text-right">
        <Button
          color="primary"
          title="Kembali"
          onClick={() => dispatch(isEdit(false))}
        />{" "}
        &nbsp;
        <Button
          color="success"
          title="Simpan Perubahan"
          onClick={() => dispatch(action.simpanPerubahanNoAkun())}
        />
      </div>

      <ModalGlobal title="Edit Data Akun" size="p" content={<FormAkun />} />
    </div>
  );
};

// export default TabelDetailAkun;
const maptostate = (state) => {
  return {
    detail_akun: getItem("tmp_detail")?.detail_akun,
    initialValues: {
      trx: getItem("tmp_detail")?.trx,
      type: getItem("tmp_detail")?.type,
    },
  };
};

TabelDetailAkun = reduxForm({
  form: "TabelDetailAkun",
  enableReinitialize: true,
})(TabelDetailAkun);
export default connect(maptostate, null)(TabelDetailAkun);
