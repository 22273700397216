import { ganerateNotaSlip, TextFile } from "./function";

const NotaSlipGanerate = async (res,nama_file,form) => {
  const notaGenerated = ganerateNotaSlip(res,form)
  document.getElementById("nota_ganerate").value = notaGenerated?.join("\n");
  setTimeout(() => {
    TextFile(nama_file);
  }, 100);
};


export default NotaSlipGanerate;