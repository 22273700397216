import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  getToday,
  getUserdata,
  getItem
} from "../../../../../components/helpers/function";
// Date Fns is used to format the dates we receive

// define a generatePDF function that accepts a tickets argument
const LaporanBatalPesananPdf = (
  data = "",
  username = "",
  tanggal_dari = "",
  tanggal_sampai = ""
) => {
  // initialize jsPDF
  const doc = new jsPDF("l", "mm", [297, 210]);
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];
  // let footRows = [];
  let tableColumn = [];
  let finalY = 30;


  doc.setFontSize(15);
  doc.text("LAPORAN BATAL PESANAN", 215, 15);
  doc.text(getUserdata().nama_toko, 14, 15);
  doc.setFontSize(8);
  let jml_alamat = getUserdata().alamat_toko.length;
  if (jml_alamat > 20) {
    doc.text(getUserdata().alamat_toko.slice(0,48), 14, 20);
  }
  if (jml_alamat > 50) {
    doc.text(getUserdata().alamat_toko.slice(48,90), 14, 25);
  }
  doc.setFontSize(10);
  doc.setProperties({
    title: "LAPORAN BATAL PESANAN",
  });
  //row 1
  doc.text(
    `PERIODE : ${
      getItem("tgl_laporan").length === 0
        ? null
        : getItem("tgl_laporan").tgl_from +
          " s/d " +
          getItem("tgl_laporan").tgl_to
    }`,
    215,
    20
  );
  tableColumn = [
    [
      {
        content: `NO PESANAN`,
      },
      {
        content: `SALES`,
      },
      {
        content: `TGL PESAN`,
      },
      {
        content: `TOTAL BERAT`,
        styles: {
          halign: 'right',
        },
      },
      {
        content: `TOTAL QTY`,
        styles: {
          halign: 'right',
        },
      },
      {
        content: `ONGKOS`,
        styles: {
          halign: 'right',
        },
      },
      {
        content: `TOTAL RP`,
        styles: {
          halign: 'right',
        },
      },
      {
        content: `BAYAR RP`,
        styles: {
          halign: 'right',
        },
      },
      {
        content: `SISA RP`,
        styles: {
          halign: 'right',
        },
      },
    ],
  ];
  let sisa_bayar = 0;
  let jumlah_bayar = 0;
  let total_harga = 0;
  let ongkos = 0;
  let total_qty = 0;
  let total_berat = 0;
  data.forEach((item, index) => {
    sisa_bayar = sisa_bayar + item.sisa_bayar;
    jumlah_bayar = jumlah_bayar + item.jumlah_bayar;
    total_harga = total_harga + item.total_harga;
    ongkos = ongkos + item.ongkos;
    total_qty = total_qty + item.total_qty;
    total_berat = total_berat + item.total_berat;
    let rows = [
      item.no_pesanan,
      item.kode_sales,
      item.tanggal,
      {
        content: item.total_berat.toFixed(2),
        styles: {
          halign: 'right',
        },
      },
      {
        content: parseInt(item.total_qty),
        styles: {
          halign: 'right',
        },
      },
      {
        content: parseInt(item.ongkos).toLocaleString("kr-KO"),
        styles: {
          halign : "right",
        }
      },
      {
        content: parseInt(item.total_harga).toLocaleString("kr-KO"),
        styles: {
          halign : "right",
        }
      },
      {
        content: parseInt(item.jumlah_bayar).toLocaleString("kr-KO"),
        styles: {
          halign : "right",
        }
      },
      {
        content: parseInt(item.sisa_bayar).toLocaleString("kr-KO"),
        styles: {
          halign : "right",
        }
      },

    ];
    tableRows.push(rows)
  })
  
  let footer = [
    {
      content: `Grand Total`,
      colSpan: 3,
      styles: {
        halign: 'center',
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
    {
      content: total_berat.toFixed(2),
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      }
    },
    {
      content: parseInt(total_qty).toLocaleString("kr-KO"),
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      }
    },
    {
      content: parseInt(ongkos).toLocaleString("kr-KO"),
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      }
    },
    {
      content: parseInt(total_harga).toLocaleString("kr-KO"),
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      }
    },
    {
      content: parseInt(jumlah_bayar).toLocaleString("kr-KO"),
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      }
    },
    {
      content: parseInt(sisa_bayar).toLocaleString("kr-KO"),
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      }
    },
  ]
  tableRows.push(footer)

  let printed = [
    {
      content: `Printed By ${getItem("userdata").user_id} / ${getToday()}`,
      colSpan: 7,
      styles: {
        // lineWidth: 0.1,
        fontStyle: "italic",
        textColor: "#000",
        // fillColor: "#E8E5E5"
      },
    },
  ]
  tableRows.push(printed);

  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    // foot: tbl_barang,
    startY: finalY,
    theme: "plain",
    rowPageBreak: "avoid",
    // pageBreak: "avoid",
    margin: { top: 10 },
    footStyles: {
      // lineWidth: 0.02,
      // lineColor: "#000",
      fontSize: 8,
    },
    bodyStyles: {
      // lineWidth: 0.02,
      // lineColor: "#000",
      fontSize: 8,
    },
    headStyles: {
      fontSize: 8,
      // lineWidth: 0.02,
      // lineColor: "#000",
      fillColor: "#E8E5E5",
      textColor: "#000",
    },
    tableLineColor: [255, 255, 255],
  });
  finalY = doc.autoTableEndPosY() + 3;
  tableRows = [];
  // footRows = [];
  // tbl_barang = [];
  // const date = Date().split(" ");
  // we use a date string to generate our filename.
  // const dateStr = date[2] + date[3] + date[4];
  // ticket title. and margin-top + margin-left
  // doc.text(
  //   `User                          : ${
  //     getItem("userdata").user_id
  //   }`,
  //   14,
  //   finalY + 10
  // );
  // doc.text(`Cetak                        : ${getToday()}`, 14, finalY + 16);
  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  // doc.autoPrint();
  doc.save(`LAPORAN BATAL PESANAN.pdf`);
  // var string = doc.output("datauristring");
  // // var embed = "<embed width='100%' height='100%' src='" + string + "'/>";
  // var x = window.open();
  // x.document.open();
  // x.document.write(
  //   "<html><head><title>LAPORAN PESANAN</title></head><body style='margin:0 !important'><embed width='100%' height='100%'  src=" +
  //     string +
  //     "></embed></body></html>"
  // );
  // x.document.write(embed);
  // setInterval(() => {
  //   x.close();
  // }, 1000);
};

export default LaporanBatalPesananPdf;
