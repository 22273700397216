import {
  Tabel,
  useDispatch,
  useSelector,
} from "../../../../components/helpers/library";
import React from "react";
import { parameterAkun } from "../redux";
import { useEffect } from "react";
import { selectorParameter } from "../../../../selector/selectorParameter";
import { getParameterAkun } from "../../../../actions";

const TabelParameter = () => {
  const dispatch = useDispatch();
  const dataakun = useSelector(selectorParameter.dataakun);
  const action = parameterAkun();

  const columns = [
    {
      dataField: "trx",
      text: "Nama Jurnal",
      formatter: (cell) => {
        return cell.replaceAll("_", " ");
      },
    },
    {
      dataField: "type",
      text: "Type Jurnal",
      formatter: (cell) => {
        return cell.replaceAll("_", " ");
      },
    },

    {
      dataField: "action",
      text: "Action",
      headerClasses: "text-center",
      formatter: (cell, row) => {
        return (
          <div className="text-center">
            <button
              className="btn btn-primary mr-3"
              onClick={() => dispatch(action.showForm(row, "PARAMETER"))}
            >
              <i className="fa fa-edit"></i>
            </button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(getParameterAkun());
  }, [dispatch]);

  return <Tabel keyField="_id" data={dataakun} columns={columns} />;
};

export default TabelParameter;
