import React from "react";
import ReactDOM from "react-dom";
import App from "./app.jsx";
import { BrowserRouter } from "react-router-dom";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import reducer from "./reducers";
import thunk from "redux-thunk";
import reportWebVitals from "./reportWebVitals";
import "react-loading-skeleton/dist/skeleton.css";

import "./index.css";
import "./utils/lang.jsx";

import { appVersion, url } from "./components/helpers/library.jsx";

const store = createStore(
  reducer,
  compose(
    applyMiddleware(thunk),
    // window._REDUX_DEVTOOLS_EXTENSION_ && window._REDUX_DEVTOOLS_EXTENSION_()
    typeof window.__REDUX_DEVTOOLS_EXTENSION__ === "undefined"
      ? (a) => a
      : window.__REDUX_DEVTOOLS_EXTENSION__ &&
          window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);
const isPos = appVersion === "POS";
ReactDOM.render(
  <BrowserRouter
    basename={
      isPos && url !== "/" ? url : isPos === false && url !== "/" ? url : "/"
    }
  >
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);

reportWebVitals();
