import {
  convertDate,
  isLoading,
  ToastNotification,
  getDataParams,
} from "../../../../../components/helpers/library";
import LaporanPenebusanDetail from "../Laporan";

const cariLaporan = (data) => {
  return async (dispatch, getState) => {
    dispatch(isLoading(true));
    try {
      let data_post = {
        tgl_from: convertDate(data.tgl_awal),
        tgl_to: convertDate(data.tgl_akhir),
        no_pinjam: data.no_pinjam,
        sort_by: data.berdasarkan,
      };

      let hasil = await getDataParams(
        "hutang/report-penebusan-detail",
        data_post
      );
      //   console.log(hasil.data)
      if (hasil.data.length === 0) {
        ToastNotification("info", "Data Tidak Tersedia");
        dispatch(isLoading(false));
        return false;
      } else {
        LaporanPenebusanDetail(hasil.data, data_post);
        ToastNotification("success", "Laporan Tersedia");
        dispatch(isLoading(false));
      }
    } catch (error) {
      ToastNotification("info", "Laporan Tidak Tersedia");
      dispatch(isLoading(false));
    }
  };
};

export { cariLaporan };
