import {getDataNoTimeOut,  postDataNoTImeOut} from "../../../../config/axios";
import {
    getToday,
    getUserdata, hideModal,
    getItem,
    ToastNotification
} from "../../../../components/helpers/library";
import jsPDF from "jspdf";
import "jspdf-autotable";

import {reset} from "redux-form";
import {doLoading, stopLoading} from "../../../../actions/utility_action";

export const GET_ALL_KIRIM_UANG_PUSAT = "GET_ALL_KIRIM_UANG_PUSAT";

export const getKirimUangPusat = () => {
    return async ( dispatch, getState) => {
        const response = await getDataNoTimeOut('kirim-uang-history');
        dispatch({
            type : GET_ALL_KIRIM_UANG_PUSAT,
            payload : response.data
        })
    }
}

export const sendKirimUangPusat = () => {
    return async (dispatch, getState) => {
        dispatch(doLoading())
        const state = getState();
        const selected = state.form.FormKirimUangPusat.values;
        try {
        const response = await postDataNoTImeOut("kirim-uang", selected);
            console.log(response.data)
            dispatch(getKirimUangPusat())
            dispatch(hideModal())
            dispatch(reset("FormKirimUangPusat"))
            dispatch(stopLoading())
        print(response.data.nota_kirim);
        } catch (e) {
            console.log(e)
            dispatch(stopLoading())
            ToastNotification("error", "Kirim Uang Gagal, Silahkan Coba Beberapa Saat Lagi")
        }
    }
}

function print(data){
    const doc = new jsPDF("l", "mm", [297, 210]);
    //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
    let tableRows = [];
    let finalY = 30;

    doc.setFontSize(15);
    doc.text("LAPORAN KIRIM UANG", 14, 15);
    doc.setFontSize(20);
    doc.text(getUserdata().nama_toko, 200, 15);
    doc.setFontSize(8);
    let jml_alamat = getUserdata().alamat_toko.length;
    if (jml_alamat > 20) {
        doc.text(getUserdata().alamat_toko.slice(0, 48), 200, 20);
    }
    if (jml_alamat > 50) {
        doc.text(getUserdata().alamat_toko.slice(48, 90), 200, 25);
    }
    doc.setFontSize(10);
    doc.setProperties({
        title: "LAPORAN KIRIM",
    });
    //row 1
    doc.text(
        `Tanggal : ${data.tgl_system}`,
        14,
        25
    );
    let tableColumn = [
        [
            {
                content: `PENGIRIM`,
            },
            {
                content: `NOMINAL`,
                styles: {
                    halign: "right",
                },
            },
            {
                content: `TUJUAN`,
            },
            {
                content: `KETERANGAN`,
            },
            {
                content: 'NO REFF'
            }
        ],
    ];
    let rows = [
        data.input_by,
        {
            content: data.jumlah_rp?.toLocaleString("kr-KO") || "",
            styles: {
                halign: "right",
            },
        },
        data.tujuan,
        data.keterangan,
        data.no_reff
    ];
    tableRows.push(rows)
    
    let printed = [
        {
            colSpan: 9,
            content: `Printed By ${
                getItem("userdata").user_id
            } / ${getToday()}`,
            styles: {
                // lineWidth: 0.1,
                fontStyle: "italic",
                textColor: "#000",
                // fillColor: "#E8E5E5"
            },
        },
    ];
    tableRows.push(printed);
    doc.autoTable({
        head: tableColumn,
        body: tableRows,
        // foot: footRows,
        startY: finalY,
        theme: "plain",
        rowPageBreak: "avoid",
        // pageBreak: "avoid",
        margin: { top: 10 },
        footStyles: {
            // lineWidth: 0.02,
            // lineColor: "#000",
            fontSize: 8,
        },
        bodyStyles: {
            // lineWidth: 0.02,
            // lineColor: "#000",
            fontSize: 8,
        },
        headStyles: {
            fontSize: 8,
            // lineWidth: 0.02,
            // lineColor: "#000",

            fillColor: "#E8E5E5",
            textColor: "#000",
        },
    });
    finalY = doc.autoTableEndPosY() + 3;
    tableRows = [];

    const pages = doc.internal.getNumberOfPages();
    const pageWidth = doc.internal.pageSize.width; //Optional
    const pageHeight = doc.internal.pageSize.height; //Optional
    doc.setFontSize(10); //Optional
    for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2; //Can be fixed number
        let verticalPos = pageHeight - 10; //Can be fixed number
        doc.setPage(j);
        doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
            align: "center",
        });
    }
    var string = doc.output("datauristring");
    var x = window.open();
    x.document.open();
    x.document.write(
        "<html><head><title>Laporan KIRIM UANG</title></head><body style='margin:0 !important'><embed width='100%' height='100%'  src=" +
        string +
        "></embed></body></html>"
    );
}