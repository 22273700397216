import {
  DATA_NOTA,
  FORM_MODAL_NAME,
  GET_DATA_MODAL,
  GET_DATA_TIMBANGAN,
  GET_PARA_SYSTEM,
  GET_PARA_SYSTEM_ID,
  IS_DISABLED,
  SET_LOADING,
} from "../actions/utility_action";
import { SET_PORTAL } from "../actions/pusat_action";
import { AppMode, server } from "../components/helpers/library";

const type = AppMode;
const basePortal1 = server;
const basePortal2 = server;
const initialState = {
  getTimbangan: [],
  getParaSystem: [],
  getParaSystemId: [],
  isPusat: type === "PUSAT",
  portal1Selected: basePortal1,
  portal2Selected: basePortal2,
  isLoading: false,
  disabled: false,
  getDataModal: [],
  getDataNota: [],
  geFormModalName: {
    nama_form: "",
    data: [],
  },
};

const utility = (state = initialState, actions) => {
  switch (actions.type) {
    case IS_DISABLED:
      return {
        ...state,
        disabled: actions.payload.data,
      };
    case FORM_MODAL_NAME:
      return {
        ...state,
        geFormModalName: actions.payload.data,
      };
    case DATA_NOTA:
      return {
        ...state,
        getDataNota: actions.payload.data,
      };
    case GET_DATA_MODAL:
      return {
        ...state,
        getDataModal: actions.payload.data,
      };
    case GET_DATA_TIMBANGAN:
      return {
        ...state,
        getTimbangan: actions.payload.data,
      };
    case GET_PARA_SYSTEM:
      return {
        ...state,
        getParaSystem: actions.payload.data,
      };
    case GET_PARA_SYSTEM_ID:
      return {
        ...state,
        getParaSystemId: actions.payload.data,
      };
    case SET_PORTAL:
      return {
        ...state,
        portal1Selected: actions.payload.portal1,
        portal2Selected: actions.payload.portal2,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: actions.payload,
      };
    default:
      return state;
  }
};

export default utility;
